
export interface ICountry {
  countryNameEn: string;
  countryNameLocal: string;
  countryCode: string;
  currencyCode: string;
  currencyNameEn: string;
  currencySymbol: string;
  tinType?: string;
  tinName?: string;
  officialLanguageCode: string;
  officialLanguageNameEn: string;
  officialLanguageNameLocal: string;
  countryCallingCode: string;
  areaCodes?: string[];
  region: string;
  cities?: { name: string; slug: string, pop?: number }[];
  translations?: { [key: string]: string };
  vpn?: boolean;
  flag: string;
  enabled?: boolean;
  blocked?: boolean;
  locale: string;
  cryptoProhibited?: boolean; // https://en.wikipedia.org/wiki/Legality_of_cryptocurrency_by_country_or_territory
}

export const all: ICountry[] = [
  {
    countryNameEn: "Andorra",
    countryNameLocal: "Andorra",
    countryCode: "AD",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    officialLanguageCode: "ca",
    officialLanguageNameEn: "Catalan, Valencian",
    officialLanguageNameLocal: "Català, Valencià",
    countryCallingCode: "376",
    region: "Europe",
    flag: "🇦🇩",
    locale: "ca-AD"
  },
  {
    countryNameEn: "Afghanistan",
    countryNameLocal: "د افغانستان اسلامي دولتدولت اسلامی افغانستان, جمهوری اسلامی افغانستان",
    countryCode: "AF",
    currencyCode: "AFN",
    currencyNameEn: "Afghan afghani",
    currencySymbol: "؋",
    officialLanguageCode: "fa",
    officialLanguageNameEn: "Persian",
    officialLanguageNameLocal: "فارسی",
    countryCallingCode: "93",
    region: "Asia & Pacific",
    flag: "🇦🇫",
    blocked: true,
    locale: "ps-AF",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Antigua and Barbuda",
    countryNameLocal: "Antigua and Barbuda",
    countryCode: "AG",
    currencyCode: "XCD",
    currencyNameEn: "East Caribbean dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1268",
    region: "South/Latin America",
    flag: "🇦🇬",
    locale: "en-AG"
  },
  {
    countryNameEn: "Anguilla",
    countryNameLocal: "Anguilla",
    countryCode: "AI",
    currencyCode: "XCD",
    currencyNameEn: "East Caribbean dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1264",
    region: "South/Latin America",
    flag: "🇦🇮",
    locale: "en-AI"
  },
  {
    countryNameEn: "Albania",
    countryNameLocal: "Shqipëria",
    countryCode: "AL",
    currencyCode: "ALL",
    currencyNameEn: "Albanian lek",
    currencySymbol: "L",
    tinType: "NIPT",
    tinName: "Numri i Identifikimit për Personin e Tatueshëm",
    officialLanguageCode: "sq",
    officialLanguageNameEn: "Albanian",
    officialLanguageNameLocal: "Shqip",
    countryCallingCode: "355",
    region: "Europe",
    flag: "🇦🇱",
    locale: "sq-AL"
  },
  {
    countryNameEn: "Armenia",
    countryNameLocal: "Հայաստան",
    countryCode: "AM",
    currencyCode: "AMD",
    currencyNameEn: "Armenian dram",
    currencySymbol: "֏",
    officialLanguageCode: "hy",
    officialLanguageNameEn: "Armenian",
    officialLanguageNameLocal: "Հայերեն",
    countryCallingCode: "374",
    region: "Europe",
    flag: "🇦🇲",
    locale: "hy-AM"
  },
  {
    countryNameEn: "Angola",
    countryNameLocal: "Angola",
    countryCode: "AO",
    currencyCode: "AOA",
    currencyNameEn: "Angolan kwanza",
    currencySymbol: "Kz",
    officialLanguageCode: "pt",
    officialLanguageNameEn: "Portuguese",
    officialLanguageNameLocal: "Português",
    countryCallingCode: "244",
    region: "Africa",
    flag: "🇦🇴",
    locale: "pt-AO"
  },
  {
    countryNameEn: "Antarctica",
    countryNameLocal: "Antarctica, Antártico, Antarctique, Антарктике",
    countryCode: "AQ",
    currencyCode: "",
    currencyNameEn: "",
    currencySymbol: "",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "672",
    region: "Asia & Pacific",
    flag: "🇦🇶",
    locale: "en-AQ"
  },
  {
    countryNameEn: "Argentina",
    countryNameLocal: "Argentina",
    countryCode: "AR",
    currencyCode: "ARS",
    currencyNameEn: "Argentine peso",
    currencySymbol: "$",
    tinType: "CUIT",
    tinName: "Código Único de Identificación Tributaria",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "54",
    region: "South/Latin America",
    cities: [
      { name: "Buenos Aires", slug: "ar-ba-buenos-aires", pop: 2890000 },
      { name: "Córdoba", slug: "ar-co-cordoba", pop: 1300000 },
      { name: "Rosario", slug: "ar-sf-rosario", pop: 1200000 },
      { name: "La Plata", slug: "ar-ba-la-plata", pop: 700000 },
      { name: "Mar del Plata", slug: "ar-ba-mar-del-plata", pop: 600000 },
      { name: "San Miguel de Tucumán", slug: "ar-tu-san-miguel-de-tucuman", pop: 600000 },
      { name: "Salta", slug: "ar-sa-salta", pop: 500000 },
      { name: "Santa Fe", slug: "ar-sf-santa-fe", pop: 500000 },
      { name: "Vicente López", slug: "ar-ba-vicente-lopez", pop: 300000 },
      { name: "Corrientes", slug: "ar-cn-corrientes", pop: 300000 },
      { name: "Pilar", slug: "ar-ba-pilar" },
      { name: "Bahía Blanca", slug: "ar-ba-bahia-blanca" },
      { name: "Resistencia", slug: "ar-hq-resistencia" },
      { name: "Posadas", slug: "ar-nq-posadas" },
      { name: "San Salvador de Jujuy", slug: "ar-ju-san-salvador-de-jujuy" },
      { name: "Mendoza", slug: "ar-mz-mendoza" },
      { name: "San Juan", slug: "ar-sj-san-juan" },
      { name: "Neuquén", slug: "ar-nq-neuquen" },
      { name: "Paraná", slug: "ar-er-parana" },
      { name: "Formosa", slug: "ar-fm-formosa" },
      { name: "San Fernando del Valle de Catamarca", slug: "ar-ct-san-fernando-del-valle-de-catamarca" },
      { name: "San Luis", slug: "ar-sl-san-luis" },
      { name: "Santa Rosa", slug: "ar-la-santa-rosa" },
      { name: "Ushuaia", slug: "ar-tf-ushuaia" },
      { name: "Viedma", slug: "ar-rn-viedma" },
    ],
    translations: {
      "kr": "아르헨티나",
      "pt-BR": "Argentina",
      "pt": "Argentina",
      "nl": "Argentinië",
      "hr": "Argentina",
      "fa": "آرژانتین",
      "de": "Argentinien",
      "es": "Argentina",
      "fr": "Argentine",
      "ja": "アルゼンチン",
      "it": "Argentina",
      "cn": "阿根廷",
      "tr": "Arjantin"
    },
    enabled: true,
    flag: "🇦🇷",
    locale: "gn-AR"
  },
  {
    countryNameEn: "American Samoa",
    countryNameLocal: "American Samoa",
    countryCode: "AS",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1684",
    region: "Asia & Pacific",
    flag: "🇦🇸",
    locale: "en-AS"
  },
  {
    countryNameEn: "Austria",
    countryNameLocal: "Österreich",
    countryCode: "AT",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "UID",
    tinName: "Umsatzsteuer-Identifikationsnummer",
    officialLanguageCode: "de",
    officialLanguageNameEn: "German",
    officialLanguageNameLocal: "Deutsch",
    countryCallingCode: "43",
    region: "Europe",
    cities: [
      { name: "Vienna", slug: "at-w-vienna", pop: 1900000 },
      { name: "Graz", slug: "at-sty-graz", pop: 300000 },
      { name: "Linz", slug: "at-u-linz", pop: 200000 },
      { name: "Salzburg", slug: "at-s-salzburg", pop: 150000 },
      { name: "Innsbruck", slug: "at-t-innsbruck", pop: 130000 },
      { name: "Klagenfurt", slug: "at-k-klagenfurt", pop: 100000 },
      { name: "Villach", slug: "at-k-villach", pop: 65000 },
      { name: "Wels", slug: "at-u-wels", pop: 65000 },
      { name: "Sankt Pölten", slug: "at-no-sankt-polten", pop: 55000 },
      { name: "Dornbirn", slug: "at-v-dornbirn", pop: 50000 },
      { name: "Wiener Neustadt", slug: "at-n-wiener-neustadt" },
      { name: "Steyr", slug: "at-u-steyr" },
      { name: "Feldkirch", slug: "at-v-feldkirch" },
      { name: "Bregenz", slug: "at-v-bregenz" },
      { name: "Wolfsberg", slug: "at-k-wolfsberg" },
      { name: "Baden", slug: "at-l-baden" },
      { name: "Krems an der Donau", slug: "at-no-krems-an-der-donau" },
      { name: "Leoben", slug: "at-sty-leoben" },
      { name: "Traun", slug: "at-u-traun" },
      { name: "Amstetten", slug: "at-no-amstetten" },
      { name: "Leonding", slug: "at-u-leonding" },
      { name: "Kapfenberg", slug: "at-sty-kapfenberg" },
      { name: "Modling", slug: "at-l-modling" },
      { name: "Lustenau", slug: "at-v-lustenau" },
      { name: "Hallein", slug: "at-s-hallein" },
    ],
    translations: {
      "kr": "오스트리아",
      "pt-BR": "áustria",
      "pt": "áustria",
      "nl": "Oostenrijk",
      "hr": "Austrija",
      "fa": "اتریش",
      "de": "Österreich",
      "es": "Austria",
      "fr": "Autriche",
      "ja": "オーストリア",
      "it": "Austria",
      "cn": "奥地利",
      "tr": "Avusturya"
    },
    enabled: true,
    flag: "🇦🇹",
    locale: "de-AT"
  },
  {
    countryNameEn: "Australia",
    countryNameLocal: "Australia",
    countryCode: "AU",
    currencyCode: "AUD",
    currencyNameEn: "Australian dollar",
    currencySymbol: "$",
    tinType: "ABN",
    tinName: "Australian Business Number",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "61",
    region: "Asia & Pacific",
    cities: [
      { name: "Sydney", slug: "au-nsw-sydney", pop: 5450000 },
      { name: "Melbourne", slug: "au-vic-melbourne", pop: 5200000 },
      { name: "Brisbane", slug: "au-qld-brisbane", pop: 2700000 },
      { name: "Perth", slug: "au-wa-perth", pop: 2300000 },
      { name: "Adelaide", slug: "au-sa-adelaide", pop: 1400000 },
      { name: "Gold Coast", slug: "au-qld-gold-coast", pop: 730000 },
      { name: "Canberra", slug: "au-act-canberra", pop: 460000 },
      { name: "Newcastle", slug: "au-nsw-newcastle", pop: 430000 },
      { name: "Wollongong", slug: "au-nsw-wollongong", pop: 300000 },
      { name: "Hobart", slug: "au-tas-hobart", pop: 250000 },
      { name: "Geelong", slug: "au-vic-geelong", pop: 300000 },
      { name: "Townsville", slug: "au-qld-townsville", pop: 180000 },
      { name: "Cairns", slug: "au-qld-cairns", pop: 160000 },
      { name: "Darwin", slug: "au-nt-darwin", pop: 150000 },
      { name: "Toowoomba", slug: "au-qld-toowoomba", pop: 140000 },
      { name: "Ballarat", slug: "au-vic-ballarat" },
      { name: "Bendigo", slug: "au-vic-bendigo" },
      { name: "Albury-Wodonga", slug: "au-nsw-albury-wodonga" },
      { name: "Launceston", slug: "au-tas-launceston" },
      { name: "Rockhampton", slug: "au-qld-rockhampton" },
      { name: "Mackay", slug: "au-qld-mackay" },
      { name: "Bunbury", slug: "au-wa-bunbury" },
      { name: "Coffs Harbour", slug: "au-nsw-coffs-harbour" },
      { name: "Bundaberg", slug: "au-qld-bundaberg" },
      { name: "Wagga Wagga", slug: "au-nsw-wagga-wagga" },
      { name: "Hervey Bay", slug: "au-qld-hervey-bay" },
    ],
    translations: {
      "kr": "호주",
      "pt-BR": "Austrália",
      "pt": "Austrália",
      "nl": "Australië",
      "hr": "Australija",
      "fa": "استرالیا",
      "de": "Australien",
      "es": "Australia",
      "fr": "Australie",
      "ja": "オーストラリア",
      "it": "Australia",
      "cn": "澳大利亚",
      "tr": "Avustralya"
    },
    enabled: true,
    flag: "🇦🇺",
    locale: "en-AU"
  },
  {
    countryNameEn: "Aruba",
    countryNameLocal: "Aruba",
    countryCode: "AW",
    currencyCode: "AWG",
    currencyNameEn: "Aruban florin",
    currencySymbol: "ƒ",
    officialLanguageCode: "nl",
    officialLanguageNameEn: "Dutch, Flemish",
    officialLanguageNameLocal: "Nederlands, Vlaams",
    countryCallingCode: "297",
    region: "South/Latin America",
    flag: "🇦🇼",
    locale: "nl-AW"
  },
  {
    countryNameEn: "Åland Islands",
    countryNameLocal: "Åland",
    countryCode: "AX",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    officialLanguageCode: "sv",
    officialLanguageNameEn: "Swedish",
    officialLanguageNameLocal: "Svenska",
    countryCallingCode: "358",
    region: "Europe",
    flag: "🇦🇽",
    locale: "sv-AX"
  },
  {
    countryNameEn: "Azerbaijan",
    countryNameLocal: "Azərbaycan",
    countryCode: "AZ",
    currencyCode: "AZN",
    currencyNameEn: "Azerbaijani manat",
    currencySymbol: "₼",
    officialLanguageCode: "az",
    officialLanguageNameEn: "Azerbaijani",
    officialLanguageNameLocal: "azərbaycan dili",
    countryCallingCode: "994",
    region: "Asia & Pacific",
    flag: "🇦🇿",
    locale: "az-AZ"
  },
  {
    countryNameEn: "Bosnia and Herzegovina",
    countryNameLocal: "Bosna i Hercegovina",
    countryCode: "BA",
    currencyCode: "BAM",
    currencyNameEn: "Bosnia and Herzegovina convertible mark",
    currencySymbol: "",
    officialLanguageCode: "bs",
    officialLanguageNameEn: "Bosnian",
    officialLanguageNameLocal: "bosanski jezik",
    countryCallingCode: "387",
    region: "Europe",
    flag: "🇧🇦",
    locale: "bs-BA"
  },
  {
    countryNameEn: "Barbados",
    countryNameLocal: "Barbados",
    countryCode: "BB",
    currencyCode: "BBD",
    currencyNameEn: "Barbados dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1246",
    region: "South/Latin America",
    flag: "🇧🇧",
    locale: "en-BB"
  },
  {
    countryNameEn: "Bangladesh",
    countryNameLocal: "গণপ্রজাতন্ত্রী বাংলাদেশ",
    countryCode: "BD",
    currencyCode: "BDT",
    currencyNameEn: "Bangladeshi taka",
    currencySymbol: "৳",
    officialLanguageCode: "bn",
    officialLanguageNameEn: "Bengali",
    officialLanguageNameLocal: "বাংলা",
    countryCallingCode: "880",
    region: "Asia & Pacific",
    flag: "🇧🇩",
    blocked: true,
    locale: "bn-BD",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Belgium",
    countryNameLocal: "België, Belgique, Belgien",
    countryCode: "BE",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "n° TVABTW-nr Mwst-nr",
    tinName: "BTW identificatienummer / Numéro de TVA",
    officialLanguageCode: "nl",
    officialLanguageNameEn: "Dutch, Flemish",
    officialLanguageNameLocal: "Nederlands, Vlaams",
    countryCallingCode: "32",
    region: "Europe",
    cities: [
      { name: "Antwerp", slug: "be-van-antwerp", pop: 520000 },
      { name: "Ghent", slug: "be-vo-ghent", pop: 260000 },
      { name: "Charleroi", slug: "be-wal-charleroi", pop: 200000 },
      { name: "Liège", slug: "be-wal-liege", pop: 200000 },
      { name: "Brussels", slug: "be-bru-brussels", pop: 180000 },
      { name: "Schaerbeek", slug: "be-bru-schaerbeek", pop: 130000 },
      { name: "Anderlecht", slug: "be-bru-anderlecht", pop: 120000 },
      { name: "Bruges", slug: "be-vo-bruges", pop: 120000 },
      { name: "Namur", slug: "be-wal-namur", pop: 110000 },
      { name: "Leuven", slug: "be-vbr-leuven", pop: 100000 },
      { name: "Sint-Jans-Molenbeek", slug: "be-bru-sint-jans-molenbeek" },
      { name: "Mons", slug: "be-wal-mons" },
      { name: "Aalst", slug: "be-vov-aalst" },
      { name: "Mechelen", slug: "be-van-mechelen" },
      { name: "La Louvière", slug: "be-wal-la-louviere" },
      { name: "Kortrijk", slug: "be-vwv-kortrijk" },
      { name: "Hasselt", slug: "be-vli-hasselt" },
      { name: "Ostend", slug: "be-vwv-ostend" },
      { name: "Sint-Niklaas", slug: "be-vov-sint-niklaas" },
      { name: "Tournai", slug: "be-wal-tournai" },
      { name: "Genk", slug: "be-vli-genk" },
      { name: "Seraing", slug: "be-wal-seraing" },
      { name: "Roeselare", slug: "be-vwv-roeselare" },
      { name: "Verviers", slug: "be-wal-verviers" },
      { name: "Mouscron", slug: "be-wal-mouscron" },
      { name: "Beveren", slug: "be-vo-beveren" },
      { name: "Dendermonde", slug: "be-vov-dendermonde" },
      { name: "Beringen", slug: "be-vli-beringen" },
    ],
    translations: {
      "kr": "벨기에",
      "pt-BR": "Bélgica",
      "pt": "Bélgica",
      "nl": "België",
      "hr": "Belgija",
      "fa": "بلژیک",
      "de": "Belgien",
      "es": "Bélgica",
      "fr": "Belgique",
      "ja": "ベルギー",
      "it": "Belgio",
      "cn": "比利时",
      "tr": "Belçika"
    },
    enabled: true,
    flag: "🇧🇪",
    locale: "de-BE"
  },
  {
    countryNameEn: "Burkina Faso",
    countryNameLocal: "Burkina Faso",
    countryCode: "BF",
    currencyCode: "XOF",
    currencyNameEn: "CFA franc BCEAO",
    currencySymbol: "Fr",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "226",
    region: "Africa",
    flag: "🇧🇫",
    locale: "fr-BF"
  },
  {
    countryNameEn: "Bulgaria",
    countryNameLocal: "България",
    countryCode: "BG",
    currencyCode: "BGN",
    currencyNameEn: "Bulgarian lev",
    currencySymbol: "лв",
    tinType: "ДДС номер",
    tinName: "Идентификационен номер по ДДС",
    officialLanguageCode: "bg",
    officialLanguageNameEn: "Bulgarian",
    officialLanguageNameLocal: "български език",
    countryCallingCode: "359",
    region: "Europe",
    flag: "🇧🇬",
    locale: "bg-BG"
  },
  {
    countryNameEn: "Bahrain",
    countryNameLocal: "البحرين",
    countryCode: "BH",
    currencyCode: "BHD",
    currencyNameEn: "Bahraini dinar",
    currencySymbol: ".د.ب",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "973",
    region: "Arab States",
    flag: "🇧🇭",
    blocked: true,
    locale: "ar-BH"
  },
  {
    countryNameEn: "Burundi",
    countryNameLocal: "Burundi",
    countryCode: "BI",
    currencyCode: "BIF",
    currencyNameEn: "Burundian franc",
    currencySymbol: "Fr",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "257",
    region: "Africa",
    flag: "🇧🇮",
    locale: "fr-BI",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Benin",
    countryNameLocal: "Bénin",
    countryCode: "BJ",
    currencyCode: "XOF",
    currencyNameEn: "CFA franc BCEAO",
    currencySymbol: "Fr",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "229",
    region: "Africa",
    flag: "🇧🇯",
    locale: "fr-BJ"
  },
  {
    countryNameEn: "Saint Barthélemy",
    countryNameLocal: "Saint-Barthélemy",
    countryCode: "BL",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "590",
    region: "South/Latin America",
    flag: "🇧🇱",
    locale: "fr-BL"
  },
  {
    countryNameEn: "Bermuda",
    countryNameLocal: "Bermuda",
    countryCode: "BM",
    currencyCode: "BMD",
    currencyNameEn: "Bermudian dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1441",
    region: "North America",
    flag: "🇧🇲",
    locale: "en-BM"
  },
  {
    countryNameEn: "Brunei Darussalam",
    countryNameLocal: "Brunei Darussalam",
    countryCode: "BN",
    currencyCode: "BND",
    currencyNameEn: "Brunei dollar",
    currencySymbol: "$",
    officialLanguageCode: "ms",
    officialLanguageNameEn: "Malay",
    officialLanguageNameLocal: "Bahasa Melayu, بهاس ملايو‎",
    countryCallingCode: "673",
    region: "Asia & Pacific",
    flag: "🇧🇳",
    locale: "ms-BN"
  },
  {
    countryNameEn: "Bolivia (Plurinational State of)",
    countryNameLocal: "Bolivia, Bulibiya, Volívia, Wuliwya",
    countryCode: "BO",
    currencyCode: "BOP",
    currencyNameEn: "",
    currencySymbol: "Bs.",
    tinType: "NIT",
    tinName: "Número de Identificación Tributaria",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "591",
    region: "South/Latin America",
    flag: "🇧🇴",
    locale: "ay-BO",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Bonaire, Sint Eustatius and Saba",
    countryNameLocal: "Caribisch Nederland",
    countryCode: "BQ",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    currencySymbol: "$",
    officialLanguageCode: "nl",
    officialLanguageNameEn: "Dutch, Flemish",
    officialLanguageNameLocal: "Nederlands, Vlaams",
    countryCallingCode: "5997",
    region: "Unknown",
    flag: "🇧🇶",
    locale: "nl-BQ"
  },
  {
    countryNameEn: "Brazil",
    countryNameLocal: "Brasil",
    countryCode: "BR",
    currencyCode: "BRL",
    currencyNameEn: "Brazilian real",
    currencySymbol: "R$",
    tinType: "CNPJ",
    tinName: "Cadastro Nacional de Pessoa Jurídica",
    officialLanguageCode: "pt",
    officialLanguageNameEn: "Portuguese",
    officialLanguageNameLocal: "Português",
    countryCallingCode: "55",
    region: "South/Latin America",
    cities: [
      { name: "São Paulo", slug: "br-sp-sao-paulo", pop: 11450000 },
      { name: "Rio de Janeiro", slug: "br-rj-rio-de-janeiro", pop: 6200000 },
      { name: "Brasília", slug: "br-df-brasilia", pop: 2800000 },
      { name: "Fortaleza", slug: "br-ce-fortaleza", pop: 2400000 },
      { name: "Salvador", slug: "br-ba-salvador", pop: 2400000 },
      { name: "Belo Horizonte", slug: "br-mg-belo-horizonte", pop: 2300000 },
      { name: "Manaus", slug: "br-am-manaus", pop: 2000000 },
      { name: "Curitiba", slug: "br-pr-curitiba", pop: 1700000 },
      { name: "Recife", slug: "br-pe-recife", pop: 1500000 },
      { name: "Goiânia", slug: "br-go-goiania", pop: 1400000 },
      { name: "Porto Alegre", slug: "br-rs-porto-alegre" },
      { name: "Belém", slug: "br-pa-belem" },
      { name: "Guarulhos", slug: "br-sp-guarulhos" },
      { name: "Campinas", slug: "br-sp-campinas" },
      { name: "São Luís", slug: "br-ma-sao-luis" },
      { name: "São Gonçalo", slug: "br-rj-sao-goncalo" },
      { name: "Maceió", slug: "br-al-maceio" },
      { name: "Duque de Caxias", slug: "br-rj-duque-de-caxias" },
      { name: "Teresina", slug: "br-pi-teresina" },
      { name: "Natal", slug: "br-rn-natal" },
      { name: "Nova Iguaçu", slug: "br-rj-nova-iguacu" },
      { name: "Campo Grande", slug: "br-ms-campo-grande" },
      { name: "São Bernardo do Campo", slug: "br-sp-sao-bernardo-do-campo" },
      { name: "Osasco", slug: "br-sp-osasco" },
    ],
    translations: {
      "kr": "브라질",
      "pt-BR": "Brasil",
      "pt": "Brasil",
      "nl": "Brazilië",
      "hr": "Brazil",
      "fa": "برزیل",
      "de": "Brasilien",
      "es": "Brasil",
      "fr": "Brésil",
      "ja": "ブラジル",
      "it": "Brasile",
      "cn": "巴西",
      "tr": "Brezilya"
    },
    enabled: true,
    flag: "🇧🇷",
    locale: "pt-BR"
  },
  {
    countryNameEn: "Bhutan",
    countryNameLocal: "འབྲུག་ཡུལ",
    countryCode: "BT",
    currencyCode: "BTN",
    currencyNameEn: "Bhutanese ngultrum",
    currencySymbol: "Nu.",
    officialLanguageCode: "dz",
    officialLanguageNameEn: "Dzongkha",
    officialLanguageNameLocal: "རྫོང་ཁ",
    countryCallingCode: "975",
    region: "Asia & Pacific",
    flag: "🇧🇹",
    locale: "dz-BT"
  },
  {
    countryNameEn: "Bouvet Island",
    countryNameLocal: "Bouvetøya",
    countryCode: "BV",
    currencyCode: "NOK",
    currencyNameEn: "Norwegian krone",
    currencySymbol: "",
    officialLanguageCode: "no",
    officialLanguageNameEn: "Norwegian",
    officialLanguageNameLocal: "Norsk",
    countryCallingCode: "47",
    region: "South/Latin America",
    flag: "🇧🇻",
    locale: "nn-BV"
  },
  {
    countryNameEn: "Botswana",
    countryNameLocal: "Botswana",
    countryCode: "BW",
    currencyCode: "BWP",
    currencyNameEn: "Botswana pula",
    currencySymbol: "P",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "267",
    region: "Africa",
    flag: "🇧🇼",
    locale: "en-BW"
  },
  {
    countryNameEn: "Belarus",
    countryNameLocal: "Беларусь",
    countryCode: "BY",
    currencyCode: "BYR",
    currencyNameEn: "",
    currencySymbol: "",
    tinType: "УНП (UNP)",
    tinName: "Учетный номер плательщика",
    officialLanguageCode: "be",
    officialLanguageNameEn: "Belarusian",
    officialLanguageNameLocal: "беларуская мова",
    countryCallingCode: "375",
    region: "Europe",
    flag: "🇧🇾",
    locale: "be-BY"
  },
  {
    countryNameEn: "Belize",
    countryNameLocal: "Belize",
    countryCode: "BZ",
    currencyCode: "BZD",
    currencyNameEn: "Belize dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "501",
    region: "South/Latin America",
    flag: "🇧🇿",
    locale: "en-BZ"
  },
  {
    countryNameEn: "Canada",
    countryNameLocal: "Canada",
    countryCode: "CA",
    currencyCode: "CAD",
    currencyNameEn: "Canadian dollar",
    currencySymbol: "$",
    tinType: "BN / NE",
    tinName: "Business Number",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1",
    "areaCodes": [
      "403",
      "587",
      "780",
      "825",
      "236",
      "250",
      "604",
      "672",
      "778",
      "204",
      "431",
      "506",
      "709",
      "782",
      "902",
      "226",
      "249",
      "289",
      "343",
      "365",
      "416",
      "437",
      "519",
      "548",
      "613",
      "647",
      "705",
      "807",
      "905",
      "367",
      "418",
      "438",
      "450",
      "514",
      "579",
      "581",
      "819",
      "873",
      "306",
      "639",
      "867"
    ],
    region: "North America",
    cities: [
      { name: "Toronto", slug: "ca-on-toronto", pop: 5650000 },
      { name: "Montreal", slug: "ca-qc-montreal", pop: 3670000 },
      { name: "Vancouver", slug: "ca-bc-vancouver", pop: 2400000 },
      { name: "Calgary", slug: "ca-ab-calgary", pop: 1300000 },
      { name: "Edmonton", slug: "ca-ab-edmonton", pop: 1150000 },
      { name: "Ottawa", slug: "ca-on-ottawa", pop: 1000000 },
      { name: "Winnipeg", slug: "ca-mb-winnipeg", pop: 750000 },
      { name: "Québec City", slug: "ca-qc-quebec-city", pop: 730000 },
      { name: "Hamilton", slug: "ca-on-hamilton", pop: 730000 },
      { name: "Kitchener", slug: "ca-on-kitchener", pop: 520000 },
      { name: "London", slug: "ca-on-london", pop: 420000 },
      { name: "Victoria", slug: "ca-bc-victoria" },
      { name: "Halifax", slug: "ca-ns-halifax" },
      { name: "Oshawa", slug: "ca-on-oshawa" },
      { name: "Windsor", slug: "ca-on-windsor" },
      { name: "Saskatoon", slug: "ca-sk-saskatoon" },
      { name: "Barrie", slug: "ca-on-barrie" },
      { name: "Regina", slug: "ca-sk-regina" },
      { name: "St. John's", slug: "ca-nl-st-johns" },
      { name: "Kelowna", slug: "ca-bc-kelowna" },
      { name: "Sherbrooke", slug: "ca-qc-sherbrooke" },
      { name: "Sudbury", slug: "ca-on-sudbury" },
      { name: "Chicoutimi", slug: "ca-qc-chicoutimi" },
      { name: "Kingston", slug: "ca-on-kingston" },
      { name: "Trois-Rivières", slug: "ca-qc-trois-rivieres" },
      { name: "Moncton", slug: "ca-nb-moncton" },
      { name: "Saint John", slug: "ca-nb-saint-john" },
    ],
    translations: {
      "kr": "캐나다",
      "pt-BR": "Canadá",
      "pt": "Canadá",
      "nl": "Canada",
      "hr": "Kanada",
      "fa": "کانادا",
      "de": "Kanada",
      "es": "Canadá",
      "fr": "Canada",
      "ja": "カナダ",
      "it": "Canada",
      "cn": "加拿大",
      "tr": "Kanada"
    },
    enabled: true,
    flag: "🇨🇦",
    locale: "en-CA"
  },
  {
    countryNameEn: "Switzerland",
    countryNameLocal: "Schweiz, Suisse",
    countryCode: "CH",
    currencyCode: "CHF",
    currencyNameEn: "WIR Euro (complementary currency)",
    currencySymbol: "Fr.",
    tinType: "MWST/TVA/IVA",
    tinName: "Mehrwertsteuernummer",
    officialLanguageCode: "de",
    officialLanguageNameEn: "German",
    officialLanguageNameLocal: "Deutsch",
    countryCallingCode: "41",
    region: "Europe",
    cities: [
      { name: "Zürich", slug: "ch-zh-zurich", pop: 420000 },
      { name: "Geneva", slug: "ch-ge-geneva", pop: 200000 },
      { name: "Basel", slug: "ch-bs-basel", pop: 170000 },
      { name: "Lausanne", slug: "ch-vd-lausanne", pop: 140000 },
      { name: "Bern", slug: "ch-be-bern", pop: 130000 },
      { name: "Winterthur", slug: "ch-zh-winterthur", pop: 110000 },
      { name: "Lucerne", slug: "ch-lu-lucerne", pop: 82000 },
      { name: "St. Gallen", slug: "ch-sg-st-gallen", pop: 75000 },
      { name: "Lugano", slug: "ch-ti-lugano", pop: 63000 },
      { name: "Biel/Bienne", slug: "ch-be-biel-bienne", pop: 55000 },
      { name: "Thun", slug: "ch-be-thun", pop: 44000 },
      { name: "Köniz", slug: "ch-be-koniz" },
      { name: "La Chaux-de-Fonds", slug: "ch-ne-la-chaux-de-fonds" },
      { name: "Schaffhausen", slug: "ch-sh-schaffhausen" },
      { name: "Fribourg", slug: "ch-fr-fribourg" },
      { name: "Chur", slug: "ch-gr-chur" },
      { name: "Neuchâtel", slug: "ch-ne-neuchatel" },
      { name: "Vernier", slug: "ch-ge-vernier" },
      { name: "Uster", slug: "ch-zh-uster" },
      { name: "Sion", slug: "ch-vs-sion" },
      { name: "Emmen", slug: "ch-lu-emmen" },
      { name: "Zug", slug: "ch-zg-zug" },
      { name: "Yverdon-les-Bains", slug: "ch-vd-yverdon-les-bains" },
      { name: "Dübendorf", slug: "ch-zh-dubendorf" },
      { name: "Kriens", slug: "ch-lu-kriens" },
      { name: "Rapperswil-Jona", slug: "ch-sg-rapperswil-jona" },
    ],
    translations: {
      "kr": "스위스",
      "pt-BR": "Suíça",
      "pt": "Suíça",
      "nl": "Zwitserland",
      "hr": "Švicarska",
      "fa": "سوئیس",
      "de": "Schweiz",
      "es": "Suiza",
      "fr": "Suisse",
      "ja": "スイス",
      "it": "Svizzera",
      "cn": "瑞士",
      "tr": "İsviçre"
    },
    enabled: true,
    flag: "🇨🇭",
    locale: "de-CH"
  },
  {
    countryNameEn: "Côte d'Ivoire",
    countryNameLocal: "Côte d'Ivoire",
    countryCode: "CI",
    currencyCode: "XOF",
    currencyNameEn: "CFA franc BCEAO",
    currencySymbol: "Fr",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "225",
    region: "Africa",
    flag: "🇨🇮",
    locale: "fr-CI"
  },
  {
    countryNameEn: "Chile",
    countryNameLocal: "Chile",
    countryCode: "CL",
    currencyCode: "CLP",
    currencyNameEn: "Chilean peso",
    currencySymbol: "$",
    tinType: "RUT",
    tinName: "Rol Único Tributario",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "56",
    region: "South/Latin America",
    cities: [
      { name: "Santiago", slug: "cl-rm-santiago", pop: 4600000 },
      { name: "Puente Alto", slug: "cl-rm-puente-alto", pop: 510000 },
      { name: "Antofagasta", slug: "cl-an-antofagasta", pop: 310000 },
      { name: "Valparaíso", slug: "cl-vs-valparaiso", pop: 300000 },
      { name: "Viña del Mar", slug: "cl-vs-vina-del-mar", pop: 290000 },
      { name: "Temuco", slug: "cl-ar-temuco", pop: 250000 },
      { name: "Concepción", slug: "cl-bi-concepcion", pop: 220000 },
      { name: "Rancagua", slug: "cl-li-rancagua", pop: 210000 },
      { name: "Talca", slug: "cl-ml-talca", pop: 200000 },
      { name: "Arica", slug: "cl-ar-arica", pop: 190000 },
      { name: "Talcahuano", slug: "cl-bi-talcahuano" },
      { name: "San Bernardo", slug: "cl-rm-san-bernardo" },
      { name: "Chillán", slug: "cl-bi-chillan" },
      { name: "Iquique", slug: "cl-ta-iquique" },
      { name: "Los Ángeles", slug: "cl-bi-los-angeles" },
      { name: "Puerto Montt", slug: "cl-ll-puerto-montt" },
      { name: "Coquimbo", slug: "cl-co-coquimbo" },
      { name: "Osorno", slug: "cl-ll-osorno" },
      { name: "Calama", slug: "cl-an-calama" },
      { name: "Valdivia", slug: "cl-lr-valdivia" },
      { name: "Quilpué", slug: "cl-vs-quilpue" },
      { name: "Copiapó", slug: "cl-at-copiapo" },
      { name: "Curicó", slug: "cl-ml-curico" },
      { name: "Punta Arenas", slug: "cl-ma-punta-arenas" },
      { name: "San Antonio", slug: "cl-vs-san-antonio" },
    ],
    translations: {
      "kr": "칠리",
      "pt-BR": "Chile",
      "pt": "Chile",
      "nl": "Chili",
      "hr": "Čile",
      "fa": "شیلی",
      "de": "Chile",
      "es": "Chile",
      "fr": "Chili",
      "ja": "チリ",
      "it": "Cile",
      "cn": "智利",
      "tr": "Şili"
    },
    enabled: true,
    flag: "🇨🇱",
    locale: "es-CL"
  },
  {
    countryNameEn: "Cameroon",
    countryNameLocal: "Cameroun, Cameroon",
    countryCode: "CM",
    currencyCode: "XAF",
    currencyNameEn: "CFA franc BEAC",
    currencySymbol: "Fr",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "237",
    region: "Africa",
    flag: "🇨🇲",
    locale: "en-CM",
    cryptoProhibited: true
  },
  {
    countryNameEn: "China",
    countryNameLocal: "中国",
    countryCode: "CN",
    currencyCode: "CNY",
    currencyNameEn: "Renminbi (Chinese) yuan",
    currencySymbol: "¥",
    officialLanguageCode: "zh-hans",
    officialLanguageNameEn: "",
    officialLanguageNameLocal: "",
    countryCallingCode: "86",
    region: "Asia & Pacific",
    cities: [
      { name: "Shanghai", slug: "cn-sh-shanghai", pop: 22000000 },
      { name: "Beijing", slug: "cn-bj-beijing", pop: 19000000 },
      { name: "Shenzhen", slug: "cn-gd-shenzhen", pop: 17000000 },
      { name: "Guangzhou", slug: "cn-gd-guangzhou", pop: 16000000 },
      { name: "Chengdu", slug: "cn-sc-chengdu", pop: 13500000 },
      { name: "Tianjin", slug: "cn-tj-tianjin", pop: 11000000 },
      { name: "Wuhan", slug: "cn-hb-wuhan", pop: 10000000 },
      { name: "Dongguan", slug: "cn-gd-dongguan", pop: 9600000 },
      { name: "Chongqing", slug: "cn-cq-chongqing", pop: 9500000 },
      { name: "Xi'an", slug: "cn-sa-xian", pop: 9300000 },
      { name: "Hangzhou", slug: "cn-zj-hangzhou", pop: 9200000 },
      { name: "Nanjing", slug: "cn-js-nanjing", pop: 7500000 },
      { name: "Suzhou", slug: "cn-js-suzhou" },
      { name: "Dalian", slug: "cn-ln-dalian" },
      { name: "Qingdao", slug: "cn-sd-qingdao" },
      { name: "Changsha", slug: "cn-hn-changsha" },
      { name: "Ningbo", slug: "cn-zj-ningbo" },
      { name: "Zhengzhou", slug: "cn-ha-zhengzhou" },
      { name: "Shenyang", slug: "cn-ln-shenyang" },
      { name: "Wuxi", slug: "cn-js-wuxi" },
      { name: "Foshan", slug: "cn-gd-foshan" },
      { name: "Shijiazhuang", slug: "cn-he-shijiazhuang" },
      { name: "Harbin", slug: "cn-hl-harbin" },
      { name: "Hefei", slug: "cn-ah-hefei" },
      { name: "Changchun", slug: "cn-jl-changchun" },
      { name: "Jinan", slug: "cn-sd-jinan" },
    ],
    translations: {
      "kr": "중국",
      "pt-BR": "China",
      "pt": "China",
      "nl": "China",
      "hr": "Kina",
      "fa": "چین",
      "de": "China",
      "es": "China",
      "fr": "Chine",
      "ja": "中国",
      "it": "Cina",
      "cn": "中国",
      "tr": "Çin"
    },
    enabled: true,
    flag: "🇨🇳",
    locale: "zh-CN",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Colombia",
    countryNameLocal: "Colombia",
    countryCode: "CO",
    currencyCode: "COP",
    currencyNameEn: "Colombian peso",
    currencySymbol: "$",
    tinType: "NIT",
    tinName: "Número De Identificación Tributaria",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "57",
    region: "South/Latin America",
    cities: [
      { name: "Bogotá", slug: "co-cun-bogota", pop: 8200000 },
      { name: "Medellín", slug: "co-ant-medellin", pop: 2500000 },
      { name: "Cali", slug: "co-val-cali", pop: 2400000 },
      { name: "Barranquilla", slug: "co-atl-barranquilla", pop: 1200000 },
      { name: "Cartagena", slug: "co-bol-cartagena", pop: 1000000 },
      { name: "Cúcuta", slug: "co-nsa-cucuta", pop: 670000 },
      { name: "Soledad", slug: "co-atl-soledad", pop: 650000 },
      { name: "Ibagué", slug: "co-tol-ibague", pop: 570000 },
      { name: "Soacha", slug: "co-cun-soacha", pop: 540000 },
      { name: "Bucaramanga", slug: "co-san-bucaramanga", pop: 530000 },
      { name: "Pereira", slug: "co-ris-pereira" },
      { name: "Santa Marta", slug: "co-mag-santa-marta" },
      { name: "Pasto", slug: "co-nar-pasto" },
      { name: "Manizales", slug: "co-cal-manizales" },
      { name: "Neiva", slug: "co-hui-neiva" },
      { name: "Armenia", slug: "co-qui-armenia" },
      { name: "Villavicencio", slug: "co-meta-villavicencio" },
      { name: "Valledupar", slug: "co-ces-valledupar" },
      { name: "Montería", slug: "co-cor-monteria" },
      { name: "Itagüí", slug: "co-ant-itagui" },
      { name: "Palmira", slug: "co-val-palmira" },
      { name: "Bello", slug: "co-ant-bello" },
      { name: "Popayán", slug: "co-cau-popayan" },
      { name: "Floridablanca", slug: "co-san-floridablanca" },
      { name: "Sincelejo", slug: "co-sucre-sincelejo" },
    ],
    translations: {
      "kr": "콜롬비아",
      "pt-BR": "Colômbia",
      "pt": "Colômbia",
      "nl": "Colombia",
      "hr": "Kolumbija",
      "fa": "کلمبیا",
      "de": "Kolumbien",
      "es": "Colombia",
      "fr": "Colombie",
      "ja": "コロンビア",
      "it": "Colombia",
      "cn": "哥伦比亚",
      "tr": "Kolombiya"
    },
    enabled: true,
    flag: "🇨🇴",
    locale: "es-CO"
  },
  {
    countryNameEn: "Costa Rica",
    countryNameLocal: "Costa Rica",
    countryCode: "CR",
    currencyCode: "CRC",
    currencyNameEn: "Costa Rican colon",
    currencySymbol: "₡",
    tinName: "Cédula Jurídica",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "506",
    region: "South/Latin America",
    flag: "🇨🇷",
    locale: "es-CR"
  },
  {
    countryNameEn: "Cuba",
    countryNameLocal: "Cuba",
    countryCode: "CU",
    currencyCode: "CUC",
    currencyNameEn: "Cuban convertible peso",
    currencySymbol: "$",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "53",
    region: "South/Latin America",
    blocked: true,
    flag: "🇨🇺",
    locale: "es-CU"
  },
  {
    countryNameEn: "Cabo Verde",
    countryNameLocal: "Cabo Verde",
    countryCode: "CV",
    currencyCode: "CVE",
    currencyNameEn: "Cape Verdean escudo",
    currencySymbol: "Esc",
    officialLanguageCode: "pt",
    officialLanguageNameEn: "Portuguese",
    officialLanguageNameLocal: "Português",
    countryCallingCode: "238",
    region: "Africa",
    flag: "🇨🇻",
    locale: "pt-CV"
  },
  {
    countryNameEn: "Curaçao",
    countryNameLocal: "Curaçao",
    countryCode: "CW",
    currencyCode: "ANG",
    currencyNameEn: "Netherlands Antillean guilder",
    currencySymbol: "ƒ",
    officialLanguageCode: "nl",
    officialLanguageNameEn: "Dutch, Flemish",
    officialLanguageNameLocal: "Nederlands, Vlaams",
    countryCallingCode: "599",
    region: "Unknown",
    flag: "🇨🇼",
    locale: "en-CW"
  },
  {
    countryNameEn: "Christmas Island",
    countryNameLocal: "Christmas Island",
    countryCode: "CX",
    currencyCode: "AUD",
    currencyNameEn: "Australian dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "61",
    region: "Asia & Pacific",
    flag: "🇨🇽",
    locale: "en-CX"
  },
  {
    countryNameEn: "Cyprus",
    countryNameLocal: "Κύπρος, Kibris",
    countryCode: "CY",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "ΦΠΑ",
    tinName: "Αριθμός Εγγραφής Φ.Π.Α.",
    officialLanguageCode: "el",
    officialLanguageNameEn: "Greek, Modern (1453-)",
    officialLanguageNameLocal: "ελληνικά",
    countryCallingCode: "357",
    region: "Europe",
    flag: "🇨🇾",
    locale: "el-CY"
  },
  {
    countryNameEn: "Germany",
    countryNameLocal: "Deutschland",
    countryCode: "DE",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "USt-IdNr.",
    tinName: "Umsatzsteuer-Identifikationsnummer",
    officialLanguageCode: "de",
    officialLanguageNameEn: "German",
    officialLanguageNameLocal: "Deutsch",
    countryCallingCode: "49",
    region: "Europe",
    cities: [
      { name: "Berlin", slug: "de-be-berlin", pop: 3600000 },
      { name: "Hamburg", slug: "de-hh-hamburg", pop: 1900000 },
      { name: "Munich", slug: "de-by-munich", pop: 1500000 },
      { name: "Cologne", slug: "de-nw-cologne", pop: 1000000 },
      { name: "Frankfurt am Main", slug: "de-he-frankfurt-am-main", pop: 750000 },
      { name: "Stuttgart", slug: "de-bw-stuttgart", pop: 600000 },
      { name: "Düsseldorf", slug: "de-nw-dusseldorf", pop: 600000 },
      { name: "Leipzig", slug: "de-sn-leipzig", pop: 600000 },
      { name: "Dortmund", slug: "de-nw-dortmund", pop: 580000 },
      { name: "Essen", slug: "de-nw-essen", pop: 580000 },
      { name: "Bremen", slug: "de-hb-bremen" },
      { name: "Dresden", slug: "de-sn-dresden" },
      { name: "Hanover", slug: "de-ni-hanover" },
      { name: "Nuremberg", slug: "de-by-nuremberg" },
      { name: "Duisburg", slug: "de-nw-duisburg" },
      { name: "Bochum", slug: "de-nw-bochum" },
      { name: "Wuppertal", slug: "de-nw-wuppertal" },
      { name: "Bielefeld", slug: "de-nw-bielefeld" },
      { name: "Bonn", slug: "de-nw-bonn" },
      { name: "Münster", slug: "de-nw-munster" },
      { name: "Karlsruhe", slug: "de-bw-karlsruhe" },
      { name: "Mannheim", slug: "de-bw-mannheim" },
      { name: "Augsburg", slug: "de-by-augsburg" },
      { name: "Wiesbaden", slug: "de-he-wiesbaden" },
      { name: "Gelsenkirchen", slug: "de-nw-gelsenkirchen" },
    ],
    translations: {
      "kr": "독일",
      "pt-BR": "Alemanha",
      "pt": "Alemanha",
      "nl": "Duitsland",
      "hr": "Njemačka",
      "fa": "آلمان",
      "de": "Deutschland",
      "es": "Alemania",
      "fr": "Allemagne",
      "ja": "ドイツ",
      "it": "Germania",
      "cn": "德国",
      "tr": "Almanya"
    },
    enabled: true,
    flag: "🇩🇪",
    locale: "de-DE"
  },
  {
    countryNameEn: "Djibouti",
    countryNameLocal: "Djibouti, جيبوتي, Jabuuti, Gabuutih",
    countryCode: "DJ",
    currencyCode: "DJF",
    currencyNameEn: "Djiboutian franc",
    currencySymbol: "Fr",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "253",
    region: "Arab States",
    flag: "🇩🇯",
    blocked: true,
    locale: "ar-DJ"
  },
  {
    countryNameEn: "Denmark",
    countryNameLocal: "Danmark",
    countryCode: "DK",
    currencyCode: "DKK",
    currencyNameEn: "Danish krone",
    currencySymbol: "kr",
    tinType: "CVR",
    tinName: "Momsregistreringsnummer",
    officialLanguageCode: "da",
    officialLanguageNameEn: "Danish",
    officialLanguageNameLocal: "dansk",
    countryCallingCode: "45",
    region: "Europe",
    cities: [
      { name: "Copenhagen", slug: "dk-hovedstaden-copenhagen", pop: 1300000 },
      { name: "Aarhus", slug: "dk-midtjylland-aarhus", pop: 290000 },
      { name: "Odense", slug: "dk-syddanmark-odense", pop: 180000 },
      { name: "Aalborg", slug: "dk-nordjylland-aalborg", pop: 120000 },
      { name: "Esbjerg", slug: "dk-syddanmark-esbjerg", pop: 72000 },
      { name: "Randers", slug: "dk-midtjylland-randers", pop: 62000 },
      { name: "Horsens", slug: "dk-midtjylland-horsens", pop: 63000 },
      { name: "Kolding", slug: "dk-syddanmark-kolding", pop: 62000 },
      { name: "Vejle", slug: "dk-syddanmark-vejle", pop: 61000 },
      { name: "Roskilde", slug: "dk-sjaelland-roskilde", pop: 52000 },
      { name: "Herning", slug: "dk-midtjylland-herning" },
      { name: "Helsingør", slug: "dk-hovedstaden-helsingor" },
      { name: "Silkeborg", slug: "dk-midtjylland-silkeborg" },
      { name: "Næstved", slug: "dk-sjaelland-naestved" },
      { name: "Fredericia", slug: "dk-syddanmark-fredericia" },
      { name: "Frederikshavn", slug: "dk-nordjylland-frederikshavn" },
      { name: "Køge", slug: "dk-sjaelland-koge" },
      { name: "Hillerød", slug: "dk-hovedstaden-hillerod" },
      { name: "Hørsholm", slug: "dk-hovedstaden-horsholm" },
      { name: "Holstebro", slug: "dk-midtjylland-holstebro" },
      { name: "Taastrup", slug: "dk-hovedstaden-taastrup" },
      { name: "Slagelse", slug: "dk-sjaelland-slagelse" },
      { name: "Gloucester", slug: "dk-sjaelland-gloucester" },
    ],
    translations: {
      "kr": "덴마크",
      "pt-BR": "Dinamarca",
      "pt": "Dinamarca",
      "nl": "Denemarken",
      "hr": "Danska",
      "fa": "دانمارک",
      "de": "Dänemark",
      "es": "Dinamarca",
      "fr": "Danemark",
      "ja": "デンマーク",
      "it": "Danimarca",
      "cn": "丹麦",
      "tr": "Danimarka"
    },
    enabled: true,
    flag: "🇩🇰",
    locale: "da-DK"
  },
  {
    countryNameEn: "Dominica",
    countryNameLocal: "Dominica",
    countryCode: "DM",
    currencyCode: "XCD",
    currencyNameEn: "East Caribbean dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "767",
    region: "South/Latin America",
    flag: "🇩🇲",
    locale: "en-DM"
  },
  {
    countryNameEn: "Algeria",
    countryNameLocal: "الجزائر",
    countryCode: "DZ",
    currencyCode: "DZD",
    currencyNameEn: "Algerian dinar",
    currencySymbol: "د.ج",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "213",
    region: "Arab States",
    flag: "🇩🇿",
    blocked: true,
    locale: "ar-DZ",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Ecuador",
    countryNameLocal: "Ecuador",
    countryCode: "EC",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    currencySymbol: "$",
    tinType: "RUC",
    tinName: "Número de Registro Unico de Contribuyentes",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "593",
    region: "South/Latin America",
    flag: "🇪🇨",
    locale: "es-EC",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Estonia",
    countryNameLocal: "Eesti",
    countryCode: "EE",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "KMKR",
    tinName: "Käibemaksukohustuslase number",
    officialLanguageCode: "et",
    officialLanguageNameEn: "Estonian",
    officialLanguageNameLocal: "eesti, eesti keel",
    countryCallingCode: "372",
    region: "Europe",
    cities: [
      { name: "Tallinn", slug: "ee-harju-tallinn", pop: 430000 },
      { name: "Tartu", slug: "ee-tartu-tartu", pop: 100000 },
      { name: "Narva", slug: "ee-ida-viru-narva", pop: 60000 },
      { name: "Pärnu", slug: "ee-parnu-parnu", pop: 44000 },
      { name: "Kohtla-Järve", slug: "ee-ida-viru-kohtla-jarve", pop: 46000 },
      { name: "Viljandi", slug: "ee-viljandi-viljandi", pop: 17000 },
      { name: "Rakvere", slug: "ee-laane-viru-rakvere", pop: 16000 },
      { name: "Maardu", slug: "ee-harju-maardu", pop: 16000 },
      { name: "Kuressaare", slug: "ee-saare-kuressaare", pop: 14000 },
      { name: "Sillamäe", slug: "ee-ida-viru-sillamae", pop: 13000 },
      { name: "Võru", slug: "ee-voru-voru" },
      { name: "Valga", slug: "ee-valga-valga" },
      { name: "Jõhvi", slug: "ee-ida-viru-johvi" },
      { name: "Haapsalu", slug: "ee-laane-harju-haapsalu" },
      { name: "Paide", slug: "ee-jarva-paide" },
      { name: "Keila", slug: "ee-harju-keila" },
      { name: "Tapa", slug: "ee-laane-viru-tapa" },
      { name: "Põlva", slug: "ee-polva-polva" },
      { name: "Jõgeva", slug: "ee-jogeva-jogeva" },
      { name: "Türi", slug: "ee-jarva-turi" },
      { name: "Elva", slug: "ee-tartu-elva" },
      { name: "Rapla", slug: "ee-rapla-rapla" },
      { name: "Saue", slug: "ee-harju-sauelinn" },
    ],
    translations: {
      "kr": "에스토니아",
      "pt-BR": "Estônia",
      "pt": "Estónia",
      "nl": "Estland",
      "hr": "Estonija",
      "fa": "استونی",
      "de": "Estland",
      "es": "Estonia",
      "fr": "Estonie",
      "ja": "エストニア",
      "it": "Estonia",
      "cn": "爱沙尼亚",
      "tr": "Estonya"
    },
    enabled: true,
    flag: "🇪🇪",
    locale: "et-EE"
  },
  {
    countryNameEn: "Egypt",
    countryNameLocal: "مصر",
    countryCode: "EG",
    currencyCode: "EGP",
    currencyNameEn: "Egyptian pound",
    currencySymbol: "£",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "20",
    region: "Arab States",
    flag: "🇪🇬",
    blocked: true,
    locale: "ar-EG",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Western Sahara",
    countryNameLocal: "Sahara Occidental",
    countryCode: "EH",
    currencyCode: "MAD",
    currencyNameEn: "Moroccan dirham",
    currencySymbol: "DH",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "212",
    region: "Africa",
    flag: "🇪🇭",
    blocked: true,
    locale: "es-EH"
  },
  {
    countryNameEn: "Eritrea",
    countryNameLocal: "ኤርትራ, إرتريا, Eritrea",
    countryCode: "ER",
    currencyCode: "ERN",
    currencyNameEn: "Eritrean nakfa",
    currencySymbol: "Nfk",
    officialLanguageCode: "ti",
    officialLanguageNameEn: "Tigrinya",
    officialLanguageNameLocal: "ትግርኛ",
    countryCallingCode: "291",
    region: "Africa",
    flag: "🇪🇷",
    locale: "aa-ER"
  },
  {
    countryNameEn: "Spain",
    countryNameLocal: "España",
    countryCode: "ES",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "NIF (CIF)",
    tinName: "Número de Identificación Fiscal (formerly named Código de Identificación Fiscal)",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "34",
    region: "Europe",
    cities: [
      { name: "Madrid", slug: "es-mad-madrid", pop: 7000000 },
      { name: "Barcelona", slug: "es-cat-barcelona", pop: 5200000 },
      { name: "Valencia", slug: "es-vlc-valencia", pop: 1700000 },
      { name: "Seville", slug: "es-and-seville", pop: 1500000 },
      { name: "Bilbao", slug: "es-pv-bilbao", pop: 1000000 },
      { name: "Málaga", slug: "es-and-malaga", pop: 950000 },
      { name: "Asturias", slug: "es-ast-asturias", pop: 850000 },
      { name: "Alicante", slug: "es-vlc-alicante", pop: 800000 },
      { name: "Las Palmas", slug: "es-gc-las-palmas", pop: 650000 },
      { name: "Zaragoza", slug: "es-ara-zaragoza", pop: 650000 },
      { name: "Murcia", slug: "es-mur-murcia" },
      { name: "Palma", slug: "es-bal-palma" },
      { name: "Córdoba", slug: "es-and-cordoba" },
      { name: "Valladolid", slug: "es-va-valladolid" },
      { name: "Vigo", slug: "es-ga-vigo" },
      { name: "Gijón", slug: "es-ast-gijon" },
      { name: "Hospitalet de Llobregat", slug: "es-cat-hospitalet-de-llobregat" },
      { name: "La Coruña", slug: "es-ga-la-coruna" },
      { name: "Granada", slug: "es-and-granada" },
      { name: "Vitoria-Gasteiz", slug: "es-pv-vitoria-gasteiz" },
      { name: "Santa Cruz de Tenerife", slug: "es-tf-santa-cruz-de-tenerife" },
      { name: "Badalona", slug: "es-cat-badalona" },
      { name: "Oviedo", slug: "es-ast-oviedo" },
      { name: "Móstoles", slug: "es-mad-mostoles" },
      { name: "Elche", slug: "es-vlc-elche" },
    ],
    translations: {
      "kr": "스페인",
      "pt-BR": "Espanha",
      "pt": "Espanha",
      "nl": "Spanje",
      "hr": "Španjolska",
      "fa": "اسپانیا",
      "de": "Spanien",
      "es": "España",
      "fr": "Espagne",
      "ja": "スペイン",
      "it": "Spagna",
      "cn": "西班牙",
      "tr": "İspanya"
    },
    enabled: true,
    flag: "🇪🇸",
    locale: "es-ES"
  },
  {
    countryNameEn: "Ethiopia",
    countryNameLocal: "ኢትዮጵያ, Itoophiyaa",
    countryCode: "ET",
    currencyCode: "ETB",
    currencyNameEn: "Ethiopian birr",
    currencySymbol: "Br",
    officialLanguageCode: "am",
    officialLanguageNameEn: "Amharic",
    officialLanguageNameLocal: "አማርኛ",
    countryCallingCode: "251",
    region: "Africa",
    flag: "🇪🇹",
    locale: "am-ET",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Finland",
    countryNameLocal: "Suomi",
    countryCode: "FI",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "ALV nro",
    tinName: "Arvonlisäveronumero",
    officialLanguageCode: "fi",
    officialLanguageNameEn: "Finnish",
    officialLanguageNameLocal: "suomi, suomen kieli",
    countryCallingCode: "358",
    region: "Europe",
    cities: [
      { name: "Helsinki", slug: "fi-uusimaa-helsinki", pop: 1300000 },
      { name: "Tampere", slug: "fi-pirkanmaa-tampere", pop: 340000 },
      { name: "Turku", slug: "fi-varsinais-suomi-turku", pop: 270000 },
      { name: "Oulu", slug: "fi-pohjois-pohjanmaa-oulu", pop: 200000 },
      { name: "Jyväskylä", slug: "fi-keski-suomi-jyvaskyla", pop: 130000 },
      { name: "Lahti", slug: "fi-paijat-hame-lahti", pop: 120000 },
      { name: "Kuopio", slug: "fi-pohjois-savo-kuopio", pop: 90000 },
      { name: "Pori", slug: "fi-satakunta-pori", pop: 83000 },
      { name: "Joensuu", slug: "fi-pohjois-karjala-joensuu", pop: 72000 },
      { name: "Vaasa", slug: "fi-pohjanmaa-vaasa", pop: 67000 },
      { name: "Espoo", slug: "fi-uusimaa-espoo" },
      { name: "Vantaa", slug: "fi-uusimaa-vantaa" },
      { name: "Kouvola", slug: "fi-kymenlaakso-kouvola" },
      { name: "Lappeenranta", slug: "fi-etela-karjala-lappeenranta" },
      { name: "Hämeenlinna", slug: "fi-kanta-hame-hameenlinna" },
      { name: "Seinäjoki", slug: "fi-etela-pohjanmaa-seinajoki" },
      { name: "Rovaniemi", slug: "fi-lappi-rovaniemi" },
      { name: "Mikkeli", slug: "fi-etela-savo-mikkeli" },
      { name: "Salo", slug: "fi-varsinais-suomi-salo" },
      { name: "Kotka", slug: "fi-kymenlaakso-kotka" },
      { name: "Lohja", slug: "fi-uusimaa-lohja" },
      { name: "Porvoo", slug: "fi-uusimaa-porvoo" },
    ],
    translations: {
      "kr": "핀란드",
      "pt-BR": "Finlândia",
      "pt": "Finlândia",
      "nl": "Finland",
      "hr": "Finska",
      "fa": "فنلاند",
      "de": "Finnland",
      "es": "Finlandia",
      "fr": "Finlande",
      "ja": "フィンランド",
      "it": "Finlandia",
      "cn": "芬兰",
      "tr": "Finlandiya"
    },
    enabled: true,
    flag: "🇫🇮",
    locale: "fi-FI"
  },
  {
    countryNameEn: "Fiji",
    countryNameLocal: "Fiji",
    countryCode: "FJ",
    currencyCode: "FJD",
    currencyNameEn: "Fiji dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "679",
    region: "Asia & Pacific",
    flag: "🇫🇯",
    locale: "en-FJ"
  },
  {
    countryNameEn: "Micronesia (Federated States of)",
    countryNameLocal: "Micronesia",
    countryCode: "FM",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "691",
    region: "Asia & Pacific",
    flag: "🇫🇲",
    locale: "en-FM"
  },
  {
    countryNameEn: "France",
    countryNameLocal: "France",
    countryCode: "FR",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "n° TVA",
    tinName: "Numéro d'identification à la taxe sur la valeur ajoutée / Numéro de TVA intracommunautaire",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "33",
    region: "Europe",
    cities: [
      { name: "Paris", slug: "fr-idf-paris", pop: 2160000 },
      { name: "Marseille", slug: "fr-pac-marseille", pop: 870000 },
      { name: "Lyon", slug: "fr-ara-lyon", pop: 520000 },
      { name: "Toulouse", slug: "fr-occ-toulouse", pop: 490000 },
      { name: "Nice", slug: "fr-pac-nice", pop: 340000 },
      { name: "Nantes", slug: "fr-pdl-nantes", pop: 320000 },
      { name: "Montpellier", slug: "fr-occ-montpellier", pop: 295000 },
      { name: "Strasbourg", slug: "fr-ge-strasbourg", pop: 285000 },
      { name: "Bordeaux", slug: "fr-naq-bordeaux", pop: 260000 },
      { name: "Lille", slug: "fr-hdf-lille", pop: 230000 },
      { name: "Rennes", slug: "fr-bzh-rennes" },
      { name: "Reims", slug: "fr-ge-reims" },
      { name: "Le Havre", slug: "fr-nor-le-havre" },
      { name: "Saint-Étienne", slug: "fr-ara-saint-etienne" },
      { name: "Toulon", slug: "fr-pac-toulon" },
      { name: "Grenoble", slug: "fr-ara-grenoble" },
      { name: "Dijon", slug: "fr-bfc-dijon" },
      { name: "Angers", slug: "fr-pdl-angers" },
      { name: "Nîmes", slug: "fr-occ-nimes" },
      { name: "Villeurbanne", slug: "fr-ara-villeurbanne" },
      { name: "Le Mans", slug: "fr-pdl-le-mans" },
      { name: "Aix-en-Provence", slug: "fr-pac-aix-en-provence" },
      { name: "Brest", slug: "fr-bzh-brest" },
      { name: "Troyes", slug: "fr-ge-troyes" },
    ],
    translations: {
      "kr": "프랑스",
      "pt-BR": "França",
      "pt": "França",
      "nl": "Frankrijk",
      "hr": "Francuska",
      "fa": "فرانسه",
      "de": "Frankreich",
      "es": "Francia",
      "fr": "France",
      "ja": "フランス",
      "it": "Francia",
      "cn": "法国",
      "tr": "Fransa"
    },
    enabled: true,
    flag: "🇫🇷",
    locale: "fr-FR"
  },
  {
    countryNameEn: "Gabon",
    countryNameLocal: "Gabon",
    countryCode: "GA",
    currencyCode: "XAF",
    currencyNameEn: "CFA franc BEAC",
    currencySymbol: "Fr",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "241",
    region: "Africa",
    flag: "🇬🇦",
    locale: "fr-GA",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Grenada",
    countryNameLocal: "Grenada",
    countryCode: "GD",
    currencyCode: "XCD",
    currencyNameEn: "East Caribbean dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1473",
    region: "South/Latin America",
    flag: "🇬🇩",
    locale: "en-GD"
  },
  {
    countryNameEn: "Georgia",
    countryNameLocal: "საქართველო",
    countryCode: "GE",
    currencyCode: "GEL",
    currencyNameEn: "Georgian lari",
    currencySymbol: "₾",
    officialLanguageCode: "ka",
    officialLanguageNameEn: "Georgian",
    officialLanguageNameLocal: "ქართული",
    countryCallingCode: "995",
    region: "Europe",
    flag: "🇬🇪",
    locale: "ka-GE"
  },
  {
    countryNameEn: "French Guiana",
    countryNameLocal: "Guyane française",
    countryCode: "GF",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "594",
    region: "South/Latin America",
    flag: "🇬🇫",
    locale: "fr-GF"
  },
  {
    countryNameEn: "Guernsey",
    countryNameLocal: "Guernsey",
    countryCode: "GG",
    currencyCode: "GBP",
    currencyNameEn: "Pound sterling",
    currencySymbol: "£",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "44",
    region: "Europe",
    flag: "🇬🇬",
    locale: "en-GG"
  },
  {
    countryNameEn: "Ghana",
    countryNameLocal: "Ghana",
    countryCode: "GH",
    currencyCode: "GHS",
    currencyNameEn: "Ghanaian cedi",
    currencySymbol: "₵",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "233",
    region: "Africa",
    flag: "🇬🇭",
    locale: "en-GH"
  },
  {
    countryNameEn: "Gibraltar",
    countryNameLocal: "Gibraltar",
    countryCode: "GI",
    currencyCode: "GIP",
    currencyNameEn: "Gibraltar pound",
    currencySymbol: "£",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "350",
    region: "Europe",
    flag: "🇬🇮",
    locale: "en-GI"
  },
  {
    countryNameEn: "Greenland",
    countryNameLocal: "Kalaallit Nunaat, Grønland",
    countryCode: "GL",
    currencyCode: "DKK",
    currencyNameEn: "Danish krone",
    currencySymbol: "kr.",
    officialLanguageCode: "kl",
    officialLanguageNameEn: "Kalaallisut, Greenlandic",
    officialLanguageNameLocal: "kalaallisut, kalaallit oqaasii",
    countryCallingCode: "299",
    region: "Europe",
    cities: [
      { name: "Nuuk", slug: "gl-nuuk", pop: 18000 },
      { name: "Sisimiut", slug: "gl-sisimiut", pop: 5500 },
      { name: "Ilulissat", slug: "gl-ilulissat", pop: 4900 },
      { name: "Qaqortoq", slug: "gl-qaqortoq", pop: 3000 },
      { name: "Aasiaat", slug: "gl-aasiaat", pop: 2900 },
      { name: "Maniitsoq", slug: "gl-maniitsoq", pop: 2500 },
      { name: "Tasiilaq", slug: "gl-tasiilaq", pop: 1800 },
      { name: "Uummannaq", slug: "gl-uummannaq", pop: 1300 },
      { name: "Paamiut", slug: "gl-paamiut", pop: 1200 },
      { name: "Nanortalik", slug: "gl-nanortalik" },
      { name: "Qasigiannguit", slug: "gl-qasigiannguit" },
      { name: "Kangerlussuaq", slug: "gl-kangerlussuaq" },
      { name: "Upernavik", slug: "gl-upernavik" },
      { name: "Qeqertarsuaq", slug: "gl-qeqertarsuaq" },
      { name: "Narsaq", slug: "gl-narsaq" },
      { name: "Kangaatsiaq", slug: "gl-kangaatsiaq" },
      { name: "Uummannaq", slug: "gl-uummannaq" },
      { name: "Qaanaaq", slug: "gl-qaanaaq" },
      { name: "Ittoqqortoormiit", slug: "gl-ittoqqortoormiit" },
      { name: "Kullorsuaq", slug: "gl-kullorsuaq" },
      { name: "Qeqertaq", slug: "gl-qeqertaq" },
      { name: "Qasigiannguit", slug: "gl-qasigiannguit" },
      { name: "Kangaatsiaq", slug: "gl-kangaatsiaq" },
      { name: "Qaanaaq", slug: "gl-qaanaaq" },
    ],
    translations: {
      "kr": "그린란드",
      "pt-BR": "Groelândia",
      "pt": "Gronelândia",
      "nl": "Groenland",
      "hr": "Grenland",
      "fa": "گرینلند",
      "de": "Grönland",
      "es": "Groenlandia",
      "fr": "Groenland",
      "ja": "グリーンランド",
      "it": "Groenlandia",
      "cn": "格陵兰岛",
      "tr": "Grönland"
    },
    enabled: true,
    flag: "🇬🇱",
    locale: "kl-GL"
  },
  {
    countryNameEn: "Guinea",
    countryNameLocal: "Guinée",
    countryCode: "GN",
    currencyCode: "GNF",
    currencyNameEn: "Guinean franc",
    currencySymbol: "Fr",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "224",
    region: "Africa",
    flag: "🇬🇳",
    locale: "fr-GN"
  },
  {
    countryNameEn: "Guadeloupe",
    countryNameLocal: "Guadeloupe",
    countryCode: "GP",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "590",
    region: "South/Latin America",
    flag: "🇬🇵",
    locale: "fr-GP"
  },
  {
    countryNameEn: "Equatorial Guinea",
    countryNameLocal: "Guiena ecuatorial, Guinée équatoriale, Guiné Equatorial",
    countryCode: "GQ",
    currencyCode: "XAF",
    currencyNameEn: "CFA franc BEAC",
    currencySymbol: "Fr",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "240",
    region: "Africa",
    flag: "🇬🇶",
    locale: "fan-GQ"
  },
  {
    countryNameEn: "Greece",
    countryNameLocal: "Ελλάδα",
    countryCode: "GR",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    officialLanguageCode: "el",
    officialLanguageNameEn: "Greek, Modern (1453-)",
    officialLanguageNameLocal: "ελληνικά",
    countryCallingCode: "30",
    region: "Europe",
    cities: [
      { name: "Athens", slug: "gr-att-athens", pop: 640000 },
      { name: "Thessaloniki", slug: "gr-c-macedonia-thessaloniki", pop: 309000 },
      { name: "Patras", slug: "gr-c-peloponnese-patras", pop: 170000 },
      { name: "Piraeus", slug: "gr-att-piraeus", pop: 168000 },
      { name: "Heraklion", slug: "gr-c-crete-heraklion", pop: 149000 },
      { name: "Larissa", slug: "gr-c-thessaly-larissa", pop: 146000 },
      { name: "Volos", slug: "gr-c-thessaly-volos", pop: 85000 },
      { name: "Ioannina", slug: "gr-c-epirus-ioannina", pop: 65000 },
      { name: "Chalcis", slug: "gr-c-central-greece-chalcis", pop: 64000 },
      { name: "Trikala", slug: "gr-c-thessaly-trikala", pop: 62000 },
      { name: "Rhodes", slug: "gr-c-south-aegean-rhodes" },
      { name: "Chania", slug: "gr-c-crete-chania" },
      { name: "Chalkida", slug: "gr-c-central-greece-chalkida" },
      { name: "Kavala", slug: "gr-c-east-macedonia-kavala" },
      { name: "Komotini", slug: "gr-c-east-macedonia-komotini" },
      { name: "Alexandroupoli", slug: "gr-c-east-macedonia-alexandroupoli" },
      { name: "Kalamata", slug: "gr-c-peloponnese-kalamata" },
      { name: "Lamia", slug: "gr-c-central-greece-lamia" },
      { name: "Mytilene", slug: "gr-c-north-aegean-mytilene" },
      { name: "Tripoli", slug: "gr-c-peloponnese-tripoli" },
      { name: "Corinth", slug: "gr-c-peloponnese-corinth" },
      { name: "Sparta", slug: "gr-c-peloponnese-sparta" },
      { name: "Argos", slug: "gr-c-peloponnese-argos" },
      { name: "Katerini", slug: "gr-c-central-macedonia-katerini" },
    ],
    translations: {
      "kr": "그리스",
      "pt-BR": "Grécia",
      "pt": "Grécia",
      "nl": "Griekenland",
      "hr": "Grčka",
      "fa": "یونان",
      "de": "Griechenland",
      "es": "Grecia",
      "fr": "Grèce",
      "ja": "ギリシャ",
      "it": "Grecia",
      "cn": "希腊",
      "tr": "Yunanistan"
    },
    enabled: true,
    flag: "🇬🇷",
    locale: "el-GR"
  },
  {
    countryNameEn: "South Georgia and the South Sandwich Islands",
    countryNameLocal: "South Georgia and the South Sandwich Islands",
    countryCode: "GS",
    currencyCode: "",
    currencyNameEn: "",
    currencySymbol: "",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "500",
    region: "South/Latin America",
    flag: "🇬🇸",
    locale: "en-GS"
  },
  {
    countryNameEn: "Guatemala",
    countryNameLocal: "Guatemala",
    countryCode: "GT",
    currencyCode: "GTQ",
    currencyNameEn: "Guatemalan quetzal",
    currencySymbol: "Q",
    tinType: "NIT",
    tinName: "Número de Identificación Tributaria",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "502",
    region: "South/Latin America",
    flag: "🇬🇹",
    locale: "es-GT"
  },
  {
    countryNameEn: "Guam",
    countryNameLocal: "Guam, Guåhån",
    countryCode: "GU",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1",
    region: "Asia & Pacific",
    flag: "🇬🇺",
    locale: "ch-GU"
  },
  {
    countryNameEn: "Guinea-Bissau",
    countryNameLocal: "Guiné-Bissau",
    countryCode: "GW",
    currencyCode: "XOF",
    currencyNameEn: "CFA franc BCEAO",
    currencySymbol: "Fr",
    officialLanguageCode: "pt",
    officialLanguageNameEn: "Portuguese",
    officialLanguageNameLocal: "Português",
    countryCallingCode: "245",
    region: "Africa",
    flag: "🇬🇼",
    locale: "pt-GW"
  },
  {
    countryNameEn: "Guyana",
    countryNameLocal: "Guyana",
    countryCode: "GY",
    currencyCode: "GYD",
    currencyNameEn: "Guyanese dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "592",
    region: "South/Latin America",
    flag: "🇬🇾",
    locale: "en-GY"
  },
  {
    countryNameEn: "Hong Kong",
    countryNameLocal: "香港, Hong Kong",
    countryCode: "HK",
    currencyCode: "HKD",
    currencyNameEn: "Hong Kong dollar",
    currencySymbol: "$",
    officialLanguageCode: "zh-hant",
    officialLanguageNameEn: "",
    officialLanguageNameLocal: "",
    countryCallingCode: "852",
    region: "Asia & Pacific",
    cities: [
      { name: "Hong Kong", slug: "hk-hong-kong", pop: 7000000 },
      { name: "Kowloon", slug: "hk-kowloon", pop: 2000000 },
      { name: "Tsuen Wan", slug: "hk-tsuen-wan", pop: 290000 },
      { name: "Sha Tin", slug: "hk-sha-tin", pop: 650000 },
      { name: "Tuen Mun", slug: "hk-tuen-mun", pop: 500000 },
      { name: "Sai Kung", slug: "hk-sai-kung", pop: 490000 },
      { name: "Tseung Kwan O", slug: "hk-tseung-kwan-o", pop: 396000 },
      { name: "Tai Po", slug: "hk-tai-po", pop: 300000 },
      { name: "Yuen Long Kau Hui", slug: "hk-yuen-long-kau-hui", pop: 150000 },
      { name: "Tung Chung", slug: "hk-tung-chung", pop: 116000 },
      { name: "Yung Shue Wan", slug: "hk-yung-shue-wan" },
      { name: "Sok Kwu Wan", slug: "hk-sok-kwu-wan" },
      { name: "Tai O", slug: "hk-tai-o" },
      { name: "Chek Lap Kok", slug: "hk-chek-lap-kok" },
      { name: "Peng Chau", slug: "hk-peng-chau" },
      { name: "Lamma", slug: "hk-lamma" },
      { name: "Cheung Chau", slug: "hk-cheung-chau" },
      { name: "Mui Wo", slug: "hk-mui-wo" },
      { name: "Discovery Bay", slug: "hk-discovery-bay" },
      { name: "Lantau", slug: "hk-lantau" },
      { name: "Pui O", slug: "hk-pui-o" },
      { name: "Tai Long Wan", slug: "hk-tai-long-wan" },
      { name: "Shek O", slug: "hk-shek-o" },
      { name: "Repulse Bay", slug: "hk-repulse-bay" },
    ],
    translations: {
      "kr": "홍콩",
      "pt-BR": "Hong Kong",
      "pt": "Hong Kong",
      "nl": "Hongkong",
      "hr": "Hong Kong",
      "fa": "هنگ‌کنگ",
      "de": "Hong Kong",
      "es": "Hong Kong",
      "fr": "Hong Kong",
      "ja": "香港",
      "it": "Hong Kong",
      "cn": "中国香港",
      "tr": "Hong Kong"
    },
    enabled: true,
    flag: "🇭🇰",
    locale: "en-HK"
  },
  {
    countryNameEn: "Honduras",
    countryNameLocal: "Honduras",
    countryCode: "HN",
    currencyCode: "HNL",
    currencyNameEn: "Honduran lempira",
    currencySymbol: "L",
    tinType: "RTN",
    tinName: "Registro Tributario Nacional",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "504",
    region: "South/Latin America",
    flag: "🇭🇳",
    locale: "es-HN"
  },
  {
    countryNameEn: "Croatia",
    countryNameLocal: "Hrvatska",
    countryCode: "HR",
    currencyCode: "HRK",
    currencyNameEn: "Croatian kuna",
    currencySymbol: "€",
    tinType: "PDV-ID; OIB",
    tinName: "PDV Id. Broj OIB",
    officialLanguageCode: "hr",
    officialLanguageNameEn: "Croatian",
    officialLanguageNameLocal: "hrvatski jezik",
    countryCallingCode: "385",
    region: "Europe",
    cities: [
      { name: "Zagreb", slug: "hr-zag-zagreb", pop: 760000 },
      { name: "Split", slug: "hr-spl-split", pop: 160000 },
      { name: "Rijeka", slug: "hr-rij-rijeka", pop: 107000 },
      { name: "Osijek", slug: "hr-os-osijek", pop: 96000 },
      { name: "Zadar", slug: "hr-zad-zadar", pop: 70000 },
      { name: "Velika Gorica", slug: "hr-vg-velika-gorica", pop: 61000 },
      { name: "Pula", slug: "hr-pu-pula", pop: 52000 },
      { name: "Slavonski Brod", slug: "hr-sb-slavonski-brod", pop: 49000 },
      { name: "Karlovac", slug: "hr-ka-karlovac", pop: 49000 },
      { name: "Varaždin", slug: "hr-va-varazdin", pop: 43000 },
      { name: "Sisak", slug: "hr-sk-sisak" },
      { name: "Šibenik", slug: "hr-si-sibenik" },
      { name: "Dubrovnik", slug: "hr-du-dubrovnik" },
      { name: "Bjelovar", slug: "hr-bj-bjelovar" },
      { name: "Kaštela", slug: "hr-ka-kastela" },
      { name: "Samobor", slug: "hr-sa-samobor" },
      { name: "Vinkovci", slug: "hr-vi-vinkovci" },
      { name: "Koprivnica", slug: "hr-ko-koprivnica" },
      { name: "Đakovo", slug: "hr-dj-djakovo" },
      { name: "Požega", slug: "hr-po-pozega" },
      { name: "Zaprešić", slug: "hr-za-zapresic" },
      { name: "Sesvete", slug: "hr-se-sesvete" },
      { name: "Cakovec", slug: "hr-ck-cakovec" },
      { name: "Vukovar", slug: "hr-vu-vukovar" },
    ],
    translations: {
      "kr": "크로아티아",
      "pt-BR": "Croácia",
      "pt": "Croácia",
      "nl": "Kroatië",
      "hr": "Hrvatska",
      "fa": "کرواسی",
      "de": "Kroatien",
      "es": "Croacia",
      "fr": "Croatie",
      "ja": "クロアチア",
      "it": "Croazia",
      "cn": "克罗地亚",
      "tr": "Hirvatistan"
    },
    enabled: true,
    flag: "🇭🇷",
    locale: "hr-HR"
  },
  {
    countryNameEn: "Haiti",
    countryNameLocal: "Haïti, Ayiti",
    countryCode: "HT",
    currencyCode: "HTG",
    currencyNameEn: "Haitian gourde",
    currencySymbol: "G",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "509",
    region: "South/Latin America",
    flag: "🇭🇹",
    locale: "fr-HT"
  },
  {
    countryNameEn: "Hungary",
    countryNameLocal: "Magyarország",
    countryCode: "HU",
    currencyCode: "HUF",
    currencyNameEn: "Hungarian forint",
    currencySymbol: "Ft",
    tinType: "ANUM",
    tinName: "Közösségi adószám",
    officialLanguageCode: "hu",
    officialLanguageNameEn: "Hungarian",
    officialLanguageNameLocal: "magyar",
    countryCallingCode: "36",
    region: "Europe",
    cities: [
      { name: "Budapest", slug: "hu-budapest", pop: 1680000 },
      { name: "Debrecen", slug: "hu-debrecen", pop: 199000 },
      { name: "Szeged", slug: "hu-szeged", pop: 160000 },
      { name: "Miskolc", slug: "hu-miskolc", pop: 147000 },
      { name: "Pécs", slug: "hu-pecs", pop: 139000 },
      { name: "Győr", slug: "hu-gyor", pop: 127000 },
      { name: "Nyíregyháza", slug: "hu-nyiregyhaza", pop: 116000 },
      { name: "Kecskemét", slug: "hu-kecskemet", pop: 112000 },
      { name: "Székesfehérvár", slug: "hu-szekesfehervar", pop: 95000 },
      { name: "Szombathely", slug: "hu-szombathely", pop: 78000 },
      { name: "Érd", slug: "hu-erd" },
      { name: "Tatabánya", slug: "hu-tatabanya" },
      { name: "Veszprém", slug: "hu-veszprem" },
      { name: "Békéscsaba", slug: "hu-bekescsaba" },
      { name: "Zalaegerszeg", slug: "hu-zalaegerszeg" },
      { name: "Sopron", slug: "hu-sopron" },
      { name: "Eger", slug: "hu-eger" },
      { name: "Nagykanizsa", slug: "hu-nagykanizsa" },
      { name: "Dunaújváros", slug: "hu-dunaujvaros" },
      { name: "Hódmezővásárhely", slug: "hu-hodmezovasarhely" },
      { name: "Salgótarján", slug: "hu-salgotarjan" },
      { name: "Cegléd", slug: "hu-cegled" },
      { name: "Orosháza", slug: "hu-oroshaza" },
    ],
    translations: {
      "kr": "헝가리",
      "pt-BR": "Hungria",
      "pt": "Hungria",
      "nl": "Hongarije",
      "hr": "Mađarska",
      "fa": "مجارستان",
      "de": "Ungarn",
      "es": "Hungría",
      "fr": "Hongrie",
      "ja": "ハンガリー",
      "it": "Ungheria",
      "cn": "匈牙利",
      "tr": "Macaristan"
    },
    enabled: true,
    flag: "🇭🇺",
    locale: "hu-HU"
  },
  {
    countryNameEn: "Indonesia",
    countryNameLocal: "Indonesia",
    countryCode: "ID",
    currencyCode: "IDR",
    currencyNameEn: "Indonesian rupiah",
    currencySymbol: "Rp",
    tinType: "NPWP",
    tinName: "Nomor Pokok Wajib Pajak",
    officialLanguageCode: "id",
    officialLanguageNameEn: "Indonesian",
    officialLanguageNameLocal: "Bahasa Indonesia",
    countryCallingCode: "62",
    region: "Asia & Pacific",
    cities: [
      { name: "Bali", slug: "id-bali", pop: 392000 },
    ],
    translations: {
      "kr": "인도네시아",
      "pt-BR": "Indonésia",
      "pt": "Indonésia",
      "nl": "Indonesië",
      "hr": "Indonezija",
      "fa": "اندونزی",
      "de": "Indonesien",
      "es": "Indonesia",
      "fr": "Indonésie",
      "ja": "インドネシア",
      "it": "Indonesia",
      "cn": "印度尼西亚",
      "tr": "Endonezya"
    },
    vpn: true,
    flag: "🇮🇩",
    locale: "id-ID",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Ireland",
    countryNameLocal: "Ireland, Éire",
    countryCode: "IE",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "VAT or CBL",
    tinName: "Value added tax identification no.",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "353",
    region: "Europe",
    cities: [
      { name: "Dublin", slug: "ie-leinster-dublin", pop: 600000 },
      { name: "Cork", slug: "ie-munster-cork", pop: 222000 },
      { name: "Limerick", slug: "ie-munster-limerick", pop: 102000 },
      { name: "Galway", slug: "ie-connacht-galway", pop: 86000 },
      { name: "Waterford", slug: "ie-munster-waterford", pop: 60000 },
      { name: "Dundalk", slug: "ie-leinster-dundalk", pop: 43000 },
      { name: "Swords", slug: "ie-leinster-swords", pop: 40000 },
      { name: "Navan", slug: "ie-leinster-navan", pop: 34000 },
      { name: "Bray", slug: "ie-leinster-bray", pop: 33000 },
      { name: "Ennis", slug: "ie-munster-ennis", pop: 28000 },
      { name: "Balbriggan", slug: "ie-leinster-balbriggan" },
      { name: "Tralee", slug: "ie-munster-tralee" },
      { name: "Sallins", slug: "ie-leinster-sallins" },
      { name: "Greystones", slug: "ie-leinster-greystones" },
      { name: "Malahide", slug: "ie-leinster-malahide" },
      { name: "Leixlip", slug: "ie-leinster-leixlip" },
      { name: "Portmarnock", slug: "ie-leinster-portmarnock" },
      { name: "Maynooth", slug: "ie-leinster-maynooth" },
      { name: "Celbridge", slug: "ie-leinster-celbridge" },
      { name: "Kildare", slug: "ie-leinster-kildare" },
      { name: "Wicklow", slug: "ie-leinster-wicklow" },
      { name: "Arklow", slug: "ie-leinster-arklow" },
      { name: "Newbridge", slug: "ie-leinster-newbridge" },
    ],
    translations: {
      "kr": "아일랜드",
      "pt-BR": "Irlanda",
      "pt": "Irlanda",
      "nl": "Ierland",
      "hr": "Irska",
      "fa": "ایرلند",
      "de": "Irland",
      "es": "Irlanda",
      "fr": "Irlande",
      "ja": "アイルランド",
      "it": "Irlanda",
      "cn": "爱尔兰",
      "tr": "İrlanda"
    },
    enabled: true,
    flag: "🇮🇪",
    locale: "en-IE"
  },
  {
    countryNameEn: "Israel",
    countryNameLocal: "ישראל",
    countryCode: "IL",
    currencyCode: "ILS",
    currencyNameEn: "Israeli new shekel",
    currencySymbol: "₪",
    tinName: "מס' עוסק מורשה / ח\"פ",
    officialLanguageCode: "he",
    officialLanguageNameEn: "Hebrew",
    officialLanguageNameLocal: "עברית",
    countryCallingCode: "972",
    region: "Europe",
    flag: "🇮🇱",
    locale: "ar-IL"
  },
  {
    countryNameEn: "Isle of Man",
    countryNameLocal: "Isle of Man",
    countryCode: "IM",
    currencyCode: "GBP",
    currencyNameEn: "Pound sterling",
    currencySymbol: "£",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "44",
    region: "Europe",
    flag: "🇮🇲",
    locale: "en-IM"
  },
  {
    countryNameEn: "India",
    countryNameLocal: "भारत, India",
    countryCode: "IN",
    currencyCode: "INR",
    currencyNameEn: "Indian rupee",
    currencySymbol: "₹",
    tinType: "VAT TIN / CST TIN",
    tinName: "Value Added Tax - Taxpayer Identification Number / Central Sales Tax - Taxpayer Identification Number (In most states)Not applicable",
    officialLanguageCode: "hi",
    officialLanguageNameEn: "Hindi",
    officialLanguageNameLocal: "हिन्दी, हिंदी",
    countryCallingCode: "91",
    region: "Asia & Pacific",
    cities: [
      { name: "Mumbai", slug: "in-mh-mumbai", pop: 12400000 },
      { name: "Delhi", slug: "in-dl-delhi", pop: 11000000 },
      { name: "Bangalore", slug: "in-ka-bangalore", pop: 8440000 },
      { name: "Hyderabad", slug: "in-tg-hyderabad", pop: 6990000 },
      { name: "Ahmedabad", slug: "in-gj-ahmedabad", pop: 5570000 },
      { name: "Chennai", slug: "in-tn-chennai", pop: 4650000 },
      { name: "Kolkata", slug: "in-wb-kolkata", pop: 4490000 },
      { name: "Surat", slug: "in-gj-surat", pop: 4460000 },
      { name: "Pune", slug: "in-mh-pune", pop: 3120000 },
      { name: "Jaipur", slug: "in-rj-jaipur", pop: 3050000 },
      { name: "Lucknow", slug: "in-up-lucknow", pop: 2800000 },
      { name: "Kanpur", slug: "in-up-kanpur" },
      { name: "Nagpur", slug: "in-mh-nagpur" },
      { name: "Visakhapatnam", slug: "in-ap-visakhapatnam" },
      { name: "Thane", slug: "in-mh-thane" },
      { name: "Bhopal", slug: "in-mp-bhopal" },
      { name: "Patna", slug: "in-br-patna" },
      { name: "Vadodara", slug: "in-gj-vadodara" },
      { name: "Ghaziabad", slug: "in-up-ghaziabad" },
      { name: "Ludhiana", slug: "in-pb-ludhiana" },
      { name: "Agra", slug: "in-up-agra" },
      { name: "Nashik", slug: "in-mh-nashik" },
      { name: "Faridabad", slug: "in-hr-faridabad" },
      { name: "Meerut", slug: "in-up-meerut" },
    ],
    translations: {
      "kr": "인도",
      "pt-BR": "Índia",
      "pt": "Índia",
      "nl": "India",
      "hr": "Indija",
      "fa": "هند",
      "de": "Indien",
      "es": "India",
      "fr": "Inde",
      "ja": "インド",
      "it": "India",
      "cn": "印度",
      "tr": "Hindistan"
    },
    enabled: true,
    flag: "🇮🇳",
    locale: "en-IN"
  },
  {
    countryNameEn: "British Indian Ocean Territories",
    countryNameLocal: "British Indian Ocean Territories",
    countryCode: "IO",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "246",
    region: "Indian Ocean",
    flag: "🇮🇴",
    locale: "en-IO"
  },
  {
    countryNameEn: "Iraq",
    countryNameLocal: "العراق, Iraq",
    countryCode: "IQ",
    currencyCode: "IQD",
    currencyNameEn: "Iraqi dinar",
    currencySymbol: "ع.د",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "964",
    region: "Arab States",
    flag: "🇮🇶",
    blocked: true,
    locale: "ar-IQ",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Iran (Islamic Republic of)",
    countryNameLocal: "ایران",
    countryCode: "IR",
    currencyCode: "IRR",
    currencyNameEn: "Iranian rial",
    currencySymbol: "﷼",
    officialLanguageCode: "fa",
    officialLanguageNameEn: "Persian",
    officialLanguageNameLocal: "فارسی",
    countryCallingCode: "98",
    region: "Asia & Pacific",
    flag: "🇮🇷",
    blocked: true,
    locale: "fa-IR"
  },
  {
    countryNameEn: "Iceland",
    countryNameLocal: "Ísland",
    countryCode: "IS",
    currencyCode: "ISK",
    currencyNameEn: "Icelandic króna",
    currencySymbol: "kr",
    tinType: "VSK / VASK",
    tinName: "Virðisaukaskattsnúmer",
    officialLanguageCode: "is",
    officialLanguageNameEn: "Icelandic",
    officialLanguageNameLocal: "Íslenska",
    countryCallingCode: "354",
    region: "Europe",
    cities: [
      { name: "Reykjavík", slug: "is-reykjavik", pop: 119000 },
      { name: "Kópavogur", slug: "is-kopavogur", pop: 32000 },
      { name: "Hafnarfjörður", slug: "is-hafnarfjordur", pop: 26000 },
      { name: "Akureyri", slug: "is-akureyri", pop: 18000 },
      { name: "Garðabær", slug: "is-gardabaer", pop: 10000 },
      { name: "Mosfellsbær", slug: "is-mosfellsbaer", pop: 9000 },
      { name: "Keflavík", slug: "is-keflavik", pop: 8000 },
      { name: "Akranes", slug: "is-akranes", pop: 7000 },
      { name: "Selfoss", slug: "is-selfoss", pop: 7000 },
      { name: "Seltjarnarnes", slug: "is-seltjarnarnes", pop: 4000 },
      { name: "Vestmannaeyjar", slug: "is-vestmannaeyjar" },
      { name: "Grindavík", slug: "is-grindavik" },
      { name: "Húsavík", slug: "is-husavik" },
      { name: "Egilsstaðir", slug: "is-egilsstadir" },
      { name: "Hveragerði", slug: "is-hveragerdi" },
      { name: "Ólafsvík", slug: "is-olafsvik" },
      { name: "Sauðárkrókur", slug: "is-saudarkrokur" },
      { name: "Borgarnes", slug: "is-borgarnes" },
      { name: "Ísafjörður", slug: "is-isafjordur" },
      { name: "Blönduós", slug: "is-blonduos" },
      { name: "Seyðisfjörður", slug: "is-seydisfjordur" },
      { name: "Stykkishólmur", slug: "is-stykkisholmur" },
      { name: "Dalvík", slug: "is-dalvik" },
    ],
    translations: {
      "kr": "아이슬란드",
      "pt-BR": "Islândia",
      "pt": "Islândia",
      "nl": "IJsland",
      "hr": "Island",
      "fa": "ایسلند",
      "de": "Island",
      "es": "Islandia",
      "fr": "Islande",
      "ja": "アイスランド",
      "it": "Islanda",
      "cn": "冰岛",
      "tr": "İzlanda"
    },
    enabled: true,
    flag: "🇮🇸",
    locale: "is-IS"
  },
  {
    countryNameEn: "Italy",
    countryNameLocal: "Italia",
    countryCode: "IT",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "P.IVA",
    tinName: "Partita IVA(IVA = Imposta sul Valore Aggiunto)",
    officialLanguageCode: "it",
    officialLanguageNameEn: "Italian",
    officialLanguageNameLocal: "Italiano",
    countryCallingCode: "39",
    region: "Europe",
    cities: [
      { name: "Rome", slug: "it-lazio-rome", pop: 2760000 },
      { name: "Milan", slug: "it-lombardy-milan", pop: 1370000 },
      { name: "Naples", slug: "it-campania-naples", pop: 914000 },
      { name: "Turin", slug: "it-piedmont-turin", pop: 848000 },
      { name: "Palermo", slug: "it-sicily-palermo", pop: 630000 },
      { name: "Genoa", slug: "it-liguria-genoa", pop: 560000 },
      { name: "Bologna", slug: "it-emilia-romagna-bologna", pop: 390000 },
      { name: "Florence", slug: "it-tuscany-florence", pop: 367000 },
      { name: "Bari", slug: "it-apulia-bari", pop: 316000 },
      { name: "Catania", slug: "it-sicily-catania", pop: 298000 },
      { name: "Venice", slug: "it-veneto-venice", pop: 254000 },
      { name: "Verona", slug: "it-veneto-verona" },
      { name: "Messina", slug: "it-sicily-messina" },
      { name: "Padua", slug: "it-veneto-padua" },
      { name: "Trieste", slug: "it-friuli-venezia-giulia-trieste" },
      { name: "Taranto", slug: "it-apulia-taranto" },
      { name: "Brescia", slug: "it-lombardy-brescia" },
      { name: "Reggio Calabria", slug: "it-calabria-reggio-calabria" },
      { name: "Modena", slug: "it-emilia-romagna-modena" },
      { name: "Prato", slug: "it-tuscany-prato" },
      { name: "Parma", slug: "it-emilia-romagna-parma" },
      { name: "Cagliari", slug: "it-sardinia-cagliari" },
      { name: "Livorno", slug: "it-tuscany-livorno" },
    ],
    translations: {
      "kr": "이탈리아",
      "pt-BR": "Itália",
      "pt": "Itália",
      "nl": "Italië",
      "hr": "Italija",
      "fa": "ایتالیا",
      "de": "Italien",
      "es": "Italia",
      "fr": "Italie",
      "ja": "イタリア",
      "it": "Italia",
      "cn": "意大利",
      "tr": "İtalya"
    },
    enabled: true,
    flag: "🇮🇹",
    locale: "it-IT"
  },
  {
    countryNameEn: "Jersey",
    countryNameLocal: "Jersey",
    countryCode: "JE",
    currencyCode: "GBP",
    currencyNameEn: "Pound sterling",
    currencySymbol: "£",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "44",
    region: "Europe",
    flag: "🇯🇪",
    locale: "en-JE"
  },
  {
    countryNameEn: "Jamaica",
    countryNameLocal: "Jamaica",
    countryCode: "JM",
    currencyCode: "JMD",
    currencyNameEn: "Jamaican dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "876",
    region: "South/Latin America",
    flag: "🇯🇲",
    locale: "en-JM"
  },
  {
    countryNameEn: "Jordan",
    countryNameLocal: "الأُرْدُن",
    countryCode: "JO",
    currencyCode: "JOD",
    currencyNameEn: "Jordanian dinar",
    currencySymbol: "د.ا",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "962",
    region: "Arab States",
    flag: "🇯🇴",
    blocked: true,
    locale: "ar-JO"
  },
  {
    countryNameEn: "Japan",
    countryNameLocal: "日本",
    countryCode: "JP",
    currencyCode: "JPY",
    currencyNameEn: "Japanese yen",
    currencySymbol: "¥",
    officialLanguageCode: "ja",
    officialLanguageNameEn: "Japanese",
    officialLanguageNameLocal: "日本語 (にほんご)",
    countryCallingCode: "81",
    region: "Asia & Pacific",
    cities: [
      { name: "Tokyo", slug: "jp-tokyo", pop: 13950000 },
      { name: "Yokohama", slug: "jp-yokohama", pop: 3730000 },
      { name: "Osaka", slug: "jp-osaka", pop: 2690000 },
      { name: "Nagoya", slug: "jp-nagoya", pop: 2330000 },
      { name: "Sapporo", slug: "jp-sapporo", pop: 1970000 },
      { name: "Fukuoka", slug: "jp-fukuoka", pop: 1580000 },
      { name: "Kawasaki", slug: "jp-kawasaki", pop: 1530000 },
      { name: "Kobe", slug: "jp-kobe", pop: 1520000 },
      { name: "Kyoto", slug: "jp-kyoto", pop: 1460000 },
      { name: "Hiroshima", slug: "jp-hiroshima", pop: 1190000 },
      { name: "Saitama", slug: "jp-saitama" },
      { name: "Sendai", slug: "jp-sendai" },
      { name: "Chiba", slug: "jp-chiba" },
      { name: "Kitakyushu", slug: "jp-kitakyushu" },
      { name: "Sakai", slug: "jp-sakai" },
      { name: "Niigata", slug: "jp-niigata" },
      { name: "Hamamatsu", slug: "jp-hamamatsu" },
      { name: "Shizuoka", slug: "jp-shizuoka" },
      { name: "Sagamihara", slug: "jp-sagamihara" },
      { name: "Okayama", slug: "jp-okayama" },
      { name: "Kumamoto", slug: "jp-kumamoto" },
      { name: "Kagoshima", slug: "jp-kagoshima" },
      { name: "Okinawa", slug: "jp-okinawa" },
    ],
    translations: {
      "kr": "일본",
      "pt-BR": "Japão",
      "pt": "Japão",
      "nl": "Japan",
      "hr": "Japan",
      "fa": "ژاپن",
      "de": "Japan",
      "es": "Japón",
      "fr": "Japon",
      "ja": "日本",
      "it": "Giappone",
      "cn": "日本",
      "tr": "Japonya"
    },
    enabled: true,
    flag: "🇯🇵",
    locale: "ja-JP"
  },
  {
    countryNameEn: "Kenya",
    countryNameLocal: "Kenya",
    countryCode: "KE",
    currencyCode: "KES",
    currencyNameEn: "Kenyan shilling",
    currencySymbol: "Sh",
    officialLanguageCode: "sw",
    officialLanguageNameEn: "Swahili",
    officialLanguageNameLocal: "Kiswahili",
    countryCallingCode: "254",
    region: "Africa",
    flag: "🇰🇪",
    locale: "en-KE"
  },
  {
    countryNameEn: "Kyrgyzstan",
    countryNameLocal: "Кыргызстан, Киргизия",
    countryCode: "KG",
    currencyCode: "KGS",
    currencyNameEn: "Kyrgyzstani som",
    currencySymbol: "с",
    officialLanguageCode: "ky",
    officialLanguageNameEn: "Kirghiz, Kyrgyz",
    officialLanguageNameLocal: "Кыргызча, Кыргыз тили",
    countryCallingCode: "996",
    region: "Asia & Pacific",
    flag: "🇰🇬",
    locale: "ky-KG"
  },
  {
    countryNameEn: "Cambodia",
    countryNameLocal: "កម្ពុជា",
    countryCode: "KH",
    currencyCode: "KHR",
    currencyNameEn: "Cambodian riel",
    currencySymbol: "៛",
    officialLanguageCode: "km",
    officialLanguageNameEn: "Central Khmer",
    officialLanguageNameLocal: "ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ",
    countryCallingCode: "855",
    region: "Asia & Pacific",
    flag: "🇰🇭",
    locale: "km-KH"
  },
  {
    countryNameEn: "North Korea",
    countryNameLocal: "조선민주주의인민공화국",
    countryCode: "KP",
    currencyCode: "KPW",
    currencyNameEn: "North Korean won",
    currencySymbol: "₩",
    officialLanguageCode: "ko",
    officialLanguageNameEn: "Korean",
    officialLanguageNameLocal: "조선어",
    countryCallingCode: "850",
    region: "Asia",
    flag: "🇰🇵",
    blocked: true,
    locale: "ko-KP"
  },
  {
    countryNameEn: "South Korea",
    countryNameLocal: "대한민국",
    countryCode: "KR",
    currencyCode: "KRW",
    currencyNameEn: "South Korean won",
    currencySymbol: "₩",
    officialLanguageCode: "ko",
    officialLanguageNameEn: "Korean",
    officialLanguageNameLocal: "한국어",
    countryCallingCode: "82",
    region: "Asia",
    cities: [
      { name: "Seoul", slug: "kr-seoul", pop: 9930000 },
      { name: "Busan", slug: "kr-busan", pop: 3490000 },
      { name: "Incheon", slug: "kr-incheon", pop: 2940000 },
      { name: "Daegu", slug: "kr-daegu", pop: 2480000 },
      { name: "Daejeon", slug: "kr-daejeon", pop: 1510000 },
      { name: "Gwangju", slug: "kr-gwangju", pop: 1460000 },
      { name: "Suwon", slug: "kr-suwon", pop: 1190000 },
      { name: "Ulsan", slug: "kr-ulsan", pop: 1110000 },
      { name: "Changwon", slug: "kr-changwon", pop: 1060000 },
      { name: "Goyang", slug: "kr-goyang", pop: 1040000 },
      { name: "Seongnam", slug: "kr-seongnam" },
      { name: "Yongin", slug: "kr-yongin" },
      { name: "Cheongju", slug: "kr-cheongju" },
      { name: "Jeonju", slug: "kr-jeonju" },
      { name: "Cheonan", slug: "kr-cheonan" },
      { name: "Anyang", slug: "kr-anyang" },
      { name: "Pohang", slug: "kr-pohang" },
      { name: "Ansan", slug: "kr-ansan" },
      { name: "Wonju", slug: "kr-wonju" },
      { name: "Gimhae", slug: "kr-gimhae" },
      { name: "Yangju", slug: "kr-yangju" },
      { name: "Uijeongbu", slug: "kr-uijeongbu" },
      { name: "Gumi", slug: "kr-gumi" },
      { name: "Iksan", slug: "kr-iksan" },
    ],
    translations: {
      "kr": "대한민국",
      "pt-BR": "Coreia do Sul",
      "pt": "Coreia do Sul",
      "nl": "Zuid-Korea",
      "hr": "Južna Koreja",
      "fa": "کره شمالی",
      "de": "Südkorea",
      "es": "Corea del Sur",
      "fr": "Corée du Sud",
      "ja": "大韓民国",
      "it": "Corea del Sud",
      "cn": "韩国",
      "tr": "Güney Kore"
    },
    enabled: true,
    flag: "🇰🇷",
    locale: "ko-KR"
  },
  {
    countryNameEn: "Kiribati",
    countryNameLocal: "Kiribati",
    countryCode: "KI",
    currencyCode: "AUD",
    currencyNameEn: "Australian dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "686",
    region: "Asia & Pacific",
    flag: "🇰🇮",
    locale: "en-KI"
  },
  {
    countryNameEn: "Saint Kitts and Nevis",
    countryNameLocal: "Saint Kitts and Nevis",
    countryCode: "KN",
    currencyCode: "XCD",
    currencyNameEn: "East Caribbean dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1869",
    region: "South/Latin America",
    flag: "🇰🇳",
    locale: "en-KN"
  },
  {
    countryNameEn: "Kuwait",
    countryNameLocal: "الكويت",
    countryCode: "KW",
    currencyCode: "KWD",
    currencyNameEn: "Kuwaiti dinar",
    currencySymbol: "د.ك",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "965",
    region: "Arab States",
    flag: "🇰🇼",
    blocked: true,
    locale: "ar-KW"
  },
  {
    countryNameEn: "Kazakhstan",
    countryNameLocal: "Қазақстан, Казахстан",
    countryCode: "KZ",
    currencyCode: "KZT",
    currencyNameEn: "Kazakhstani tenge",
    currencySymbol: "₸",
    officialLanguageCode: "kk",
    officialLanguageNameEn: "Kazakh",
    officialLanguageNameLocal: "қазақ тілі",
    countryCallingCode: "7",
    region: "Asia & Pacific",
    flag: "🇰🇿",
    blocked: true,
    locale: "kk-KZ"
  },
  {
    countryNameEn: "Lebanon",
    countryNameLocal: "لبنان, Liban",
    countryCode: "LB",
    currencyCode: "LBP",
    currencyNameEn: "Lebanese pound",
    currencySymbol: "ل.ل",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "961",
    region: "Arab States",
    flag: "🇱🇧",
    blocked: true,
    vpn: true,
    locale: "ar-LB"
  },
  {
    countryNameEn: "Saint Lucia",
    countryNameLocal: "Saint Lucia",
    countryCode: "LC",
    currencyCode: "XCD",
    currencyNameEn: "East Caribbean dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1758",
    region: "South/Latin America",
    flag: "🇱🇨",
    locale: "en-LC"
  },
  {
    countryNameEn: "Liechtenstein",
    countryNameLocal: "Liechtenstein",
    countryCode: "LI",
    currencyCode: "CHF",
    currencyNameEn: "Swiss franc",
    currencySymbol: "Fr",
    officialLanguageCode: "de",
    officialLanguageNameEn: "German",
    officialLanguageNameLocal: "Deutsch",
    countryCallingCode: "423",
    region: "Europe",
    flag: "🇱🇮",
    locale: "de-LI"
  },
  {
    countryNameEn: "Sri Lanka",
    countryNameLocal: "ශ්‍රී ලංකා, இலங்கை",
    countryCode: "LK",
    currencyCode: "LKR",
    currencyNameEn: "Sri Lankan rupee",
    currencySymbol: "Rs  රු",
    officialLanguageCode: "si",
    officialLanguageNameEn: "Sinhala, Sinhalese",
    officialLanguageNameLocal: "සිංහල",
    countryCallingCode: "94",
    region: "Asia & Pacific",
    flag: "🇱🇰",
    locale: "si-LK"
  },
  {
    countryNameEn: "Liberia",
    countryNameLocal: "Liberia",
    countryCode: "LR",
    currencyCode: "LRD",
    currencyNameEn: "Liberian dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "231",
    region: "Africa",
    flag: "🇱🇷",
    locale: "en-LR"
  },
  {
    countryNameEn: "Lesotho",
    countryNameLocal: "Lesotho",
    countryCode: "LS",
    currencyCode: "LSL",
    currencyNameEn: "Lesotho loti",
    currencySymbol: "L",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "266",
    region: "Africa",
    flag: "🇱🇸",
    locale: "en-LS",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Lithuania",
    countryNameLocal: "Lietuva",
    countryCode: "LT",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "PVM kodas",
    tinName: "PVM (abbrev. Pridėtinės vertės mokestis) mokėtojo kodas",
    officialLanguageCode: "lt",
    officialLanguageNameEn: "Lithuanian",
    officialLanguageNameLocal: "lietuvių kalba",
    countryCallingCode: "370",
    region: "Europe",
    cities: [
      { name: "Vilnius", slug: "lt-vilnius", pop: 602000 },
      { name: "Kaunas", slug: "lt-kaunas", pop: 304000 },
      { name: "Klaipėda", slug: "lt-klaipeda", pop: 159000 },
      { name: "Šiauliai", slug: "lt-siauliai", pop: 110000 },
      { name: "Panevėžys", slug: "lt-panevezys", pop: 86000 },
      { name: "Alytus", slug: "lt-alytus", pop: 51000 },
      { name: "Marijampolė", slug: "lt-marijampole", pop: 36000 },
      { name: "Mažeikiai", slug: "lt-mazeikiai", pop: 33000 },
      { name: "Utena", slug: "lt-utena", pop: 28000 },
      { name: "Jonava", slug: "lt-jonava", pop: 27000 },
      { name: "Kėdainiai", slug: "lt-kedainiai" },
      { name: "Telšiai", slug: "lt-telsiai" },
      { name: "Visaginas", slug: "lt-visaginas" },
      { name: "Tauragė", slug: "lt-taurage" },
      { name: "Ukmergė", slug: "lt-ukmerge" },
      { name: "Plungė", slug: "lt-plunge" },
      { name: "Kretinga", slug: "lt-kretinga" },
      { name: "Šilutė", slug: "lt-silute" },
      { name: "Radviliškis", slug: "lt-radviliskis" },
      { name: "Palanga", slug: "lt-palanga" },
      { name: "Gargždai", slug: "lt-gargzdai" },
      { name: "Druskininkai", slug: "lt-druskininkai" },
      { name: "Rokiškis", slug: "lt-rokiskis" },
      { name: "Biržai", slug: "lt-birzai" },
    ],
    translations: {
      "kr": "리투아니아",
      "pt-BR": "Lituânia",
      "pt": "Lituânia",
      "nl": "Litouwen",
      "hr": "Litva",
      "fa": "لیتوانی",
      "de": "Litauen",
      "es": "Lituania",
      "fr": "Lituanie",
      "ja": "リトアニア",
      "it": "Lituania",
      "cn": "立陶宛",
      "tr": "Litvanya"
    },
    enabled: true,
    flag: "🇱🇹",
    locale: "lt-LT"
  },
  {
    countryNameEn: "Luxembourg",
    countryNameLocal: "Lëtzebuerg",
    countryCode: "LU",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "No. TVA",
    tinName: "Numéro d'identification à la taxe sur la valeur ajoutée",
    officialLanguageCode: "lb",
    officialLanguageNameEn: "Luxembourgish, Letzeburgesch",
    officialLanguageNameLocal: "Lëtzebuergesch",
    countryCallingCode: "352",
    region: "Europe",
    cities: [
      { name: "Luxembourg", slug: "lu-luxembourg", pop: 115000 },
      { name: "Esch-sur-Alzette", slug: "lu-esch-sur-alzette", pop: 34000 },
      { name: "Differdange", slug: "lu-differdange", pop: 24000 },
      { name: "Dudelange", slug: "lu-dudelange", pop: 20000 },
      { name: "Ettelbruck", slug: "lu-ettelbruck", pop: 8000 },
      { name: "Diekirch", slug: "lu-diekirch", pop: 7000 },
      { name: "Wiltz", slug: "lu-wiltz", pop: 6000 },
      { name: "Rumelange", slug: "lu-rumelange", pop: 5000 },
      { name: "Echternach", slug: "lu-echternach", pop: 5000 },
      { name: "Grevenmacher", slug: "lu-grevenmacher", pop: 4000 },
      { name: "Remich", slug: "lu-remich" },
      { name: "Vianden", slug: "lu-vianden" },
      { name: "Diekirch", slug: "lu-diekirch" },
      { name: "Redange", slug: "lu-redange" },
      { name: "Schifflange", slug: "lu-schifflange" },
      { name: "Mersch", slug: "lu-mersch" },
      { name: "Sanem", slug: "lu-sanem" },
      { name: "Capellen", slug: "lu-capellen" },
      { name: "Clervaux", slug: "lu-clervaux" },
      { name: "Dippach", slug: "lu-dippach" },
      { name: "Beaufort", slug: "lu-beaufort" },
      { name: "Schengen", slug: "lu-schengen" },
      { name: "Junglinster", slug: "lu-junglinster" },
      { name: "Mondorf-les-Bains", slug: "lu-mondorf-les-bains" },
    ],
    translations: {
      "kr": "룩셈부르크",
      "pt-BR": "Luxemburgo",
      "pt": "Luxemburgo",
      "nl": "Luxemburg",
      "hr": "Luksemburg",
      "fa": "لوکزامبورگ",
      "de": "Luxemburg",
      "es": "Luxemburgo",
      "fr": "Luxembourg",
      "ja": "ルクセンブルク",
      "it": "Lussemburgo",
      "cn": "卢森堡",
      "tr": "Lüksemburg"
    },
    enabled: true,
    flag: "🇱🇺",
    locale: "de-LU"
  },
  {
    countryNameEn: "Latvia",
    countryNameLocal: "Latvija",
    countryCode: "LV",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "PVN",
    tinName: "Pievienotās vērtības nodokļa (PVN) reģistrācijas numurs",
    officialLanguageCode: "lv",
    officialLanguageNameEn: "Latvian",
    officialLanguageNameLocal: "latviešu valoda",
    countryCallingCode: "371",
    region: "Europe",
    cities: [
      { name: "Riga", slug: "lv-riga", pop: 605000 },
      { name: "Daugavpils", slug: "lv-daugavpils", pop: 77000 },
      { name: "Liepāja", slug: "lv-liepaja", pop: 66000 },
      { name: "Jelgava", slug: "lv-jelgava", pop: 55000 },
      { name: "Jūrmala", slug: "lv-jurmala", pop: 52000 },
      { name: "Ventspils", slug: "lv-ventspils", pop: 32000 },
      { name: "Rēzekne", slug: "lv-rezekne", pop: 26000 },
      { name: "Ogre", slug: "lv-ogre", pop: 22000 },
      { name: "Valmiera", slug: "lv-valmiera", pop: 22000 },
      { name: "Jēkabpils", slug: "lv-jekabpils", pop: 21000 },
      { name: "Tukums", slug: "lv-tukums" },
      { name: "Cēsis", slug: "lv-cesis" },
      { name: "Salaspils", slug: "lv-salaspils" },
      { name: "Kuldīga", slug: "lv-kuldiga" },
      { name: "Olaine", slug: "lv-olaine" },
      { name: "Saldus", slug: "lv-saldus" },
      { name: "Talsi", slug: "lv-talsi" },
      { name: "Dobele", slug: "lv-dobele" },
      { name: "Krāslava", slug: "lv-kraslava" },
      { name: "Bauska", slug: "lv-bauska" },
      { name: "Gulbene", slug: "lv-gulbene" },
      { name: "Ludza", slug: "lv-ludza" },
      { name: "Sigulda", slug: "lv-sigulda" },
      { name: "Aizkraukle", slug: "lv-aizkraukle" },
      { name: "Balvi", slug: "lv-balvi" },
    ],
    translations: {
      "kr": "라트비아",
      "pt-BR": "Letônia",
      "pt": "Letónia",
      "nl": "Letland",
      "hr": "Latvija",
      "fa": "لتونی",
      "de": "Lettland",
      "es": "Letonia",
      "fr": "Lettonie",
      "ja": "ラトビア",
      "it": "Lettonia",
      "cn": "拉脱维亚",
      "tr": "Letonya"
    },
    enabled: true,
    flag: "🇱🇻",
    locale: "lv-LV"
  },
  {
    countryNameEn: "Libya",
    countryNameLocal: "ليبيا",
    countryCode: "LY",
    currencyCode: "LYD",
    currencyNameEn: "Libyan dinar",
    currencySymbol: "ل.د",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "218",
    region: "Arab States",
    flag: "🇱🇾",
    blocked: true,
    locale: "ar-LY",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Morocco",
    countryNameLocal: "Maroc, ⵍⵎⵖⵔⵉⴱ, المغرب",
    countryCode: "MA",
    currencyCode: "MAD",
    currencyNameEn: "Moroccan dirham",
    currencySymbol: "د.م.",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "212",
    region: "Arab States",
    flag: "🇲🇦",
    blocked: true,
    locale: "ar-MA",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Monaco",
    countryNameLocal: "Monaco",
    countryCode: "MC",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "377",
    region: "Europe",
    flag: "🇲🇨",
    locale: "fr-MC"
  },
  {
    countryNameEn: "Montenegro",
    countryNameLocal: "Crna Gora, Црна Гора",
    countryCode: "ME",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    officialLanguageCode: "srp",
    officialLanguageNameEn: "српски језик",
    officialLanguageNameLocal: "",
    countryCallingCode: "382",
    region: "Europe",
    flag: "🇲🇪",
    locale: "bs-ME"
  },
  {
    countryNameEn: "Saint Martin (French part)",
    countryNameLocal: "Saint-Martin",
    countryCode: "MF",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "590",
    region: "South/Latin America",
    flag: "🇲🇫",
    locale: "en-MF"
  },
  {
    countryNameEn: "Madagascar",
    countryNameLocal: "Madagasikara, Madagascar",
    countryCode: "MG",
    currencyCode: "MGA",
    currencyNameEn: "Malagasy ariary",
    currencySymbol: "Ar",
    officialLanguageCode: "mg",
    officialLanguageNameEn: "Malagasy",
    officialLanguageNameLocal: "fiteny malagasy",
    countryCallingCode: "261",
    region: "Africa",
    flag: "🇲🇬",
    locale: "fr-MG"
  },
  {
    countryNameEn: "Mali",
    countryNameLocal: "Mali",
    countryCode: "ML",
    currencyCode: "XOF",
    currencyNameEn: "CFA franc BCEAO",
    currencySymbol: "Fr",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "223",
    region: "Africa",
    flag: "🇲🇱",
    locale: "fr-ML"
  },
  {
    countryNameEn: "Myanmar",
    countryNameLocal: "မြန်မာ",
    countryCode: "MM",
    currencyCode: "MMK",
    currencyNameEn: "Myanmar kyat",
    currencySymbol: "Ks",
    officialLanguageCode: "my",
    officialLanguageNameEn: "Burmese",
    officialLanguageNameLocal: "ဗမာစာ",
    countryCallingCode: "95",
    region: "Asia & Pacific",
    flag: "🇲🇲",
    locale: "my-MM",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Mongolia",
    countryNameLocal: "Монгол Улс",
    countryCode: "MN",
    currencyCode: "MNT",
    currencyNameEn: "Mongolian tögrög",
    currencySymbol: "₮",
    officialLanguageCode: "mn",
    officialLanguageNameEn: "Mongolian",
    officialLanguageNameLocal: "Монгол хэл",
    countryCallingCode: "976",
    region: "Asia & Pacific",
    flag: "🇲🇳",
    locale: "mn-MN"
  },
  {
    countryNameEn: "Macao",
    countryNameLocal: "澳門, Macau",
    countryCode: "MO",
    currencyCode: "MOP",
    currencyNameEn: "Macanese pataca",
    currencySymbol: "P",
    officialLanguageCode: "zh-hant",
    officialLanguageNameEn: "",
    officialLanguageNameLocal: "",
    countryCallingCode: "853",
    region: "Asia & Pacific",
    flag: "🇲🇴",
    locale: "pt-MO"
  },
  {
    countryNameEn: "Martinique",
    countryNameLocal: "Martinique",
    countryCode: "MQ",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "596",
    region: "South/Latin America",
    flag: "🇲🇶",
    locale: "fr-MQ"
  },
  {
    countryNameEn: "Mauritania",
    countryNameLocal: "موريتانيا, Mauritanie",
    countryCode: "MR",
    currencyCode: "MRU",
    currencyNameEn: "",
    currencySymbol: "",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "222",
    region: "Arab States",
    flag: "🇲🇷",
    blocked: true,
    locale: "ar-MR"
  },
  {
    countryNameEn: "Montserrat",
    countryNameLocal: "Montserrat",
    countryCode: "MS",
    currencyCode: "XCD",
    currencyNameEn: "East Caribbean dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1664",
    region: "South/Latin America",
    flag: "🇲🇸",
    locale: "en-MS"
  },
  {
    countryNameEn: "Malta",
    countryNameLocal: "Malta",
    countryCode: "MT",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "Vat No.",
    tinName: "Vat reg. no.",
    officialLanguageCode: "mt",
    officialLanguageNameEn: "Maltese",
    officialLanguageNameLocal: "Malti",
    countryCallingCode: "356",
    region: "Europe",
    flag: "🇲🇹",
    locale: "en-MT"
  },
  {
    countryNameEn: "Mauritius",
    countryNameLocal: "Maurice, Mauritius",
    countryCode: "MU",
    currencyCode: "MUR",
    currencyNameEn: "Mauritian rupee",
    currencySymbol: "₨",
    officialLanguageCode: "mfe",
    officialLanguageNameEn: "",
    officialLanguageNameLocal: "",
    countryCallingCode: "230",
    region: "Africa",
    flag: "🇲🇺",
    locale: "en-MU"
  },
  {
    countryNameEn: "Maldives",
    countryNameLocal: "",
    countryCode: "MV",
    currencyCode: "MVR",
    currencyNameEn: "Maldivian rufiyaa",
    currencySymbol: ".ރ",
    officialLanguageCode: "dv",
    officialLanguageNameEn: "Divehi, Dhivehi, Maldivian",
    officialLanguageNameLocal: "ދިވެހި",
    countryCallingCode: "960",
    region: "Asia & Pacific",
    flag: "🇲🇻",
    locale: "dv-MV"
  },
  {
    countryNameEn: "Malawi",
    countryNameLocal: "Malawi",
    countryCode: "MW",
    currencyCode: "MWK",
    currencyNameEn: "Malawian kwacha",
    currencySymbol: "MK",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "265",
    region: "Africa",
    flag: "🇲🇼",
    locale: "en-MW"
  },
  {
    countryNameEn: "Mexico",
    countryNameLocal: "México",
    countryCode: "MX",
    currencyCode: "MXN",
    currencyNameEn: "Mexican peso",
    currencySymbol: "$",
    tinType: "RFC",
    tinName: "Registro Federal de Contribuyentes",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "52",
    region: "South/Latin America",
    cities: [
      { name: "Mexico City", slug: "mx-mexico-city", pop: 9200000 },
      { name: "Tijuana", slug: "mx-tijuana", pop: 1920000 },
      { name: "León", slug: "mx-leon", pop: 1720000 },
      { name: "Puebla", slug: "mx-puebla", pop: 1690000 },
      { name: "Ecatepec", slug: "mx-ecatepec", pop: 1650000 },
      { name: "Ciudad Juárez", slug: "mx-ciudad-juarez", pop: 1500000 },
      { name: "Zapopan", slug: "mx-zapopan", pop: 1470000 },
      { name: "Guadalajara", slug: "mx-guadalajara", pop: 1380000 },
      { name: "Monterrey", slug: "mx-monterrey", pop: 1140000 },
      { name: "Nezahualcóyotl", slug: "mx-nezahualcoyotl", pop: 1070000 },
      { name: "Toluca", slug: "mx-toluca" },
      { name: "Acapulco", slug: "mx-acapulco" },
      { name: "Cancún", slug: "mx-cancun" },
      { name: "Chihuahua", slug: "mx-chihuahua" },
      { name: "Cuernavaca", slug: "mx-cuernavaca" },
      { name: "Culiacán", slug: "mx-culiacan" },
      { name: "Durango", slug: "mx-durango" },
      { name: "Hermosillo", slug: "mx-hermosillo" },
      { name: "Matamoros", slug: "mx-matamoros" },
      { name: "Mérida", slug: "mx-merida" },
      { name: "Morelia", slug: "mx-morelia" },
      { name: "Oaxaca", slug: "mx-oaxaca" },
      { name: "Pachuca", slug: "mx-pachuca" },
      { name: "Puebla", slug: "mx-puebla" },
      { name: "Querétaro", slug: "mx-queretaro" },
      { name: "Saltillo", slug: "mx-saltillo" },
      { name: "San Luis Potosí", slug: "mx-san-luis-potosi" },
      { name: "Tampico", slug: "mx-tampico" },
      { name: "Tuxtla Gutiérrez", slug: "mx-tuxtla-gutierrez" },
    ],
    translations: {
      "kr": "멕시코",
      "pt-BR": "México",
      "pt": "México",
      "nl": "Mexico",
      "hr": "Meksiko",
      "fa": "مکزیک",
      "de": "Mexiko",
      "es": "México",
      "fr": "Mexique",
      "ja": "メキシコ",
      "it": "Messico",
      "cn": "墨西哥",
      "tr": "Meksika"
    },
    enabled: true,
    flag: "🇲🇽",
    locale: "es-MX"
  },
  {
    countryNameEn: "Malaysia",
    countryNameLocal: "",
    countryCode: "MY",
    currencyCode: "MYR",
    currencyNameEn: "Malaysian ringgit",
    currencySymbol: "RM",
    officialLanguageCode: "ms",
    officialLanguageNameEn: "Malay",
    officialLanguageNameLocal: "Bahasa Melayu, بهاس ملايو‎",
    countryCallingCode: "60",
    region: "Asia & Pacific",
    cities: [
      { name: "Kuala Lumpur", slug: "my-kuala-lumpur", pop: 1980000 },
      { name: "Kajang", slug: "my-kajang", pop: 1050000 },
      { name: "Seberang Perai", slug: "my-seberang-perai", pop: 946000 },
      { name: "Johor Bahru", slug: "my-johor-bahru", pop: 858000 },
      { name: "Shah Alam", slug: "my-shah-alam", pop: 812000 },
      { name: "George Town", slug: "my-george-town", pop: 794000 },
      { name: "Ipoh", slug: "my-ipoh", pop: 759000 },
      { name: "Kuantan", slug: "my-kuantan", pop: 548000 },
      { name: "Kota Kinabalu", slug: "my-kota-kinabalu", pop: 500000 },
      { name: "Selangor", slug: "my-selangor", pop: 4180000 },
      { name: "Kuching", slug: "my-kuching", pop: 402000 },
    ],
    translations: {
      "kr": "말레이시아",
      "pt-BR": "Malásia",
      "pt": "Malásia",
      "nl": "Maleisië",
      "hr": "Malezija",
      "fa": "مالزی",
      "de": "Malaysia",
      "es": "Malasia",
      "fr": "Malaisie",
      "ja": "マレーシア",
      "it": "Malesia",
      "cn": "马来西亚",
      "tr": "Malezya"
    },
    vpn: true,
    flag: "🇲🇾",
    locale: "ms-MY"
  },
  {
    countryNameEn: "Mozambique",
    countryNameLocal: "Mozambique",
    countryCode: "MZ",
    currencyCode: "MZN",
    currencyNameEn: "Mozambican metical",
    currencySymbol: "MT",
    officialLanguageCode: "pt",
    officialLanguageNameEn: "Portuguese",
    officialLanguageNameLocal: "Português",
    countryCallingCode: "258",
    region: "Africa",
    flag: "🇲🇿",
    locale: "pt-MZ"
  },
  {
    countryNameEn: "Namibia",
    countryNameLocal: "Namibia",
    countryCode: "NA",
    currencyCode: "NAD",
    currencyNameEn: "Namibian dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "264",
    region: "Africa",
    flag: "🇳🇦",
    locale: "af-NA"
  },
  {
    countryNameEn: "New Caledonia",
    countryNameLocal: "Nouvelle-Calédonie",
    countryCode: "NC",
    currencyCode: "XPF",
    currencyNameEn: "CFP franc (franc Pacifique)",
    currencySymbol: "₣",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "687",
    region: "Asia & Pacific",
    flag: "🇳🇨",
    locale: "fr-NC"
  },
  {
    countryNameEn: "Norfolk Island",
    countryNameLocal: "Norfolk Island",
    countryCode: "NF",
    currencyCode: "AUD",
    currencyNameEn: "Australian dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "672",
    region: "Asia & Pacific",
    flag: "🇳🇫",
    locale: "en-NF"
  },
  {
    countryNameEn: "Nigeria",
    countryNameLocal: "Nigeria",
    countryCode: "NG",
    currencyCode: "NGN",
    currencyNameEn: "Nigerian naira",
    currencySymbol: "₦",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "234",
    region: "Africa",
    flag: "🇳🇬",
    blocked: true,
    locale: "en-NG"
  },
  {
    countryNameEn: "Nicaragua",
    countryNameLocal: "Nicaragua",
    countryCode: "NI",
    currencyCode: "NIO",
    currencyNameEn: "Nicaraguan córdoba",
    currencySymbol: "C$",
    tinType: "RUC",
    tinName: "Registro Unico de Contribuyentes",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "505",
    region: "South/Latin America",
    flag: "🇳🇮",
    locale: "es-NI"
  },
  {
    countryNameEn: "Norway",
    countryNameLocal: "Norge, Noreg",
    countryCode: "NO",
    currencyCode: "NOK",
    currencyNameEn: "Norwegian krone",
    currencySymbol: "kr",
    tinType: "Orgnr",
    tinName: "Organisasjonsnummer",
    officialLanguageCode: "nb",
    officialLanguageNameEn: "Norwegian Bokmål",
    officialLanguageNameLocal: "Norsk Bokmål",
    countryCallingCode: "47",
    region: "Europe",
    cities: [
      { name: "Oslo", slug: "no-oslo", pop: 1080000 },
      { name: "Bergen", slug: "no-bergen", pop: 269000 },
      { name: "Stavanger", slug: "no-stavanger", pop: 234000 },
      { name: "Trondheim", slug: "no-trondheim", pop: 196000 },
      { name: "Drammen", slug: "no-drammen", pop: 122000 },
      { name: "Fredrikstad", slug: "no-fredrikstad", pop: 120000 },
      { name: "Kristiansand", slug: "no-kristiansand", pop: 66000 },
      { name: "Tønsberg", slug: "no-tonsberg", pop: 55000 },
      { name: "Ålesund", slug: "no-alesund", pop: 55000 },
      { name: "Moss", slug: "no-moss", pop: 49000 },
      { name: "Sandnes", slug: "no-sandnes" },
      { name: "Sarpsborg", slug: "no-sarpsborg" },
      { name: "Skien", slug: "no-skien" },
      { name: "Haugesund", slug: "no-haugesund" },
      { name: "Arendal", slug: "no-arendal" },
      { name: "Bodø", slug: "no-bodo" },
      { name: "Hamar", slug: "no-hamar" },
      { name: "Ytrebygda", slug: "no-ytrebygda" },
      { name: "Larvik", slug: "no-larvik" },
      { name: "Halden", slug: "no-halden" },
      { name: "Askøy", slug: "no-askoy" },
      { name: "Kongsberg", slug: "no-kongsberg" },
      { name: "Molde", slug: "no-molde" },
      { name: "Harstad", slug: "no-harstad" },
      { name: "Horten", slug: "no-horten" },
      { name: "Lillehammer", slug: "no-lillehammer" },
    ],
    translations: {
      "kr": "노르웨이",
      "pt-BR": "Noruega",
      "pt": "Noruega",
      "nl": "Noorwegen",
      "hr": "Norveška",
      "fa": "نروژ",
      "de": "Norwegen",
      "es": "Noruega",
      "fr": "Norvège",
      "ja": "ノルウェー",
      "it": "Norvegia",
      "cn": "挪威",
      "tr": "Norveç"
    },
    enabled: true,
    flag: "🇳🇴",
    locale: "nn-NO"
  },
  {
    countryNameEn: "Nepal",
    countryNameLocal: "",
    countryCode: "NP",
    currencyCode: "NPR",
    currencyNameEn: "Nepalese rupee",
    currencySymbol: "₨",
    officialLanguageCode: "ne",
    officialLanguageNameEn: "Nepali",
    officialLanguageNameLocal: "नेपाली",
    countryCallingCode: "977",
    region: "Asia & Pacific",
    flag: "🇳🇵",
    locale: "ne-NP",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Nauru",
    countryNameLocal: "Nauru",
    countryCode: "NR",
    currencyCode: "AUD",
    currencyNameEn: "Australian dollar",
    currencySymbol: "$",
    officialLanguageCode: "na",
    officialLanguageNameEn: "Nauru",
    officialLanguageNameLocal: "Dorerin Naoero",
    countryCallingCode: "674",
    region: "Asia & Pacific",
    flag: "🇳🇷",
    locale: "en-NR"
  },
  {
    countryNameEn: "Niue",
    countryNameLocal: "Niue",
    countryCode: "NU",
    currencyCode: "NZD",
    currencyNameEn: "New Zealand dollar",
    currencySymbol: "$",
    officialLanguageCode: "niu",
    officialLanguageNameEn: "",
    officialLanguageNameLocal: "",
    countryCallingCode: "683",
    region: "Asia & Pacific",
    flag: "🇳🇺",
    locale: "en-NU"
  },
  {
    countryNameEn: "New Zealand",
    countryNameLocal: "New Zealand",
    countryCode: "NZ",
    currencyCode: "NZD",
    currencyNameEn: "New Zealand dollar",
    currencySymbol: "$",
    tinType: "NZBN",
    tinName: "NZ Business Number",
    officialLanguageCode: "mi",
    officialLanguageNameEn: "Maori",
    officialLanguageNameLocal: "te reo Māori",
    countryCallingCode: "64",
    region: "Asia & Pacific",
    cities: [
      { name: "Auckland", slug: "nz-auckland", pop: 1480000 },
      { name: "Christchurch", slug: "nz-christchurch", pop: 384000 },
      { name: "Wellington", slug: "nz-wellington", pop: 215000 },
      { name: "Hamilton", slug: "nz-hamilton", pop: 185000 },
      { name: "Tauranga", slug: "nz-tauranga", pop: 161000 },
      { name: "Lower Hutt", slug: "nz-lower-hutt", pop: 113000 },
      { name: "Dunedin", slug: "nz-dunedin", pop: 106000 },
      { name: "Palmerston North", slug: "nz-palmerston-north", pop: 82000 },
      { name: "Napier", slug: "nz-napier", pop: 67000 },
      { name: "Hibiscus Coast", slug: "nz-hibiscus-coast", pop: 63000 },
      { name: "Hastings", slug: "nz-hastings" },
      { name: "Nelson", slug: "nz-nelson" },
      { name: "Rotorua", slug: "nz-rotorua" },
      { name: "Whangarei", slug: "nz-whangarei" },
      { name: "New Plymouth", slug: "nz-new-plymouth" },
      { name: "Invercargill", slug: "nz-invercargill" },
      { name: "Wanganui", slug: "nz-wanganui" },
      { name: "Gisborne", slug: "nz-gisborne" },
      { name: "Masterton", slug: "nz-masterton" },
      { name: "Levin", slug: "nz-levin" },
      { name: "Tokoroa", slug: "nz-tokoroa" },
      { name: "Ashburton", slug: "nz-ashburton" },
      { name: "Whakatane", slug: "nz-whakatane" },
      { name: "Blenheim", slug: "nz-blenheim" },
      { name: "Richmond", slug: "nz-richmond" },
      { name: "Timaru", slug: "nz-timaru" },
      { name: "Taupo", slug: "nz-taupo" },
      { name: "Pukekohe East", slug: "nz-pukekohe-east" },
    ],
    translations: {
      "kr": "뉴질랜드",
      "pt-BR": "Nova Zelândia",
      "pt": "Nova Zelândia",
      "nl": "Nieuw-Zeeland",
      "hr": "Novi Zeland",
      "fa": "نیوزیلند",
      "de": "Neuseeland",
      "es": "Nueva Zelanda",
      "fr": "Nouvelle-Zélande",
      "ja": "ニュージーランド",
      "it": "Nuova Zelanda",
      "cn": "新西兰",
      "tr": "Yeni Zelanda"
    },
    enabled: true,
    flag: "🇳🇿",
    locale: "en-NZ"
  },
  {
    countryNameEn: "Oman",
    countryNameLocal: "سلطنة عُمان",
    countryCode: "OM",
    currencyCode: "OMR",
    currencyNameEn: "Omani rial",
    currencySymbol: "ر.ع.",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "968",
    region: "Arab States",
    flag: "🇴🇲",
    blocked: true,
    locale: "ar-OM"
  },
  {
    countryNameEn: "Panama",
    countryNameLocal: "Panama",
    countryCode: "PA",
    currencyCode: "PAB",
    currencyNameEn: "Panamanian balboa",
    currencySymbol: "B/.",
    tinType: "RUC",
    tinName: "Registro Unico de Contribuyentes",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "507",
    region: "South/Latin America",
    flag: "🇵🇦",
    locale: "es-PA"
  },
  {
    countryNameEn: "Peru",
    countryNameLocal: "Perú",
    countryCode: "PE",
    currencyCode: "PEN",
    currencyNameEn: "Peruvian sol",
    currencySymbol: "S/ ",
    tinType: "RUC",
    tinName: "Registro Unico de Contribuyentes",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "51",
    region: "South/Latin America",
    flag: "🇵🇪",
    locale: "es-PE"
  },
  {
    countryNameEn: "French Polynesia",
    countryNameLocal: "Polynésie française",
    countryCode: "PF",
    currencyCode: "XPF",
    currencyNameEn: "CFP franc (franc Pacifique)",
    currencySymbol: "₣",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "689",
    region: "Asia & Pacific",
    flag: "🇵🇫",
    locale: "fr-PF"
  },
  {
    countryNameEn: "Papua New Guinea",
    countryNameLocal: "Papua New Guinea",
    countryCode: "PG",
    currencyCode: "PGK",
    currencyNameEn: "Papua New Guinean kina",
    currencySymbol: "K",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "675",
    region: "Asia & Pacific",
    flag: "🇵🇬",
    locale: "en-PG"
  },
  {
    countryNameEn: "Pakistan",
    countryNameLocal: "پاکستان",
    countryCode: "PK",
    currencyCode: "PKR",
    currencyNameEn: "Pakistani rupee",
    currencySymbol: "₨",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "92",
    region: "Asia & Pacific",
    flag: "🇵🇰",
    blocked: true,
    vpn: true,
    locale: "en-PK"
  },
  {
    countryNameEn: "Poland",
    countryNameLocal: "Polska",
    countryCode: "PL",
    currencyCode: "PLN",
    currencyNameEn: "Polish złoty",
    currencySymbol: "zł",
    tinType: "NIP",
    tinName: "numer identyfikacji podatkowej",
    officialLanguageCode: "pl",
    officialLanguageNameEn: "Polish",
    officialLanguageNameLocal: "język polski, polszczyzna",
    countryCallingCode: "48",
    region: "Europe",
    cities: [
      { name: "Warsaw", slug: "pl-warsaw", pop: 2060000 },
      { name: "Kraków", slug: "pl-krakow", pop: 802000 },
      { name: "Łódź", slug: "pl-lodz", pop: 664000 },
      { name: "Wrocław", slug: "pl-wroclaw", pop: 642000 },
      { name: "Poznań", slug: "pl-poznan", pop: 530000 },
      { name: "Gdańsk", slug: "pl-gdansk", pop: 470000 },
      { name: "Szczecin", slug: "pl-szczecin", pop: 395000 },
      { name: "Bydgoszcz", slug: "pl-bydgoszcz", pop: 339000 },
      { name: "Lublin", slug: "pl-lublin", pop: 336000 },
      { name: "Katowice", slug: "pl-katowice", pop: 290000 },
      { name: "Białystok", slug: "pl-bialystok" },
      { name: "Gdynia", slug: "pl-gdynia" },
      { name: "Częstochowa", slug: "pl-czestochowa" },
      { name: "Radom", slug: "pl-radom" },
      { name: "Sosnowiec", slug: "pl-sosnowiec" },
      { name: "Toruń", slug: "pl-torun" },
      { name: "Kielce", slug: "pl-kielce" },
      { name: "Rzeszów", slug: "pl-rzeszow" },
      { name: "Gliwice", slug: "pl-gliwice" },
      { name: "Zabrze", slug: "pl-zabrze" },
      { name: "Olsztyn", slug: "pl-olsztyn" },
      { name: "Bielsko-Biała", slug: "pl-bielsko-biala" },
      { name: "Bytom", slug: "pl-bytom" },
      { name: "Zielona Góra", slug: "pl-zielona-gora" },
      { name: "Rybnik", slug: "pl-rybnik" },
      { name: "Ruda Śląska", slug: "pl-ruda-slaska" },
    ],
    translations: {
      "kr": "폴란드",
      "pt-BR": "Polônia",
      "pt": "Polónia",
      "nl": "Polen",
      "hr": "Poljska",
      "fa": "لهستان",
      "de": "Polen",
      "es": "Polonia",
      "fr": "Pologne",
      "ja": "ポーランド",
      "it": "Polonia",
      "cn": "波兰",
      "tr": "Polonya"
    },
    enabled: true,
    flag: "🇵🇱",
    locale: "pl-PL"
  },
  {
    countryNameEn: "Saint Pierre and Miquelon",
    countryNameLocal: "Saint-Pierre-et-Miquelon",
    countryCode: "PM",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "508",
    region: "North America",
    flag: "🇵🇲",
    locale: "fr-PM"
  },
  {
    countryNameEn: "Pitcairn",
    countryNameLocal: "Pitcairn",
    countryCode: "PN",
    currencyCode: "NZD",
    currencyNameEn: "New Zealand dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "64",
    region: "Asia & Pacific",
    flag: "🇵🇳",
    locale: "en-PN"
  },
  {
    countryNameEn: "Puerto Rico",
    countryNameLocal: "Puerto Rico",
    countryCode: "PR",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    currencySymbol: "$",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "1",
    region: "South/Latin America",
    flag: "🇵🇷",
    locale: "en-PR"
  },
  {
    countryNameEn: "Palestine, State of",
    countryNameLocal: "Palestinian Territory",
    countryCode: "PS",
    currencyCode: "",
    currencyNameEn: "",
    currencySymbol: "₪",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "970",
    region: "Arab States",
    flag: "🇵🇸",
    blocked: true,
    locale: "ar-PS"
  },
  {
    countryNameEn: "Portugal",
    countryNameLocal: "Portugal",
    countryCode: "PT",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "NIPC",
    tinName: "Número de Identificação de Pessoa Coletiva (NIPC)",
    officialLanguageCode: "pt",
    officialLanguageNameEn: "Portuguese",
    officialLanguageNameLocal: "Português",
    countryCallingCode: "351",
    region: "Europe",
    cities: [
      { name: "Lisbon", slug: "pt-lisbon", pop: 545000 },
      { name: "Porto", slug: "pt-porto", pop: 231000 },
      { name: "Vila Nova de Gaia", slug: "pt-vila-nova-de-gaia", pop: 188000 },
      { name: "Amadora", slug: "pt-amadora", pop: 171000 },
      { name: "Braga", slug: "pt-braga", pop: 146000 },
      { name: "Setúbal", slug: "pt-setubal", pop: 98000 },
      { name: "Coimbra", slug: "pt-coimbra", pop: 106000 },
      { name: "Funchal", slug: "pt-funchal", pop: 105000 },
      { name: "Almada", slug: "pt-almada", pop: 85000 },
      { name: "Agualva-Cacém", slug: "pt-agualva-cacem", pop: 81000 },
      { name: "Aveiro", slug: "pt-aveiro" },
      { name: "Viseu", slug: "pt-viseu" },
      { name: "Leiria", slug: "pt-leiria" },
      { name: "Évora", slug: "pt-evora" },
      { name: "Faro", slug: "pt-faro" },
      { name: "Guarda", slug: "pt-guarda" },
      { name: "Beja", slug: "pt-beja" },
      { name: "Bragança", slug: "pt-braganca" },
      { name: "Castelo Branco", slug: "pt-castelo-branco" },
      { name: "Portalegre", slug: "pt-portalegre" },
      { name: "Santarém", slug: "pt-santarem" },
      { name: "Viana do Castelo", slug: "pt-viana-do-castelo" },
      { name: "Vila Real", slug: "pt-vila-real" },
    ],
    translations: {
      "kr": "포르투갈",
      "pt-BR": "Portugal",
      "pt": "Portugal",
      "nl": "Portugal",
      "hr": "Portugal",
      "fa": "پرتغال",
      "de": "Portugal",
      "es": "Portugal",
      "fr": "Portugal",
      "ja": "ポルトガル",
      "it": "Portogallo",
      "cn": "葡萄牙",
      "tr": "Portekiz"
    },
    enabled: true,
    flag: "🇵🇹",
    locale: "pt-PT"
  },
  {
    countryNameEn: "Palau",
    countryNameLocal: "Palau",
    countryCode: "PW",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "680",
    region: "Asia & Pacific",
    flag: "🇵🇼",
    locale: "en-PW"
  },
  {
    countryNameEn: "Paraguay",
    countryNameLocal: "Paraguay",
    countryCode: "PY",
    currencyCode: "PYG",
    currencyNameEn: "Paraguayan guaraní",
    currencySymbol: "₲",
    tinType: "RUC",
    tinName: "Registro Unico de Contribuyentes",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "595",
    region: "South/Latin America",
    flag: "🇵🇾",
    locale: "gn-PY"
  },
  {
    countryNameEn: "Qatar",
    countryNameLocal: "قطر",
    countryCode: "QA",
    currencyCode: "QAR",
    currencyNameEn: "Qatari riyal",
    currencySymbol: "ر.ق",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "974",
    region: "Arab States",
    flag: "🇶🇦",
    blocked: true,
    vpn: true,
    locale: "ar-QA",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Réunion",
    countryNameLocal: "La Réunion",
    countryCode: "RE",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "262",
    region: "Asia & Pacific",
    flag: "🇷🇪",
    locale: "fr-RE"
  },
  {
    countryNameEn: "Romania",
    countryNameLocal: "România",
    countryCode: "RO",
    currencyCode: "RON",
    currencyNameEn: "Romanian leu",
    currencySymbol: "lei",
    tinType: "CIF",
    tinName: "Codul de identificare fiscală",
    officialLanguageCode: "ro",
    officialLanguageNameEn: "Romanian, Moldavian, Moldovan",
    officialLanguageNameLocal: "Română",
    countryCallingCode: "40",
    region: "Europe",
    cities: [
      { name: "Bucharest", slug: "ro-bucharest", pop: 1710000 },
      { name: "Cluj-Napoca", slug: "ro-cluj-napoca", pop: 286000 },
      { name: "Iași", slug: "ro-iasi", pop: 271000 },
      { name: "Constanța", slug: "ro-constanta", pop: 263000 },
      { name: "Timișoara", slug: "ro-timisoara", pop: 250000 },
      { name: "Brașov", slug: "ro-brasov", pop: 237000 },
      { name: "Craiova", slug: "ro-craiova", pop: 235000 },
      { name: "Galați", slug: "ro-galati", pop: 217000 },
      { name: "Oradea", slug: "ro-oradea", pop: 183000 },
      { name: "Ploiești", slug: "ro-ploiesti", pop: 180000 },
      { name: "Brăila", slug: "ro-braila" },
      { name: "Arad", slug: "ro-arad" },
      { name: "Pitești", slug: "ro-pitesti" },
      { name: "Sibiu", slug: "ro-sibiu" },
      { name: "Bacău", slug: "ro-bacau" },
      { name: "Târgu Mureș", slug: "ro-targu-mures" },
      { name: "Baia Mare", slug: "ro-baia-mare" },
      { name: "Buzău", slug: "ro-buzau" },
      { name: "Botoșani", slug: "ro-botosani" },
      { name: "Satu Mare", slug: "ro-satu-mare" },
      { name: "Râmnicu Vâlcea", slug: "ro-ramnicu-valcea" },
      { name: "Drobeta-Turnu Severin", slug: "ro-drobeta-turnu-severin" },
      { name: "Suceava", slug: "ro-suceava" },
      { name: "Piatra Neamț", slug: "ro-piatra-neamt" },
      { name: "Târgu Jiu", slug: "ro-targu-jiu" },
    ],
    translations: {
      "kr": "루마니아",
      "pt-BR": "Romênia",
      "pt": "Roménia",
      "nl": "Roemenië",
      "hr": "Rumunjska",
      "fa": "رومانی",
      "de": "Rumänien",
      "es": "Rumania",
      "fr": "Roumanie",
      "ja": "ルーマニア",
      "it": "Romania",
      "cn": "罗马尼亚",
      "tr": "Romanya"
    },
    enabled: true,
    flag: "🇷🇴",
    locale: "ro-RO"
  },
  {
    countryNameEn: "Serbia",
    countryNameLocal: "Србија",
    countryCode: "RS",
    currencyCode: "RSD",
    currencyNameEn: "Serbian dinar",
    currencySymbol: "дин.",
    tinType: "PIB",
    tinName: "Poreski identifikacioni broj",
    officialLanguageCode: "sr",
    officialLanguageNameEn: "Serbian",
    officialLanguageNameLocal: "српски језик",
    countryCallingCode: "381",
    region: "Europe",
    flag: "🇷🇸",
    locale: "sr-RS"
  },
  {
    countryNameEn: "Russia",
    countryNameLocal: "Россия",
    countryCode: "RU",
    currencyCode: "RUB",
    currencyNameEn: "Russian ruble",
    currencySymbol: "₽",
    tinType: "ИНН",
    tinName: "Идентификационный номер налогоплательщика",
    officialLanguageCode: "ru",
    officialLanguageNameEn: "Russian",
    officialLanguageNameLocal: "русский",
    countryCallingCode: "7",
    region: "Europe",
    cities: [
      { name: "Moscow", slug: "ru-moscow", pop: 13000000 },
      { name: "Saint Petersburg", slug: "ru-saint-petersburg", pop: 5600000 },
      { name: "Novosibirsk", slug: "ru-novosibirsk", pop: 1630000 },
      { name: "Yekaterinburg", slug: "ru-yekaterinburg", pop: 1540000 },
      { name: "Nizhny Novgorod", slug: "ru-nizhny-novgorod", pop: 1220000 },
      { name: "Chelyabinsk", slug: "ru-chelyabinsk", pop: 1190000 },
      { name: "Krasnoyarsk", slug: "ru-krasnoyarsk", pop: 1180000 },
      { name: "Samara", slug: "ru-samara", pop: 1170000 },
      { name: "Ufa", slug: "ru-ufa", pop: 1140000 },
      { name: "Rostov-on-Don", slug: "ru-rostov-on-don" },
      { name: "Kazan", slug: "ru-kazan" },
      { name: "Omsk", slug: "ru-omsk" },
      { name: "Volgograd", slug: "ru-volgograd" },
      { name: "Perm", slug: "ru-perm" },
      { name: "Voronezh", slug: "ru-voronezh" },
      { name: "Saratov", slug: "ru-saratov" },
      { name: "Krasnodar", slug: "ru-krasnodar" },
      { name: "Tolyatti", slug: "ru-tolyatti" },
      { name: "Izhevsk", slug: "ru-izhevsk" },
      { name: "Barnaul", slug: "ru-barnaul" },
      { name: "Ulyanovsk", slug: "ru-ulyanovsk" },
      { name: "Irkutsk", slug: "ru-irkutsk" },
      { name: "Yaroslavl", slug: "ru-yaroslavl" },
      { name: "Tyumen", slug: "ru-tyumen" },
      { name: "Khabarovsk", slug: "ru-khabarovsk" },
      { name: "Vladivostok", slug: "ru-vladivostok" },
    ],
    translations: {
      "kr": "러시아",
      "pt-BR": "Rússia",
      "pt": "Rússia",
      "nl": "Rusland",
      "hr": "Rusija",
      "fa": "روسیه",
      "de": "Russland",
      "es": "Rusia",
      "fr": "Russie",
      "ja": "ロシア連邦",
      "it": "Russia",
      "cn": "俄罗斯联邦",
      "tr": "Rusya"
    },
    enabled: true,
    flag: "🇷🇺",
    locale: "ru-RU"
  },
  {
    countryNameEn: "Rwanda",
    countryNameLocal: "Rwanda",
    countryCode: "RW",
    currencyCode: "RWF",
    currencyNameEn: "Rwandan franc",
    currencySymbol: "Fr",
    officialLanguageCode: "rw",
    officialLanguageNameEn: "Kinyarwanda",
    officialLanguageNameLocal: "Ikinyarwanda",
    countryCallingCode: "250",
    region: "Africa",
    flag: "🇷🇼",
    locale: "en-RW"
  },
  {
    countryNameEn: "Saudi Arabia",
    countryNameLocal: "السعودية",
    countryCode: "SA",
    currencyCode: "SAR",
    currencyNameEn: "Saudi riyal",
    currencySymbol: "ر.س",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "966",
    region: "Arab States",
    flag: "🇸🇦",
    blocked: true,
    locale: "ar-SA"
  },
  {
    countryNameEn: "Solomon Islands",
    countryNameLocal: "Solomon Islands",
    countryCode: "SB",
    currencyCode: "SBD",
    currencyNameEn: "Solomon Islands dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "677",
    region: "Asia & Pacific",
    flag: "🇸🇧",
    locale: "en-SB"
  },
  {
    countryNameEn: "Seychelles",
    countryNameLocal: "Seychelles",
    countryCode: "SC",
    currencyCode: "SCR",
    currencyNameEn: "Seychelles rupee",
    currencySymbol: "₨",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "248",
    region: "Africa",
    flag: "🇸🇨",
    locale: "en-SC"
  },
  {
    countryNameEn: "Sweden",
    countryNameLocal: "Sverige",
    countryCode: "SE",
    currencyCode: "SEK",
    currencyNameEn: "Swedish krona/kronor",
    currencySymbol: "kr",
    tinType: "Momsnr.",
    tinName: "VAT-nummer",
    officialLanguageCode: "sv",
    officialLanguageNameEn: "Swedish",
    officialLanguageNameLocal: "Svenska",
    countryCallingCode: "46",
    region: "Europe",
    cities: [
      { name: "Stockholm", slug: "se-stockholm", pop: 1370000 },
      { name: "Gothenburg", slug: "se-gothenburg", pop: 549000 },
      { name: "Malmö", slug: "se-malmo", pop: 280000 },
      { name: "Uppsala", slug: "se-uppsala", pop: 140000 },
      { name: "Västerås", slug: "se-vasteras", pop: 110000 },
      { name: "Örebro", slug: "se-orebro", pop: 107000 },
      { name: "Linköping", slug: "se-linkoping", pop: 104000 },
      { name: "Helsingborg", slug: "se-helsingborg", pop: 97000 },
      { name: "Jönköping", slug: "se-jonkoping", pop: 89000 },
      { name: "Norrköping", slug: "se-norrkoping", pop: 87000 },
      { name: "Lund", slug: "se-lund" },
      { name: "Umeå", slug: "se-umea" },
      { name: "Gävle", slug: "se-gavle" },
      { name: "Borås", slug: "se-boras" },
      { name: "Södertälje", slug: "se-sodertalje" },
      { name: "Eskilstuna", slug: "se-eskilstuna" },
      { name: "Halmstad", slug: "se-halmstad" },
      { name: "Växjö", slug: "se-vaxjo" },
      { name: "Karlstad", slug: "se-karlstad" },
      { name: "Täby", slug: "se-taby" },
      { name: "Sundsvall", slug: "se-sundsvall" },
      { name: "Luleå", slug: "se-lulea" },
      { name: "Trollhättan", slug: "se-trollhattan" },
      { name: "Östersund", slug: "se-ostersund" },
      { name: "Borlänge", slug: "se-borlange" },
      { name: "Upplands Väsby", slug: "se-upplands-vasby" },
    ],
    translations: {
      "kr": "스웨덴",
      "pt-BR": "Suécia",
      "pt": "Suécia",
      "nl": "Zweden",
      "hr": "Švedska",
      "fa": "سوئد",
      "de": "Schweden",
      "es": "Suecia",
      "fr": "Suède",
      "ja": "スウェーデン",
      "it": "Svezia",
      "cn": "瑞典",
      "tr": "İsveç"
    },
    enabled: true,
    flag: "🇸🇪",
    locale: "sv-SE"
  },
  {
    countryNameEn: "Singapore",
    countryNameLocal: "Singapore",
    countryCode: "SG",
    currencyCode: "SGD",
    currencyNameEn: "Singapore dollar",
    currencySymbol: "$",
    officialLanguageCode: "zh-hans",
    officialLanguageNameEn: "",
    officialLanguageNameLocal: "",
    countryCallingCode: "65",
    region: "Asia & Pacific",
    cities: [
      { name: "Bedok", slug: "sg-bedok", pop: 279000 },
      { name: "Tampines", slug: "sg-tampines", pop: 274000 },
      { name: "Jurong West", slug: "sg-jurong-west", pop: 259000 },
      { name: "Sengkang", slug: "sg-sengkang", pop: 255000 },
      { name: "Woodlands", slug: "sg-woodlands", pop: 257000 },
      { name: "Hougang", slug: "sg-hougang", pop: 227000 },
      { name: "Yishun", slug: "sg-yishun", pop: 228000 },
      { name: "Choa Chu Kang", slug: "sg-choa-chu-kang", pop: 191000 },
      { name: "Punggol", slug: "sg-punggol", pop: 194000 },
      { name: "Bukit Batok", slug: "sg-bukit-batok", pop: 168000 },
      { name: "Ang Mo Kio", slug: "sg-ang-mo-kio" },
      { name: "Bukit Merah", slug: "sg-bukit-merah" },
      { name: "Pasir Ris", slug: "sg-pasir-ris" },
      { name: "Clementi", slug: "sg-clementi" },
      { name: "Serangoon", slug: "sg-serangoon" },
      { name: "Sembawang", slug: "sg-sembawang" },
      { name: "Queenstown", slug: "sg-queenstown" },
      { name: "Bishan", slug: "sg-bishan" },
      { name: "Bukit Panjang", slug: "sg-bukit-panjang" },
      { name: "Marine Parade", slug: "sg-marine-parade" },
      { name: "Geylang", slug: "sg-geylang" },
      { name: "Kallang", slug: "sg-kallang" },
      { name: "Tanglin", slug: "sg-tanglin" },
      { name: "Novena", slug: "sg-novena" },
      { name: "Toa Payoh", slug: "sg-toa-payoh" },
      { name: "Outram", slug: "sg-outram" },
    ],
    translations: {
      "kr": "싱가포르",
      "pt-BR": "Singapura",
      "pt": "Singapura",
      "nl": "Singapore",
      "hr": "Singapur",
      "fa": "سنگاپور",
      "de": "Singapur",
      "es": "Singapur",
      "fr": "Singapour",
      "ja": "シンガポール",
      "it": "Singapore",
      "cn": "新加坡",
      "tr": "Singapur"
    },
    enabled: true,
    flag: "🇸🇬",
    locale: "en-SG"
  },
  {
    countryNameEn: "Saint Helena, Ascension and Tristan da Cunha",
    countryNameLocal: "Saint Helena",
    countryCode: "SH",
    currencyCode: "SHP",
    currencyNameEn: "Saint Helena pound",
    currencySymbol: "£",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "290",
    region: "Africa",
    flag: "🇸🇭",
    locale: "en-SH"
  },
  {
    countryNameEn: "Slovenia",
    countryNameLocal: "Slovenija",
    countryCode: "SI",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "ID za DDV",
    tinName: "Davčna številka",
    officialLanguageCode: "sl",
    officialLanguageNameEn: "Slovenian",
    officialLanguageNameLocal: "Slovenski Jezik, Slovenščina",
    countryCallingCode: "386",
    region: "Europe",
    cities: [
      { name: "Ljubljana", slug: "si-ljubljana", pop: 288000 },
      { name: "Maribor", slug: "si-maribor", pop: 97000 },
      { name: "Kranj", slug: "si-kranj", pop: 38000 },
      { name: "Celje", slug: "si-celje", pop: 37000 },
      { name: "Koper", slug: "si-koper", pop: 26000 },
      { name: "Velenje", slug: "si-velenje", pop: 25000 },
      { name: "Novo Mesto", slug: "si-novo-mesto", pop: 24000 },
      { name: "Ptuj", slug: "si-ptuj", pop: 18000 },
      { name: "Kamnik", slug: "si-kamnik", pop: 13000 },
      { name: "Jesenice", slug: "si-jesenice", pop: 13000 },
      { name: "Trbovlje", slug: "si-trbovlje" },
      { name: "Nova Gorica", slug: "si-nova-gorica" },
      { name: "Murska Sobota", slug: "si-murska-sobota" },
      { name: "Škofja Loka", slug: "si-skofja-loka" },
      { name: "Domžale", slug: "si-domzale" },
      { name: "Izola", slug: "si-izola" },
      { name: "Kočevje", slug: "si-kocevje" },
      { name: "Postojna", slug: "si-postojna" },
      { name: "Logatec", slug: "si-logatec" },
      { name: "Slovenj Gradec", slug: "si-slovenj-gradec" },
      { name: "Ravne na Koroškem", slug: "si-ravne-na-koroskem" },
      { name: "Grosuplje", slug: "si-grosuplje" },
      { name: "Radovljica", slug: "si-radovljica" },
      { name: "Krško", slug: "si-krsko" },
      { name: "Sežana", slug: "si-sezana" },
      { name: "Litija", slug: "si-litija" },
    ],
    translations: {
      "kr": "슬로베니아",
      "pt-BR": "Eslovênia",
      "pt": "Eslovénia",
      "nl": "Slovenië",
      "hr": "Slovenija",
      "fa": "اسلوونی",
      "de": "Slowenien",
      "es": "Eslovenia",
      "fr": "Slovénie",
      "ja": "スロベニア",
      "it": "Slovenia",
      "cn": "斯洛文尼亚",
      "tr": "Slovenya"
    },
    enabled: true,
    flag: "🇸🇮",
    locale: "sl-SI"
  },
  {
    countryNameEn: "Svalbard and Jan Mayen",
    countryNameLocal: "Svalbard and Jan Mayen",
    countryCode: "SJ",
    currencyCode: "NOK",
    currencyNameEn: "Norwegian krone",
    currencySymbol: "kr",
    officialLanguageCode: "no",
    officialLanguageNameEn: "Norwegian",
    officialLanguageNameLocal: "Norsk",
    countryCallingCode: "4779",
    region: "Europe",
    flag: "🇸🇯",
    locale: "no-SJ"
  },
  {
    countryNameEn: "Slovakia",
    countryNameLocal: "Slovensko",
    countryCode: "SK",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "IČ DPH",
    tinName: "Identifikačné číslo pre daň z pridanej hodnoty",
    officialLanguageCode: "sk",
    officialLanguageNameEn: "Slovak",
    officialLanguageNameLocal: "Slovenčina, Slovenský Jazyk",
    countryCallingCode: "421",
    region: "Europe",
    cities: [
      { name: "Bratislava", slug: "sk-bratislava", pop: 437000 },
      { name: "Košice", slug: "sk-kosice", pop: 283000 },
      { name: "Prešov", slug: "sk-presov", pop: 88000 },
      { name: "Žilina", slug: "sk-zilina", pop: 80000 },
      { name: "Banská Bystrica", slug: "sk-banska-bystrica", pop: 78000 },
      { name: "Nitra", slug: "sk-nitra", pop: 76000 },
      { name: "Trnava", slug: "sk-trnava", pop: 65000 },
      { name: "Trenčín", slug: "sk-trencin", pop: 55000 },
      { name: "Martin", slug: "sk-martin", pop: 54000 },
      { name: "Poprad", slug: "sk-poprad", pop: 51000 },
      { name: "Prievidza", slug: "sk-prievidza" },
      { name: "Zvolen", slug: "sk-zvolen" },
      { name: "Považská Bystrica", slug: "sk-povazska-bystrica" },
      { name: "Michalovce", slug: "sk-michalovce" },
      { name: "Spišská Nová Ves", slug: "sk-spisska-nova-ves" },
      { name: "Komárno", slug: "sk-komarno" },
      { name: "Levice", slug: "sk-levice" },
      { name: "Humenné", slug: "sk-humenne" },
      { name: "Bardejov", slug: "sk-bardejov" },
      { name: "Liptovský Mikuláš", slug: "sk-liptovsky-mikulas" },
      { name: "Rožňava", slug: "sk-roznava" },
      { name: "Dunajská Streda", slug: "sk-dunajska-streda" },
      { name: "Hlohovec", slug: "sk-hlohovec" },
      { name: "Snina", slug: "sk-snina" },
      { name: "Šaľa", slug: "sk-sala" },
    ],
    translations: {
      "kr": "슬로바키아",
      "pt-BR": "Eslováquia",
      "pt": "Eslováquia",
      "nl": "Slowakije",
      "hr": "Slovačka",
      "fa": "اسلواکی",
      "de": "Slowakei",
      "es": "República Eslovaca",
      "fr": "Slovaquie",
      "ja": "スロバキア",
      "it": "Slovacchia",
      "cn": "斯洛伐克",
      "tr": "Slovakya"
    },
    enabled: true,
    flag: "🇸🇰",
    locale: "sk-SK"
  },
  {
    countryNameEn: "Sierra Leone",
    countryNameLocal: "Sierra Leone",
    countryCode: "SL",
    currencyCode: "SLL",
    currencyNameEn: "Sierra Leonean leone",
    currencySymbol: "Le",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "232",
    region: "Africa",
    flag: "🇸🇱",
    locale: "en-SL",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Republic of San Marino",
    countryNameLocal: "Repubblica di San Marino",
    countryCode: "SM",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "C.O.E.",
    tinName: "Codice operatore economico",
    officialLanguageCode: "it",
    officialLanguageNameEn: "Italian",
    officialLanguageNameLocal: "Italiano",
    countryCallingCode: "378",
    region: "Europe",
    flag: "🇸🇲",
    locale: "it-SM"
  },
  {
    countryNameEn: "Senegal",
    countryNameLocal: "Sénégal",
    countryCode: "SN",
    currencyCode: "XOF",
    currencyNameEn: "CFA franc BCEAO",
    currencySymbol: "Fr",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "221",
    region: "Africa",
    flag: "🇸🇳",
    locale: "fr-SN"
  },
  {
    countryNameEn: "Somalia",
    countryNameLocal: "Somalia, الصومال",
    countryCode: "SO",
    currencyCode: "SOS",
    currencyNameEn: "Somali shilling",
    currencySymbol: "Sh",
    officialLanguageCode: "so",
    officialLanguageNameEn: "Somali",
    officialLanguageNameLocal: "Soomaaliga, af Soomaali",
    countryCallingCode: "252",
    region: "Arab States",
    flag: "🇸🇴",
    blocked: true,
    locale: "ar-SO"
  },
  {
    countryNameEn: "Suriname",
    countryNameLocal: "Suriname",
    countryCode: "SR",
    currencyCode: "SRD",
    currencyNameEn: "Surinamese dollar",
    currencySymbol: "$",
    officialLanguageCode: "nl",
    officialLanguageNameEn: "Dutch, Flemish",
    officialLanguageNameLocal: "Nederlands, Vlaams",
    countryCallingCode: "597",
    region: "South/Latin America",
    flag: "🇸🇷",
    locale: "nl-SR"
  },
  {
    countryNameEn: "South Sudan",
    countryNameLocal: "South Sudan",
    countryCode: "SS",
    currencyCode: "SSP",
    currencyNameEn: "South Sudanese pound",
    currencySymbol: "£",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "211",
    region: "Africa",
    flag: "🇸🇸",
    blocked: true,
    locale: "en-SS"
  },
  {
    countryNameEn: "Sao Tome and Principe",
    countryNameLocal: "São Tomé e Príncipe",
    countryCode: "ST",
    currencyCode: "STN",
    currencyNameEn: "",
    currencySymbol: "",
    officialLanguageCode: "pt",
    officialLanguageNameEn: "Portuguese",
    officialLanguageNameLocal: "Português",
    countryCallingCode: "239",
    region: "Africa",
    flag: "🇸🇹",
    locale: "pt-ST"
  },
  {
    countryNameEn: "El Salvador",
    countryNameLocal: "El Salvador",
    countryCode: "SV",
    currencyCode: "SVC",
    currencyNameEn: "Salvadoran colón",
    currencySymbol: "$",
    tinType: "NIT",
    tinName: "Número de Identificación Tributaria",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "503",
    region: "South/Latin America",
    flag: "🇸🇻",
    locale: "es-SV"
  },
  {
    countryNameEn: "Sint Maarten (Dutch part)",
    countryNameLocal: "Sint Maarten",
    countryCode: "SX",
    currencyCode: "ANG",
    currencyNameEn: "Netherlands Antillean guilder",
    currencySymbol: "ƒ",
    officialLanguageCode: "nl",
    officialLanguageNameEn: "Dutch, Flemish",
    officialLanguageNameLocal: "Nederlands, Vlaams",
    countryCallingCode: "1721",
    region: "Unknown",
    flag: "🇸🇽",
    locale: "en-SX"
  },
  {
    countryNameEn: "Syrian Arab Republic",
    countryNameLocal: "سوريا, Sūriyya",
    countryCode: "SY",
    currencyCode: "SYP",
    currencyNameEn: "Syrian pound",
    currencySymbol: "£",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "963",
    region: "Asia & Pacific",
    flag: "🇸🇾",
    blocked: true,
    locale: "ar-SY"
  },
  {
    countryNameEn: "Chad",
    countryNameLocal: "Tchad, تشاد",
    countryCode: "TD",
    currencyCode: "XAF",
    currencyNameEn: "CFA franc BEAC",
    currencySymbol: "Fr",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "235",
    region: "Africa",
    flag: "🇹🇩",
    locale: "ar-TD",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Togo",
    countryNameLocal: "Togo",
    countryCode: "TG",
    currencyCode: "XOF",
    currencyNameEn: "CFA franc BCEAO",
    currencySymbol: "Fr",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "228",
    region: "Africa",
    flag: "🇹🇬",
    locale: "fr-TG"
  },
  {
    countryNameEn: "Thailand",
    countryNameLocal: "ประเทศไทย",
    countryCode: "TH",
    currencyCode: "THB",
    currencyNameEn: "Thai baht",
    currencySymbol: "฿",
    officialLanguageCode: "th",
    officialLanguageNameEn: "Thai",
    officialLanguageNameLocal: "ไทย",
    countryCallingCode: "66",
    region: "Asia & Pacific",
    cities: [
      { name: "Bangkok", slug: "th-bangkok", pop: 10500000 },
      { name: "Chiang Mai", slug: "th-chiang-mai", pop: 1198000 },
      { name: "Nakhon Ratchasima", slug: "th-nakhon-ratchasima", pop: 466000 },
      { name: "Khon Kaen", slug: "th-khon-kaen", pop: 412000 },
      { name: "Hat Yai", slug: "th-hat-yai", pop: 404000 },
      { name: "Udon Thani", slug: "th-udon-thani", pop: 400000 },
      { name: "Chon Buri", slug: "th-chon-buri", pop: 342000 },
      { name: "Pattaya", slug: "th-pattaya", pop: 328000 },
      { name: "Si Racha", slug: "th-si-racha", pop: 327000 },
      { name: "Phitsanulok", slug: "th-phitsanulok", pop: 281000 },
      { name: "Mueang Nonthaburi", slug: "th-mueang-nonthaburi" },
      { name: "Pak Kret", slug: "th-pak-kret" },
      { name: "Surat Thani", slug: "th-surat-thani" },
      { name: "Samut Prakan", slug: "th-samut-prakan" },
      { name: "Phra Pradaeng", slug: "th-phra-pradaeng" },
      { name: "Lampang", slug: "th-lampang" },
      { name: "Ban Mai", slug: "th-ban-mai" },
      { name: "Ubon Ratchathani", slug: "th-ubon-ratchathani" },
      { name: "Nakhon Si Thammarat", slug: "th-nakhon-si-thammarat" },
      { name: "Chiang Rai", slug: "th-chiang-rai" },
      { name: "Bang Kruai", slug: "th-bang-kruai" },
      { name: "Nakhon Pathom", slug: "th-nakhon-pathom" },
      { name: "Yala", slug: "th-yala" },
      { name: "Ratchaburi", slug: "th-ratchaburi" },
    ],
    translations: {
      "kr": "태국",
      "pt-BR": "Tailândia",
      "pt": "Tailândia",
      "nl": "Thailand",
      "hr": "Tajland",
      "fa": "تایلند",
      "de": "Thailand",
      "es": "Tailandia",
      "fr": "Thaïlande",
      "ja": "タイ",
      "it": "Tailandia",
      "cn": "泰国",
      "tr": "Tayland"
    },
    enabled: true,
    flag: "🇹🇭",
    locale: "th-TH",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Tajikistan",
    countryNameLocal: ",",
    countryCode: "TJ",
    currencyCode: "TJS",
    currencyNameEn: "Tajikistani somoni",
    currencySymbol: "ЅМ",
    officialLanguageCode: "tg",
    officialLanguageNameEn: "Tajik",
    officialLanguageNameLocal: "тоҷикӣ, toçikī, تاجیکی‎",
    countryCallingCode: "992",
    region: "Asia & Pacific",
    flag: "🇹🇯",
    blocked: true,
    locale: "ru-TJ"
  },
  {
    countryNameEn: "Tokelau",
    countryNameLocal: "Tokelau",
    countryCode: "TK",
    currencyCode: "NZD",
    currencyNameEn: "New Zealand dollar",
    currencySymbol: "$",
    officialLanguageCode: "tkl",
    officialLanguageNameEn: "",
    officialLanguageNameLocal: "",
    countryCallingCode: "690",
    region: "Asia & Pacific",
    flag: "🇹🇰",
    locale: "en-TK"
  },
  {
    countryNameEn: "Timor-Leste",
    countryNameLocal: "Timor-Leste, Timor Lorosa'e",
    countryCode: "TL",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    currencySymbol: "$",
    officialLanguageCode: "pt",
    officialLanguageNameEn: "Portuguese",
    officialLanguageNameLocal: "Português",
    countryCallingCode: "670",
    region: "Asia & Pacific",
    flag: "🇹🇱",
    locale: "pt-TL"
  },
  {
    countryNameEn: "Turkmenistan",
    countryNameLocal: "Türkmenistan",
    countryCode: "TM",
    currencyCode: "TMT",
    currencyNameEn: "Turkmenistan manat",
    currencySymbol: "m",
    officialLanguageCode: "tk",
    officialLanguageNameEn: "Turkmen",
    officialLanguageNameLocal: "Türkmen, Түркмен",
    countryCallingCode: "993",
    region: "Asia & Pacific",
    flag: "🇹🇲",
    blocked: true,
    locale: "ru-TM"
  },
  {
    countryNameEn: "Tunisia",
    countryNameLocal: "تونس, Tunisie",
    countryCode: "TN",
    currencyCode: "TND",
    currencyNameEn: "Tunisian dinar",
    currencySymbol: "د.ت",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "216",
    region: "Arab States",
    flag: "🇹🇳",
    blocked: true,
    locale: "ar-TN",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Tonga",
    countryNameLocal: "Tonga",
    countryCode: "TO",
    currencyCode: "TOP",
    currencyNameEn: "Tongan paʻanga",
    currencySymbol: "T$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "676",
    region: "Asia & Pacific",
    flag: "🇹🇴",
    locale: "en-TO"
  },
  {
    countryNameEn: "Turkey",
    countryNameLocal: "Türkiye",
    countryCode: "TR",
    currencyCode: "TRY",
    currencyNameEn: "Turkish lira",
    currencySymbol: "₺",
    tinType: "KDV",
    tinName: "Vergi Kimlik Numarası",
    officialLanguageCode: "tr",
    officialLanguageNameEn: "Turkish",
    officialLanguageNameLocal: "Türkçe",
    countryCallingCode: "90",
    region: "Europe",
    vpn: true,
    flag: "🇹🇷",
    locale: "tr-TR",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Trinidad and Tobago",
    countryNameLocal: "Trinidad and Tobago",
    countryCode: "TT",
    currencyCode: "TTD",
    currencyNameEn: "Trinidad and Tobago dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "868",
    region: "South/Latin America",
    flag: "🇹🇹",
    locale: "en-TT"
  },
  {
    countryNameEn: "Tuvalu",
    countryNameLocal: "Tuvalu",
    countryCode: "TV",
    currencyCode: "AUD",
    currencyNameEn: "Australian dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "688",
    region: "Asia & Pacific",
    flag: "🇹🇻",
    locale: "en-TV"
  },
  {
    countryNameEn: "United Republic of Tanzania",
    countryNameLocal: "Tanzania",
    countryCode: "TZ",
    currencyCode: "TZS",
    currencyNameEn: "Tanzanian shilling",
    currencySymbol: "Sh",
    officialLanguageCode: "sw",
    officialLanguageNameEn: "Swahili",
    officialLanguageNameLocal: "Kiswahili",
    countryCallingCode: "255",
    region: "Africa",
    flag: "🇹🇿",
    locale: "en-TZ"
  },
  {
    countryNameEn: "Ukraine",
    countryNameLocal: "Україна",
    countryCode: "UA",
    currencyCode: "UAH",
    currencyNameEn: "Ukrainian hryvnia",
    currencySymbol: "₴",
    tinType: "ІНПП",
    tinName: "Ідентифікаційний номер платника податків",
    officialLanguageCode: "uk",
    officialLanguageNameEn: "Ukrainian",
    officialLanguageNameLocal: "Українська",
    countryCallingCode: "380",
    region: "Europe",
    cities: [
      { name: "Kiev", slug: "ua-kiev", pop: 2950000 },
      { name: "Kharkiv", slug: "ua-kharkiv", pop: 1420000 },
      { name: "Odessa", slug: "ua-odessa", pop: 1010000 },
      { name: "Dnipro", slug: "ua-dnipro", pop: 968000 },
      { name: "Donetsk", slug: "ua-donetsk", pop: 901000 },
      { name: "Lviv", slug: "ua-lviv", pop: 717000 },
      { name: "Zaporizhia", slug: "ua-zaporizhia", pop: 710000 },
      { name: "Kryvyi Rih", slug: "ua-kryvyi-rih", pop: 603000 },
      { name: "Mykolaiv", slug: "ua-mykolaiv", pop: 470000 },
      { name: "Mariupol", slug: "ua-mariupol", pop: 425000 },
      { name: "Luhansk", slug: "ua-luhansk" },
      { name: "Vinnytsia", slug: "ua-vinnytsia" },
      { name: "Makiivka", slug: "ua-makiivka" },
      { name: "Simferopol", slug: "ua-simferopol" },
      { name: "Sevastopol", slug: "ua-sevastopol" },
      { name: "Kherson", slug: "ua-kherson" },
      { name: "Poltava", slug: "ua-poltava" },
      { name: "Chernihiv", slug: "ua-chernihiv" },
      { name: "Cherkasy", slug: "ua-cherkasy" },
      { name: "Sumy", slug: "ua-sumy" },
      { name: "Zhytomyr", slug: "ua-zhytomyr" },
      { name: "Horlivka", slug: "ua-horlivka" },
      { name: "Rivne", slug: "ua-rivne" },
      { name: "Kirovohrad", slug: "ua-kirovohrad" },
    ],
    translations: {
      "kr": "우크라이나",
      "pt-BR": "Ucrânia",
      "pt": "Ucrânia",
      "nl": "Oekraïne",
      "hr": "Ukrajina",
      "fa": "وکراین",
      "de": "Ukraine",
      "es": "Ucrania",
      "fr": "Ukraine",
      "ja": "ウクライナ",
      "it": "Ucraina",
      "cn": "乌克兰",
      "tr": "Ukrayna"
    },
    enabled: true,
    flag: "🇺🇦",
    locale: "uk-UA"
  },
  {
    countryNameEn: "Uganda",
    countryNameLocal: "Uganda",
    countryCode: "UG",
    currencyCode: "UGX",
    currencyNameEn: "Ugandan shilling",
    currencySymbol: "Sh",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "256",
    region: "Africa",
    flag: "🇺🇬",
    locale: "en-UG"
  },
  {
    countryNameEn: "United States of America",
    countryNameLocal: "United States of America",
    countryCode: "US",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    currencySymbol: "$",
    tinType: "EIN",
    tinName: "Tax Identification Number",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1",
    region: "North America",
    cities: [
      { name: "New York", slug: "us-new-york", pop: 8258000 },
      { name: "Los Angeles", slug: "us-los-angeles", pop: 3820000 },
      { name: "Chicago", slug: "us-chicago", pop: 2660000 },
      { name: "Houston", slug: "us-houston", pop: 2310000 },
      { name: "Phoenix", slug: "us-phoenix", pop: 1650000 },
      { name: "Philadelphia", slug: "us-philadelphia", pop: 1550000 },
      { name: "San Antonio", slug: "us-san-antonio", pop: 1490000 },
      { name: "San Diego", slug: "us-san-diego", pop: 1380000 },
      { name: "Dallas", slug: "us-dallas", pop: 1300000 },
      { name: "San Jose", slug: "us-san-jose", pop: 969000 },
      { name: "Austin", slug: "us-austin", pop: 979000 },
      { name: "Jacksonville", slug: "us-jacksonville", pop: 985000 },
      { name: "Fort Worth", slug: "us-fort-worth" },
      { name: "Columbus", slug: "us-columbus" },
      { name: "San Francisco", slug: "us-san-francisco" },
      { name: "Charlotte", slug: "us-charlotte" },
      { name: "Indianapolis", slug: "us-indianapolis" },
      { name: "Seattle", slug: "us-seattle" },
      { name: "Denver", slug: "us-denver" },
      { name: "Washington", slug: "us-washington" },
      { name: "Boston", slug: "us-boston" },
      { name: "El Paso", slug: "us-el-paso" },
      { name: "Nashville", slug: "us-nashville" },
      { name: "Detroit", slug: "us-detroit" },
      { name: "Oklahoma City", slug: "us-oklahoma-city" },
    ],
    translations: {
      "kr": "미국",
      "pt-BR": "Estados Unidos",
      "pt": "Estados Unidos",
      "nl": "Verenigde Staten",
      "hr": "Sjedinjene Američke Države",
      "fa": "ایالات متحده آمریکا",
      "de": "Vereinigte Staaten von Amerika",
      "es": "Estados Unidos",
      "fr": "États-Unis",
      "ja": "アメリカ合衆国",
      "it": "Stati Uniti D'America",
      "cn": "美国",
      "tr": "Amerika"
    },
    enabled: true,
    flag: "🇺🇸",
    locale: "en-US"
  },
  {
    countryNameEn: "Uruguay",
    countryNameLocal: "Uruguay",
    countryCode: "UY",
    currencyCode: "UYU",
    currencyNameEn: "Uruguayan peso",
    currencySymbol: "$",
    tinType: "RUT",
    tinName: "Registro Único Tributario",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "598",
    region: "South/Latin America",
    flag: "🇺🇾",
    locale: "es-UY"
  },
  {
    countryNameEn: "Uzbekistan",
    countryNameLocal: "",
    countryCode: "UZ",
    currencyCode: "UZS",
    currencyNameEn: "Uzbekistan som",
    currencySymbol: "so'm",
    tinType: "СТИР",
    tinName: "Солиқ тўловчиларнинг идентификация рақами",
    officialLanguageCode: "uz",
    officialLanguageNameEn: "Uzbek",
    officialLanguageNameLocal: "Oʻzbek, Ўзбек, أۇزبېك‎",
    countryCallingCode: "998",
    region: "Asia & Pacific",
    flag: "🇺🇿",
    locale: "ru-UZ"
  },
  {
    countryNameEn: "Saint Vincent and the Grenadines",
    countryNameLocal: "Saint Vincent and the Grenadines",
    countryCode: "VC",
    currencyCode: "XCD",
    currencyNameEn: "East Caribbean dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1784",
    region: "South/Latin America",
    flag: "🇻🇨",
    locale: "en-VC"
  },
  {
    countryNameEn: "Venezuela (Bolivarian Republic of)",
    countryNameLocal: "Venezuela",
    countryCode: "VE",
    currencyCode: "VES",
    currencyNameEn: "",
    currencySymbol: "",
    tinType: "RIF",
    tinName: "Registro de Informacion Fiscal",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "58",
    region: "South/Latin America",
    flag: "🇻🇪",
    locale: "es-VE"
  },
  {
    countryNameEn: "Virgin Islands (British)",
    countryNameLocal: "British Virgin Islands",
    countryCode: "VG",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1284",
    region: "South/Latin America",
    flag: "🇻🇬",
    locale: "en-VG"
  },
  {
    countryNameEn: "Virgin Islands (U.S.)",
    countryNameLocal: "United States Virgin Islands",
    countryCode: "VI",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1340",
    region: "South/Latin America",
    flag: "🇻🇮",
    locale: "en-VI"
  },
  {
    countryNameEn: "Vietnam",
    countryNameLocal: "Việt Nam",
    countryCode: "VN",
    currencyCode: "VND",
    currencyNameEn: "Vietnamese đồng",
    currencySymbol: "₫",
    officialLanguageCode: "vi",
    officialLanguageNameEn: "Vietnamese",
    officialLanguageNameLocal: "Tiếng Việt",
    countryCallingCode: "84",
    region: "Asia & Pacific",
    cities: [
      { name: "Ho Chi Minh City", slug: "vn-ho-chi-minh-city", pop: 8990000 },
      { name: "Hanoi", slug: "vn-hanoi", pop: 8050000 },
      { name: "Da Nang", slug: "vn-da-nang", pop: 1130000 },
      { name: "Hai Phong", slug: "vn-hai-phong", pop: 2030000 },
      { name: "Can Tho", slug: "vn-can-tho", pop: 1230000 },
      { name: "Bien Hoa", slug: "vn-bien-hoa", pop: 1050000 },
      { name: "Hue", slug: "vn-hue", pop: 652000 },
      { name: "Thuan An", slug: "vn-thuan-an", pop: 618000 },
      { name: "Hai Duong", slug: "vn-hai-duong", pop: 508000 },
      { name: "Tan Uyen", slug: "vn-tan-uyen", pop: 466000 },
      { name: "Vung Tau", slug: "vn-vung-tau" },
      { name: "Nha Trang", slug: "vn-nha-trang" },
      { name: "Buon Ma Thuot", slug: "vn-buon-ma-thuot" },
      { name: "Thai Nguyen", slug: "vn-thai-nguyen" },
      { name: "Long Xuyen", slug: "vn-long-xuyen" },
      { name: "Soc Trang", slug: "vn-soc-trang" },
      { name: "Vi Thanh", slug: "vn-vi-thanh" },
      { name: "Tuy Hoa", slug: "vn-tuy-hoa" },
      { name: "Ha Long", slug: "vn-ha-long" },
      { name: "Phan Rang-Thap Cham", slug: "vn-phan-rang-thap-cham" },
      { name: "Cam Ranh", slug: "vn-cam-ranh" },
      { name: "Kon Tum", slug: "vn-kon-tum" },
      { name: "Quang Ngai", slug: "vn-quang-ngai" },
      { name: "Rach Gia", slug: "vn-rach-gia" },
      { name: "Ca Mau", slug: "vn-ca-mau" },
      { name: "Quang Tri", slug: "vn-quang-tri" },
      { name: "Yen Bai", slug: "vn-yen-bai" },
    ],
    translations: {
      "kr": "베트남",
      "pt-BR": "Vietnã",
      "pt": "Vietname",
      "nl": "Vietnam",
      "hr": "Vijetnam",
      "fa": "ویتنام",
      "de": "Vietnam",
      "es": "Vietnam",
      "fr": "Viêt Nam",
      "ja": "ベトナム",
      "it": "Vietnam",
      "cn": "越南",
      "tr": "Vietnam"
    },
    enabled: true,
    flag: "🇻🇳",
    locale: "vi-VN",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Vanuatu",
    countryNameLocal: "Vanuatu",
    countryCode: "VU",
    currencyCode: "VUV",
    currencyNameEn: "Vanuatu vatu",
    currencySymbol: "Vt",
    officialLanguageCode: "bi",
    officialLanguageNameEn: "Bislama",
    officialLanguageNameLocal: "Bislama",
    countryCallingCode: "678",
    region: "Asia & Pacific",
    flag: "🇻🇺",
    locale: "bi-VU"
  },
  {
    countryNameEn: "Wallis and Futuna",
    countryNameLocal: "Wallis-et-Futuna",
    countryCode: "WF",
    currencyCode: "XPF",
    currencyNameEn: "CFP franc (franc Pacifique)",
    currencySymbol: "₣",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "681",
    region: "Asia & Pacific",
    flag: "🇼🇫",
    locale: "fr-WF"
  },
  {
    countryNameEn: "Samoa",
    countryNameLocal: "Samoa",
    countryCode: "WS",
    currencyCode: "WST",
    currencyNameEn: "Samoan tala",
    currencySymbol: "T",
    officialLanguageCode: "sm",
    officialLanguageNameEn: "Samoan",
    officialLanguageNameLocal: "gagana fa'a Samoa",
    countryCallingCode: "685",
    region: "Asia & Pacific",
    flag: "🇼🇸",
    locale: "en-WS"
  },
  {
    countryNameEn: "Yemen",
    countryNameLocal: "اليَمَن",
    countryCode: "YE",
    currencyCode: "YER",
    currencyNameEn: "Yemeni rial",
    currencySymbol: "﷼",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "967",
    region: "Arab States",
    flag: "🇾🇪",
    blocked: true,
    locale: "ar-YE"
  },
  {
    countryNameEn: "Mayotte",
    countryNameLocal: "Mayotte",
    countryCode: "YT",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "262",
    region: "Africa",
    flag: "🇾🇹",
    locale: "fr-YT"
  },
  {
    countryNameEn: "South Africa",
    countryNameLocal: "South Africa",
    countryCode: "ZA",
    currencyCode: "ZAR",
    currencyNameEn: "South African rand",
    currencySymbol: "R",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "27",
    region: "Africa",
    cities: [
      { name: "Johannesburg", slug: "za-johannesburg", pop: 9167000 },
      { name: "Cape Town", slug: "za-cape-town", pop: 4000000 },
      { name: "Durban", slug: "za-durban", pop: 3660000 },
      { name: "Pretoria", slug: "za-pretoria", pop: 2430000 },
      { name: "Gqeberha", slug: "za-gqeberha", pop: 1260000 },
      { name: "Vereeniging", slug: "za-vereeniging", pop: 957000 },
      { name: "Soshanguve", slug: "za-soshanguve", pop: 841000 },
      { name: "East London", slug: "za-east-london", pop: 810000 },
      { name: "Bloemfontein", slug: "za-bloemfontein", pop: 759000 },
      { name: "Pietermaritzburg", slug: "za-pietermaritzburg", pop: 679000 },
      { name: "Port Elizabeth", slug: "za-port-elizabeth" },
      { name: "Bloemfontein", slug: "za-bloemfontein" },
      { name: "Middelburg", slug: "za-middelburg" },
      { name: "Vanderbijlpark", slug: "za-vanderbijlpark" },
      { name: "Krugersdorp", slug: "za-krugersdorp" },
      { name: "Botshabelo", slug: "za-botshabelo" },
      { name: "Witbank", slug: "za-witbank" },
      { name: "Richards Bay", slug: "za-richards-bay" },
      { name: "Randfontein", slug: "za-randfontein" },
      { name: "Uitenhage", slug: "za-uitenhage" },
      { name: "Roodepoort", slug: "za-roodepoort" },
      { name: "Paarl", slug: "za-paarl" },
      { name: "Springs", slug: "za-springs" },
      { name: "Carletonville", slug: "za-carletonville" },
      { name: "Klerksdorp", slug: "za-klerksdorp" },
      { name: "Mossel Bay", slug: "za-mossel-bay" },
      { name: "Potchefstroom", slug: "za-potchefstroom" },
    ],
    translations: {
      "kr": "남아프리카 공화국",
      "pt-BR": "República Sul-Africana",
      "pt": "República Sul-Africana",
      "nl": "Zuid-Afrika",
      "hr": "Južnoafrička Republika",
      "fa": "آفریقای جنوبی",
      "de": "Republik Südafrika",
      "es": "República de Sudáfrica",
      "fr": "Afrique du Sud",
      "ja": "南アフリカ",
      "it": "Sud Africa",
      "cn": "南非",
      "tr": "Güney Afrika Cumhuriyeti"
    },
    enabled: true,
    flag: "🇿🇦",
    locale: "af-ZA"
  },
  {
    countryNameEn: "Zambia",
    countryNameLocal: "Zambia",
    countryCode: "ZM",
    currencyCode: "ZMW",
    currencyNameEn: "Zambian kwacha",
    currencySymbol: "ZK",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "260",
    region: "Africa",
    flag: "🇿🇲",
    locale: "en-ZM"
  },
  {
    countryNameEn: "Zimbabwe",
    countryNameLocal: "Zimbabwe",
    countryCode: "ZW",
    currencyCode: "ZWL",
    currencyNameEn: "Zimbabwean dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "263",
    region: "Africa",
    flag: "🇿🇼",
    locale: "en-ZW"
  },
  {
    countryNameEn: "Eswatini",
    countryNameLocal: "Swaziland",
    countryCode: "SZ",
    currencyCode: "SZL",
    currencyNameEn: "Swazi lilangeni",
    currencySymbol: "L",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "268",
    region: "Africa",
    flag: "🇸🇿",
    locale: "en-SZ"
  },
  {
    countryNameEn: "North Macedonia",
    countryNameLocal: "Македонија",
    countryCode: "MK",
    currencyCode: "MKD",
    currencyNameEn: "Macedonian denar",
    currencySymbol: "den",
    officialLanguageCode: "mk",
    officialLanguageNameEn: "Macedonian",
    officialLanguageNameLocal: "македонски јазик",
    countryCallingCode: "389",
    region: "Europe",
    flag: "🇲🇰",
    locale: "mk-MK"
  },
  {
    countryNameEn: "Philippines",
    countryNameLocal: "Philippines",
    countryCode: "PH",
    currencyCode: "PHP",
    currencyNameEn: "Philippine peso",
    currencySymbol: "₱",
    tinType: "TIN",
    tinName: "Tax Identification Number",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "63",
    region: "Asia & Pacific",
    flag: "🇵🇭",
    locale: "en-PH"
  },
  {
    countryNameEn: "Netherlands",
    countryNameLocal: "Nederland",
    countryCode: "NL",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    tinType: "Btw-nr.",
    tinName: "Btw-nummer",
    officialLanguageCode: "nl",
    officialLanguageNameEn: "Dutch, Flemish",
    officialLanguageNameLocal: "Nederlands, Vlaams",
    countryCallingCode: "31",
    region: "Europe",
    cities: [
      { name: "Amsterdam", slug: "nl-amsterdam", pop: 905000 },
      { name: "Rotterdam", slug: "nl-rotterdam", pop: 656000 },
      { name: "The Hague", slug: "nl-the-hague", pop: 552000 },
      { name: "Utrecht", slug: "nl-utrecht", pop: 361000 },
      { name: "Eindhoven", slug: "nl-eindhoven", pop: 238000 },
      { name: "Tilburg", slug: "nl-tilburg", pop: 224000 },
      { name: "Groningen", slug: "nl-groningen", pop: 234000 },
      { name: "Almere", slug: "nl-almere", pop: 218000 },
      { name: "Breda", slug: "nl-breda", pop: 184000 },
      { name: "Nijmegen", slug: "nl-nijmegen", pop: 179000 },
      { name: "Apeldoorn", slug: "nl-apeldoorn" },
      { name: "Haarlem", slug: "nl-haarlem" },
      { name: "Arnhem", slug: "nl-arnhem" },
      { name: "Zaanstad", slug: "nl-zaanstad" },
      { name: "Amersfoort", slug: "nl-amersfoort" },
      { name: "Haarlemmermeer", slug: "nl-haarlemmermeer" },
      { name: "'s-Hertogenbosch", slug: "nl-s-hertogenbosch" },
      { name: "Zoetermeer", slug: "nl-zoetermeer" },
      { name: "Zwolle", slug: "nl-zwolle" },
      { name: "Leiden", slug: "nl-leiden" },
      { name: "Maastricht", slug: "nl-maastricht" },
      { name: "Dordrecht", slug: "nl-dordrecht" },
      { name: "Ede", slug: "nl-ede" },
      { name: "Alphen aan den Rijn", slug: "nl-alphen-aan-den-rijn" },
    ],
    translations: {
      "kr": "네덜란드 ",
      "pt-BR": "Holanda",
      "pt": "Países Baixos",
      "nl": "Nederland",
      "hr": "Nizozemska",
      "fa": "پادشاهی هلند",
      "de": "Niederlande",
      "es": "Países Bajos",
      "fr": "Pays-Bas",
      "ja": "オランダ",
      "it": "Paesi Bassi",
      "cn": "荷兰",
      "tr": "Hollanda"
    },
    enabled: true,
    flag: "🇳🇱",
    locale: "nl-NL"
  },
  {
    countryNameEn: "United Arab Emirates",
    countryNameLocal: "دولة الإمارات العربيّة المتّحدة",
    countryCode: "AE",
    currencyCode: "AED",
    currencyNameEn: "United Arab Emirates dirham",
    currencySymbol: "د.إ",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "971",
    region: "Arab States",
    flag: "🇦🇪",
    vpn: true,
    locale: "ar-AE"
  },
  {
    countryNameEn: "Republic of Moldova",
    countryNameLocal: "Moldova, Молдавия",
    countryCode: "MD",
    currencyCode: "MDL",
    currencyNameEn: "Moldovan leu",
    currencySymbol: "L",
    officialLanguageCode: "ro",
    officialLanguageNameEn: "Romanian, Moldavian, Moldovan",
    officialLanguageNameLocal: "Română",
    countryCallingCode: "373",
    region: "Europe",
    flag: "🇲🇩",
    locale: "ro-MD"
  },
  {
    countryNameEn: "Gambia",
    countryNameLocal: "The Gambia",
    countryCode: "GM",
    currencyCode: "GMD",
    currencyNameEn: "Gambian dalasi",
    currencySymbol: "D",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "220",
    region: "Africa",
    flag: "🇬🇲",
    locale: "en-GM"
  },
  {
    countryNameEn: "Dominican Republic",
    countryNameLocal: "República Dominicana",
    countryCode: "DO",
    currencyCode: "DOP",
    currencyNameEn: "Dominican peso",
    currencySymbol: "$",
    tinType: "RNC",
    tinName: "Registro Nacional del Contribuyente",
    officialLanguageCode: "es",
    officialLanguageNameEn: "Spanish, Castilian",
    officialLanguageNameLocal: "Español",
    countryCallingCode: "1",
    region: "South/Latin America",
    cities: [
      { name: "Santo Domingo", slug: "do-santo-domingo", pop: 2987000 },
      { name: "Santiago de los Caballeros", slug: "do-santiago-de-los-caballeros", pop: 1330000 },
      { name: "Santo Domingo Oeste", slug: "do-santo-domingo-oeste", pop: 701000 },
      { name: "Santo Domingo Este", slug: "do-santo-domingo-este", pop: 624000 },
      { name: "Santo Domingo Norte", slug: "do-santo-domingo-norte", pop: 286000 },
      { name: "San Felipe de Puerto Plata", slug: "do-san-felipe-de-puerto-plata", pop: 277000 },
      { name: "Concepción de la Vega", slug: "do-concepcion-de-la-vega", pop: 220000 },
      { name: "San Pedro de Macorís", slug: "do-san-pedro-de-macoris", pop: 193000 },
      { name: "La Romana", slug: "do-la-romana", pop: 191000 },
      { name: "Los Alcarrizos", slug: "do-los-alcarrizos", pop: 166000 },
      { name: "Bella Vista", slug: "do-bella-vista" },
      { name: "San Cristóbal", slug: "do-san-cristobal" },
      { name: "Puerto Plata", slug: "do-puerto-plata" },
      { name: "San Francisco de Macorís", slug: "do-san-francisco-de-macoris" },
      { name: "Salvaleón de Higüey", slug: "do-salvaleon-de-higuey" },
      { name: "La Vega", slug: "do-la-vega" },
      { name: "Punta Cana", slug: "do-punta-cana" },
      { name: "Barahona", slug: "do-barahona" },
      { name: "Bonao", slug: "do-bonao" },
      { name: "San Juan de la Maguana", slug: "do-san-juan-de-la-maguana" },
      { name: "Bajos de Haina", slug: "do-bajos-de-haina" },
      { name: "Moca", slug: "do-moca" },
      { name: "Azua", slug: "do-azua" },
      { name: "Baní", slug: "do-bani" },
      { name: "Nagua", slug: "do-nagua" },
      { name: "Cotuí", slug: "do-cotui" },
      { name: "Hato Mayor del Rey", slug: "do-hato-mayor-del-rey" },
      { name: "Dajabón", slug: "do-dajabon" },
    ],
    translations: {
      "kr": "도미니카 공화국",
      "pt-BR": "República Dominicana",
      "pt": "República Dominicana",
      "nl": "Dominicaanse Republiek",
      "hr": "Dominikanska Republika",
      "fa": "جمهوری دومینیکن",
      "de": "Dominikanische Republik",
      "es": "República Dominicana",
      "fr": "République dominicaine",
      "ja": "ドミニカ共和国",
      "it": "Repubblica Dominicana",
      "cn": "多明尼加共和国",
      "tr": "Dominik Cumhuriyeti"
    },
    enabled: true,
    flag: "🇩🇴",
    locale: "es-DO"
  },
  {
    countryNameEn: "Sudan",
    countryNameLocal: "السودان",
    countryCode: "SD",
    currencyCode: "SDG",
    currencyNameEn: "Sudanese pound",
    currencySymbol: "",
    officialLanguageCode: "ar",
    officialLanguageNameEn: "Arabic",
    officialLanguageNameLocal: "العربية",
    countryCallingCode: "249",
    region: "Arab States",
    flag: "🇸🇩",
    blocked: true,
    locale: "ar-SD"
  },
  {
    countryNameEn: "Lao People's Democratic Republic",
    countryNameLocal: "ປະຊາຊົນລາວ",
    countryCode: "LA",
    currencyCode: "LAK",
    currencyNameEn: "Lao kip",
    currencySymbol: "₭",
    officialLanguageCode: "lo",
    officialLanguageNameEn: "Lao",
    officialLanguageNameLocal: "ພາສາລາວ",
    countryCallingCode: "856",
    region: "Asia & Pacific",
    flag: "🇱🇦",
    locale: "lo-LA"
  },
  {
    countryNameEn: "Taiwan, Province of China",
    countryNameLocal: "臺灣, Taiwan",
    countryCode: "TW",
    currencyCode: "TWD",
    currencyNameEn: "New Taiwan dollar",
    currencySymbol: "$",
    officialLanguageCode: "zh-hant",
    officialLanguageNameEn: "",
    officialLanguageNameLocal: "",
    countryCallingCode: "886",
    region: "Asia & Pacific",
    cities: [
      { name: "Kaohsiung", slug: "tw-kaohsiung", pop: 2770000 },
      { name: "New Taipei", slug: "tw-new-taipei", pop: 3970000 },
      { name: "Taichung", slug: "tw-taichung", pop: 2750000 },
      { name: "Tainan", slug: "tw-tainan", pop: 1880000 },
      { name: "Taipei", slug: "tw-taipei", pop: 2690000 },
      { name: "Taoyuan", slug: "tw-taoyuan", pop: 2130000 },
      { name: "Chiayi", slug: "tw-chiayi", pop: 269000 },
      { name: "Hsinchu", slug: "tw-hsinchu", pop: 436000 },
      { name: "Keelung", slug: "tw-keelung", pop: 372000 },
      { name: "Changhua", slug: "tw-changhua", pop: 235000 },
      { name: "Pingtung", slug: "tw-pingtung" },
      { name: "Zhubei", slug: "tw-zhubei" },
      { name: "Yilan", slug: "tw-yilan" },
      { name: "Douliu", slug: "tw-douliu" },
      { name: "Nantou", slug: "tw-nantou" },
      { name: "Hualien City", slug: "tw-hualien-city" },
      { name: "Miaoli", slug: "tw-miaoli" },
      { name: "Magong", slug: "tw-magong" },
      { name: "Taitung City", slug: "tw-taitung-city" },
      { name: "Yujing", slug: "tw-yujing" },
      { name: "Donggang", slug: "tw-donggang" },
      { name: "Su'ao", slug: "tw-su-ao" },
      { name: "Hengchun", slug: "tw-hengchun" },
      { name: "Jincheng", slug: "tw-jincheng" },
    ],
    translations: {
      "kr": "대만",
      "pt-BR": "Taiwan",
      "pt": "Taiwan",
      "nl": "Taiwan",
      "hr": "Tajvan",
      "fa": "تایوان",
      "de": "Taiwan",
      "es": "Taiwán",
      "fr": "Taïwan",
      "ja": "台湾（中華民国）",
      "it": "Taiwan",
      "cn": "中国台湾",
      "tr": "Tayvan"
    },
    enabled: true,
    flag: "🇹🇼",
    locale: "zh-TW"
  },
  {
    countryNameEn: "Republic of the Congo",
    countryNameLocal: "République du Congo",
    countryCode: "CG",
    currencyCode: "XAF",
    currencyNameEn: "CFA franc BEAC",
    currencySymbol: "Fr",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "242",
    region: "Africa",
    flag: "🇨🇬",
    locale: "fr-CG",
    cryptoProhibited: true
  },
  {
    countryNameEn: "Czechia",
    countryNameLocal: "Česká republika",
    countryCode: "CZ",
    currencyCode: "CZK",
    currencyNameEn: "Czech koruna",
    currencySymbol: "Kč",
    tinType: "DIČ",
    tinName: "Daňové identifikační číslo",
    officialLanguageCode: "cs",
    officialLanguageNameEn: "Czech",
    officialLanguageNameLocal: "Čeština",
    countryCallingCode: "420",
    region: "Europe",
    cities: [
      { name: "Prague", slug: "cz-prague", pop: 1390000 },
      { name: "Brno", slug: "cz-brno", pop: 377000 },
      { name: "Ostrava", slug: "cz-ostrava", pop: 315000 },
      { name: "Plzeň", slug: "cz-plzen", pop: 177000 },
      { name: "Liberec", slug: "cz-liberec", pop: 104000 },
      { name: "Olomouc", slug: "cz-olomouc", pop: 101000 },
      { name: "Ústí nad Labem", slug: "cz-usti-nad-labem", pop: 100000 },
      { name: "Hradec Králové", slug: "cz-hradec-kralove", pop: 100000 },
      { name: "České Budějovice", slug: "cz-ceske-budejovice", pop: 95000 },
      { name: "Pardubice", slug: "cz-pardubice", pop: 90000 },
      { name: "Zlín", slug: "cz-zlin" },
      { name: "Havirov", slug: "cz-havirov" },
      { name: "Kladno", slug: "cz-kladno" },
      { name: "Most", slug: "cz-most" },
      { name: "Karviná", slug: "cz-karvina" },
      { name: "Opava", slug: "cz-opava" },
      { name: "Frýdek-Místek", slug: "cz-frydek-mistek" },
      { name: "Karlovy Vary", slug: "cz-karlovy-vary" },
      { name: "Teplice", slug: "cz-teplice" },
      { name: "Děčín", slug: "cz-decin" },
      { name: "Chomutov", slug: "cz-chomutov" },
      { name: "Jihlava", slug: "cz-jihlava" },
      { name: "Prostějov", slug: "cz-prostejov" },
      { name: "Třebíč", slug: "cz-trebic" },
    ],
    translations: {
      "kr": "체코",
      "pt-BR": "República Tcheca",
      "pt": "República Checa",
      "nl": "Tsjechië",
      "hr": "Češka",
      "fa": "جمهوری چک",
      "de": "Tschechische Republik",
      "es": "República Checa",
      "fr": "République tchèque",
      "ja": "チェコ",
      "it": "Repubblica Ceca",
      "cn": "捷克",
      "tr": "Çekya"
    },
    enabled: true,
    flag: "🇨🇿",
    locale: "cs-CZ"
  },
  {
    countryNameEn: "United Kingdom",
    countryNameLocal: "Great Britain",
    countryCode: "GB",
    currencyCode: "GBP",
    currencyNameEn: "Pound sterling",
    currencySymbol: "£",
    tinType: "VAT Reg No",
    tinName: "Value added tax registration number",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "44",
    region: "Europe",
    cities: [
      { name: "London", slug: "gb-london", pop: 9650000 },
      { name: "Manchester", slug: "gb-manchester", pop: 2790000 },
      { name: "Birmingham", slug: "gb-birmingham", pop: 2665000 },
      { name: "Leeds", slug: "gb-leeds", pop: 1920000 },
      { name: "Glasgow", slug: "gb-glasgow", pop: 1698000 },
      { name: "Southampton", slug: "gb-southampton", pop: 951000 },
      { name: "Liverpool", slug: "gb-liverpool", pop: 917000 },
      { name: "Newcastle upon Tyne", slug: "gb-newcastle-upon-tyne", pop: 823000 },
      { name: "Nottingham", slug: "gb-nottingham", pop: 806000 },
      { name: "Sheffield", slug: "gb-sheffield", pop: 745000 },
      { name: "Bristol", slug: "gb-bristol", pop: 707000 },
      { name: "Belfast", slug: "gb-belfast", pop: 643000 },
      { name: "Edinburgh", slug: "gb-edinburgh" },
      { name: "Cardiff", slug: "gb-cardiff" },
      { name: "Leicester", slug: "gb-leicester" },
      { name: "Brighton", slug: "gb-brighton" },
      { name: "Portsmouth", slug: "gb-portsmouth" },
      { name: "Plymouth", slug: "gb-plymouth" },
      { name: "Reading", slug: "gb-reading" },
      { name: "Northampton", slug: "gb-northampton" },
      { name: "Luton", slug: "gb-luton" },
      { name: "Wolverhampton", slug: "gb-wolverhampton" },
      { name: "Middlesbrough", slug: "gb-middlesbrough" },
      { name: "Blackpool", slug: "gb-blackpool" },
    ],
    translations: {
      "kr": "영국",
      "pt-BR": "Reino Unido",
      "pt": "Reino Unido",
      "nl": "Verenigd Koninkrijk",
      "hr": "Ujedinjeno Kraljevstvo",
      "fa": "بریتانیای کبیر و ایرلند شمالی",
      "de": "Vereinigtes Königreich",
      "es": "Reino Unido",
      "fr": "Royaume-Uni",
      "ja": "イギリス",
      "it": "Regno Unito",
      "cn": "英国",
      "tr": "Birleşik Krallik"
    },
    enabled: true,
    flag: "🇬🇧",
    locale: "en-GB"
  },
  {
    countryNameEn: "Niger",
    countryNameLocal: "Niger",
    countryCode: "NE",
    currencyCode: "XOF",
    currencyNameEn: "CFA franc BCEAO",
    currencySymbol: "Fr",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "227",
    region: "Africa",
    flag: "🇳🇪",
    locale: "fr-NE"
  },
  {
    countryNameEn: "Democratic Republic of the Congo",
    countryNameLocal: "Democratic Republic of the Congo",
    countryCode: "CD",
    currencyCode: "CDF",
    currencyNameEn: "Congolese franc",
    currencySymbol: "FC",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "243",
    region: "Africa",
    flag: "🇨🇩",
    locale: "fr-CD"
  },
  {
    countryNameEn: "Commonwealth of The Bahamas",
    countryNameLocal: "Commonwealth of The Bahamas",
    countryCode: "BS",
    currencyCode: "BSD",
    currencyNameEn: "Bahamian dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1 242",
    region: "Caribbean",
    flag: "🇧🇸",
    locale: "en-BS"
  },
  {
    countryNameEn: "Cocos (Keeling) Islands",
    countryNameLocal: "Pulu Kokos (Keeling)",
    countryCode: "CC",
    currencyCode: "AUD",
    currencyNameEn: "Australian dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "61 891",
    region: "Australia",
    flag: "🇨🇨",
    locale: "en-CC"
  },
  {
    countryNameEn: "Central African Republic",
    countryNameLocal: "République centrafricaine",
    countryCode: "CF",
    currencyCode: "XAF",
    currencyNameEn: "Central African CFA",
    currencySymbol: "Fr",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "236",
    region: "Africa",
    flag: "🇨🇫",
    locale: "fr-CF"
  },
  {
    countryNameEn: "Cook Islands",
    countryNameLocal: "Kūki 'Āirani",
    countryCode: "CK",
    currencyCode: "NZD",
    currencyNameEn: "New Zealand dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "682",
    region: "South Pacific Ocean",
    flag: "🇨🇰",
    locale: "en-CK"
  },
  {
    countryNameEn: "Falkland Islands",
    countryNameLocal: "Falkland Islands",
    countryCode: "FK",
    currencyCode: "FKP",
    currencyNameEn: "Falklands pound",
    currencySymbol: "£",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "500",
    region: "South Atlantic Ocean",
    flag: "🇫🇰",
    locale: "en-FK"
  },
  {
    countryNameEn: "Faroe Islands",
    countryNameLocal: "Færøerne",
    countryCode: "FO",
    currencyCode: "DKK",
    currencyNameEn: "Faroese króna",
    currencySymbol: "kr",
    officialLanguageCode: "da",
    officialLanguageNameEn: "Danish",
    officialLanguageNameLocal: "dansk",
    countryCallingCode: "298",
    region: "Europe",
    flag: "🇫🇴",
    locale: "fo-FO"
  },
  {
    countryNameEn: "Territory of Heard Island and McDonald Islands",
    countryNameLocal: "Territory of Heard Island and McDonald Islands",
    countryCode: "HM",
    currencyCode: "AUD",
    currencyNameEn: "Australian dollar",
    currencySymbol: "",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "672",
    region: "Indian Ocean",
    flag: "🇭🇲",
    locale: "en-HM"
  },
  {
    countryNameEn: "British Indian Ocean Territory",
    countryNameLocal: "British Indian Ocean Territory",
    countryCode: "IO",
    currencyCode: "USD",
    currencyNameEn: "United States Dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "246",
    region: "Indian Ocean",
    flag: "🇮🇴",
    locale: "en-IO"
  },
  {
    countryNameEn: "Comoros",
    countryNameLocal: "Umoja wa Komori",
    countryCode: "KM",
    currencyCode: "KMF",
    currencyNameEn: "Comorian franc",
    currencySymbol: "Fr",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "269",
    region: "Indian Ocean",
    flag: "🇰🇲",
    locale: "ar-KM"
  },
  {
    countryNameEn: "Cayman Islands",
    countryNameLocal: "Cayman Islands",
    countryCode: "KY",
    currencyCode: "KYD",
    currencyNameEn: "Cayman Islands dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1 345",
    region: "Caribbean Sea",
    flag: "🇰🇾",
    locale: "en-KY"
  },
  {
    countryNameEn: "Republic of the Marshall Islands",
    countryNameLocal: "Aolepān Aorōkin Ṃajeḷ",
    countryCode: "MH",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "692",
    region: "Pacific Ocean",
    flag: "🇲🇭",
    locale: "en-MH"
  },
  {
    countryNameEn: "Commonwealth of the Northern Mariana Islands",
    countryNameLocal: "Sankattan Siha Na Islas Mariånas",
    countryCode: "MP",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1 670",
    region: "Pacific Ocean",
    flag: "🇲🇵",
    locale: "ch-MP"
  },
  {
    countryNameEn: "Turks and Caicos Islands",
    countryNameLocal: "Turks and Caicos Islands",
    countryCode: "TC",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1 649",
    region: "Atlantic Ocean",
    flag: "🇹🇨",
    locale: "en-TC"
  },
  {
    countryNameEn: "French Southern and Antarctic Lands",
    countryNameLocal: "Terres australes et antarctiques françaises",
    countryCode: "TF",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    officialLanguageCode: "fr",
    officialLanguageNameEn: "French",
    officialLanguageNameLocal: "Français",
    countryCallingCode: "672",
    region: "Indian Ocean",
    flag: "🇹🇫",
    locale: "fr-TF"
  },
  {
    countryNameEn: "United States Minor Outlying Islands",
    countryNameLocal: "United States Minor Outlying Islands",
    countryCode: "UM",
    currencyCode: "USD",
    currencyNameEn: "United States dollar",
    currencySymbol: "$",
    officialLanguageCode: "en",
    officialLanguageNameEn: "English",
    officialLanguageNameLocal: "English",
    countryCallingCode: "1",
    region: "Pacific Ocean",
    flag: "🇺🇲",
    locale: "en-UM"
  },
  {
    countryNameEn: "Holy See",
    countryNameLocal: "Sancta Sedes",
    countryCode: "VA",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    officialLanguageCode: "la",
    officialLanguageNameEn: "Latin",
    officialLanguageNameLocal: "lingua latīna",
    countryCallingCode: "39",
    region: "Europe",
    flag: "🇻🇦",
    locale: "de-VA"
  },
  {
    countryNameEn: "Republic of Kosovo",
    countryNameLocal: "Republika e Kosovës",
    countryCode: "XK",
    currencyCode: "EUR",
    currencyNameEn: "Euro",
    currencySymbol: "€",
    officialLanguageCode: "sq",
    officialLanguageNameEn: "Albanian",
    officialLanguageNameLocal: "Shqip",
    countryCallingCode: "383",
    region: "Europe",
    flag: "🇽🇰",
    locale: "sq-XK"
  }
];

const enabledCountries = all.filter(c => c.enabled == true);
export const supportedCountries: ICountry[] = [];
const topCountries = ['AU', 'NZ', 'GB', 'US', 'CA', 'MX', 'JP', 'TH'];
topCountries.forEach(c => {
  supportedCountries.push(enabledCountries.find(s => s.countryCode == c)!);
});
supportedCountries.push(...enabledCountries.filter(c => !topCountries.includes(c.countryCode)));
export default supportedCountries;

export const supportedCountryDict = supportedCountries.reduce((acc, c) => {
  acc[c.countryCode] = c;
  return acc;
}, {} as { [key: string]: ICountry });

export const cryptoSupportedCountries = supportedCountries.filter(c => !c.cryptoProhibited);
export function isCryptoPermitted(countryCode: string) {
  const countryCodeUpper = countryCode.toUpperCase();
  return cryptoSupportedCountries.some(c => c.countryCode.toUpperCase() == countryCodeUpper);
}
export function isCryptoBanned(countryCode: string) {
  return !isCryptoPermitted(countryCode);
}
