import { Component, Input } from '@angular/core';

@Component({
  selector: 'cs-flag',
  standalone: true,
  imports: [],
  templateUrl: './flag.component.html',
  styleUrl: './flag.component.scss'
})
export class FlagComponent {
  @Input() code!: string;
}
